/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  CSSProperties,
  Dispatch,
  forwardRef,
  Fragment,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import Datatable, { SelectionAction, TableRef } from '../../ui/DataTableV2/Datatable';
import { roleTypes } from '../../../constants';
import { Note, RowData, SidebarOptions } from '../types';
import { poBoxDisplay } from '../../../pages/CallCenter';
import differenceInDays from 'date-fns/differenceInDays';
import colors from '../../../styles/colors';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { formatNotes, sortDataByPhoneAndClient } from '../Datatable';
import { Column, Filter, RowDataForExport } from '../../ui/DataTableV2/types';
import { AuthContext } from '../../AuthContext';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GradingIcon from '@mui/icons-material/Grading';
import ResolveSidebarOptions from '../ResolveSidebarOptions';
import { DispositionsProvider } from '../DispositionsContext';

const useStyles = makeStyles({
  linkStyle: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  sidebar: {
    flex: '0 0 350px',
    marginTop: '26px',
    '&:empty': {
      display: 'none',
    },
  },
});

type Props = {
  rows: RowData[];
  setDatatableData: Dispatch<SetStateAction<RowData[]>>;
};

const filters = [
  {
    filterType: 'checkbox',
    id: 'stage',
    label: 'Stage',
    options: [
      {
        label: 'Stage 1',
        value: '1',
        active: false,
      },
      {
        label: 'Stage 2',
        value: '2',
        active: false,
      },
      {
        label: 'Stage 3',
        value: '3',
        active: false,
      },
    ],
    filterData: (rows, filterValue) => {
      if (!filterValue.length) {
        return rows;
      }

      return rows.filter(({ calculatedOutreachStage }) =>
        filterValue.includes(calculatedOutreachStage?.toString() || '1'),
      );
    },
  },
  {
    filterType: 'checkbox',
    id: 'deferred',
    label: 'Deferred',
    options: [
      {
        label: 'Deferred',
        value: 'deferred',
        active: false,
      },
      {
        label: 'Not deferred',
        value: 'not deferred',
        active: false,
      },
    ],
    filterData: (rows, filterValue) => {
      if (!filterValue.length) {
        return rows;
      }

      return rows.filter(({ isDeferred }) =>
        filterValue.includes(isDeferred ? 'deferred' : 'not deferred'),
      );
    },
  },
] as Filter<RowData>[];

const customExport = (rows: RowData[]) =>
  rows.map(row => {
    const { borrower, address, city, state } = row.propertyInfo;

    const daysAged = row.dateFunded ? differenceInDays(new Date(), new Date(row.dateFunded)) : 0;

    return {
      Lender: row.clientInfo.client,
      'Loan number': row.appNumber,
      Borrower: borrower,
      'Property address': address,
      City: city,
      State: state,
      'Title number': row.titleNumber,
      'Date funded': new Date(row.dateFunded).toLocaleDateString(),
      'Days aged': daysAged,
      'Missing docs': row.missingDocuments
        .split(',')
        .map(document =>
          requiresOriginal(row.requiresOriginalDoc, document)
            ? `${document} (Original Needed)`
            : document,
        )
        .join(', '),
    } as RowDataForExport;
  });

const docTypesRequiringOriginal = ['Assignment', 'CEMA', 'Second Mortgage', 'HECM', 'Mortgage'];

const requiresOriginal = (loanRequiresOriginal: boolean, docType: string) => {
  if (!loanRequiresOriginal) {
    return false;
  }

  return docTypesRequiringOriginal.includes(docType);
};

const LoansList = forwardRef<TableRef, Props>(({ rows, setDatatableData }: Props, ref) => {
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [sidebarOption, setSidebarOption] = useState<Exclude<SidebarOptions, 'email'>>(undefined);
  const [selectedNotes, setSelectedNotes] = useState<Note[] | null>(null);

  const classes = useStyles();
  const { roles } = useContext(AuthContext);

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Id',
      sortable: true,
      searchable: true,
      hidden: !roles.includes(roleTypes.Dev),
      render: ({ id }: RowData) => (
        <Link href={`/loans/${id}`} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      id: 'calculatedOutreachStage',
      label: 'Stage',
    },
    {
      id: 'appNumber',
      label: 'Loan/Title number',
      searchable: true,
      render: ({ id, appNumber, titleNumber }: RowData) => (
        <Fragment>
          <div>
            <Link href={`/loans/${id}`} target="_blank">
              {appNumber}
            </Link>
          </div>
          <div style={{ color: 'grey' }}>{titleNumber}</div>
        </Fragment>
      ),
    },
    {
      id: 'clientInformation',
      label: 'Client',
      render: ({ clientInfo }: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{clientInfo.client}</div>
          <span css={{ color: 'blue' }}>{clientInfo.clientInbox}</span>
          <div style={{ color: 'grey' }}>
            <div>{poBoxDisplay(clientInfo)}</div>
          </div>
        </div>
      ),
    },
    {
      id: 'propertyInfo',
      label: 'Property info',
      render: ({ propertyInfo }: RowData) => (
        <Fragment>
          <div>
            <b>{propertyInfo.borrower}</b>
          </div>
          <div>{propertyInfo.address}</div>
          <div>
            <span>{propertyInfo.city}</span>&nbsp;
            <span>{propertyInfo.state}</span>&nbsp;
            <span>{propertyInfo.zip}</span>&nbsp;
          </div>
          <div>{propertyInfo.county}</div>
        </Fragment>
      ),
    },
    {
      id: 'dateFunded',
      label: 'Date funded',
      sortable: true,
      render: ({ dateFunded }: RowData) => dateFunded && new Date(dateFunded).toLocaleDateString(),
    },
    {
      id: 'dateAged',
      label: 'Days aged',
      sortable: true,
      render: ({ dateFunded, daysAgedDeadline }: RowData) => {
        if (!dateFunded) {
          return null;
        }

        const daysAged = differenceInDays(new Date(), new Date(dateFunded));
        return `${daysAged}/${daysAgedDeadline}`;
      },
    },
    {
      id: 'loanImportedAt',
      label: 'Loan import date',
      sortable: true,
      render: ({ loanImportedAt }: RowData) => new Date(loanImportedAt).toLocaleDateString(),
    },
    {
      id: 'missingDocuments',
      label: 'Missing documents',
      render: ({ missingDocuments, requiresOriginalDoc }: RowData) => (
        <Fragment>
          {missingDocuments.split(',').map((doc, i, arr) => (
            <Fragment key={doc}>
              <span
                style={{
                  color: requiresOriginal(requiresOriginalDoc, doc) ? 'red' : 'inherit',
                }}
              >
                {doc}
              </span>
              {i !== arr.length - 1 && ', '}
            </Fragment>
          ))}
        </Fragment>
      ),
    },
    {
      id: 'loanLevelContactInformation',
      label: 'Loan contacts',
      sortable: true,
      customSort: sortDataByPhoneAndClient,
      render: ({ loanLevelContactInformation, titlePortalContactEmails }: RowData) => (
        <div css={{ maxHeight: 128, overflow: 'auto' }}>
          {(loanLevelContactInformation.phone || loanLevelContactInformation.email) && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark }}>Loan Level:</div>
              <div>
                <a
                  className={classes.linkStyle}
                  href={`tel:${loanLevelContactInformation.phone?.replace(/\D/g, '')}`}
                >
                  {loanLevelContactInformation.phone}
                </a>
              </div>
              <div>{loanLevelContactInformation.email}</div>
            </Fragment>
          )}
          {titlePortalContactEmails.length > 0 && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark, paddingTop: 8 }}>
                Title Portal Contacts:
              </div>
              {titlePortalContactEmails.map(e => (
                <div key={e}>{e}</div>
              ))}
            </Fragment>
          )}
        </div>
      ),
    },
    {
      id: 'pastNotes',
      label: 'Past notes',
      render: ({ pastNotes }: RowData) =>
        pastNotes.length ? (
          <div className="df">
            <div
              style={{ width: '310px', height: '66px', overflowY: 'auto', whiteSpace: 'normal' }}
            >
              {formatNotes(pastNotes)}
            </div>
            <div>
              <OpenWithIcon
                style={{ fontSize: '10px' }}
                onClick={() => setSelectedNotes(pastNotes)}
              />
            </div>
          </div>
        ) : null,
    },
  ];

  const actions = (
    allSelectedResolved: boolean,
    someSelectedResolved: boolean,
    setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>,
  ) => {
    const conflictingResolutions = someSelectedResolved && !allSelectedResolved;

    return [
      {
        key: 'updateContactInfo',
        tooltip: 'Update contact information',
        icon: () => <ContactPageIcon style={{ color: 'black' }} />,
        onClick: () => setSidebarOption(prevValue => (prevValue === 'other' ? undefined : 'other')),
      },
      {
        key: 'dispositions',
        tooltip: conflictingResolutions
          ? 'Disabled - one or more loans already have a disposition set '
          : allSelectedResolved
          ? 'Disabled - disposition is already set'
          : 'Set disposition',
        icon: () => (
          <GradingIcon
            style={{
              color:
                !allSelectedResolved && !conflictingResolutions ? 'black' : 'rgba(0, 0, 0, 0.25)',
            }}
          />
        ),
        disabled: allSelectedResolved || conflictingResolutions,
        onClick: () =>
          setSidebarOption(prevValue => (prevValue === 'disposition' ? undefined : 'disposition')),
      },
    ] as SelectionAction[];
  };

  return (
    <DispositionsProvider>
      <div className="df gap-2">
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <Datatable
            title="Outstanding loans"
            columns={columns}
            filters={filters}
            data={rows}
            rowsPerPage={100}
            exportFileName={`${rows[0].titleCompany} Loans.csv`}
            customExport={customExport}
            searchBarPlaceholder="Search by loan id or loan #"
            ref={ref}
            dense
            sortConfig={{ field: 'dateFunded', direction: 'asc' }}
            rowStyle={row => {
              if (row.isResolved) {
                return { backgroundColor: '#D9F0D5' }; /* Green */
              }

              if (row.isDeferred) {
                return { backgroundColor: '#E0E0E0' }; /* Gray */
              }

              if (row.calculatedOutreachStage === 3) {
                return { backgroundColor: '#F0DCD7' }; /* Red */
              }

              return {} as CSSProperties;
            }}
            rowSelectionActions={actions(
              selectedRows.every(r => r.isResolved),
              selectedRows.some(r => r.isResolved),
              setSidebarOption,
            )}
            onSelectionChange={rows => {
              setSelectedRows(rows as RowData[]);
              setSidebarOption(undefined);
            }}
          />
        </div>

        <div className={classes.sidebar}>
          <ResolveSidebarOptions
            setDatatableData={setDatatableData}
            datatableData={rows}
            setSidebarOption={setSidebarOption}
            sidebarOption={sidebarOption}
            selectedRows={selectedRows}
            dispositionCompleteOption={undefined}
          />
        </div>
      </div>

      <Dialog onClose={() => setSelectedNotes(null)} open={selectedNotes !== null}>
        <DialogTitle>Past Notes</DialogTitle>
        <DialogContent>
          {selectedNotes ? formatNotes(selectedNotes) : 'Error loading notes'}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedNotes(null)} color="inherit" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DispositionsProvider>
  );
});

export default LoansList;
