/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { TitleUploadsNotPreAssigned as RowData } from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

type Props = {
  titleUploadsNotPreAssigned: RowData[];
  fromDate: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'email',
    label: 'Title Email Address',
    sortable: true,
  },
  {
    id: 'count',
    label: 'Documents Uploaded',
    sortable: true,
  },
];

const TitleUploadsNotPreAssigned = ({ titleUploadsNotPreAssigned, fromDate }: Props) => (
  <Fragment>
    {titleUploadsNotPreAssigned && (
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title={`Title Uploads Not On Title Portal From  ${fromDate.toLocaleDateString()}`}
          columns={columns}
          data={titleUploadsNotPreAssigned}
          exportFileName={`Title Uploads Not On Title Portal From  ${fromDate.toLocaleDateString()}.csv`}
          dense
        />
      </div>
    )}
  </Fragment>
);

export default TitleUploadsNotPreAssigned;
