import { ReportSelection } from './types';

// eslint-disable-next-line import/prefer-default-export
export const reportsSelection: ReportSelection[] = [
  {
    name: 'DocumentsShippedGroupedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'DocumentsStapledGroupedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'DocumentsMatchedOrRejectedGroupedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'CoversheetCreatedEventsByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'PdfImageSavedEventsByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'SortedEventsByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'DeterminationPerformedEventsByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'DigitalUploadedEventsByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'AssociationsGroupedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'CallCenterGroupedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'CallCenterDocumentsReceivedMonthly',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'CallCenterDocumentsReceivedWeekly',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'AggregateProcessingStats',
    isClientFilterReport: true,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeScannedIntoAzure',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeBatched',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeMatched',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsAddedToMatchQueueToday',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeBroadMatched',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeDetermined',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeDeterminedAsOfToday',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeShipped',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsReadyToBeSorted',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsPendingCorrection',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'TitleCompaniesPendingAssociation',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'InvestorsPendingAssociation',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'ClientData',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'InvestorAssociations',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'InvestorsPerClient',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'DocumentsCorrectedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'CoversheetPreprintedByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'CallCenterProgressByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'DocumentsAgingForShippingOver14Days',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsAgingForShippingOver90Days',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'DocumentsAgingForShippingOver30DaysSorted',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Work Queue',
    isSelected: false,
  },
  {
    name: 'CorrectionsTotalByMonthAndClientOld',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'CorrectionsTotalByMonthAndClient',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'DigitalDeliveryLog',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'System Activity',
    isSelected: false,
  },
  {
    name: 'DocumentsAuditedAutomatically',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'System Activity',
    isSelected: false,
  },
  {
    name: 'MonthlyPayments',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'CompletedLoans',
    isClientFilterReport: true,
    isDateFilterReport: false,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'AutoDeletedDocs',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'System Activity',
    isSelected: false,
  },
  {
    name: 'TitleUploadsNotPreAssigned',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'CorrectionsWithPotentialCure',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'ClientUpdatedOnDocument',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'System Activity',
    isSelected: false,
  },
  {
    name: 'GuildRetainedDocuments',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'FulfilledByUser',
    isClientFilterReport: false,
    isDateFilterReport: true,
    reportCategory: 'User Activity',
    isSelected: false,
  },
  {
    name: 'ClientLoans',
    isClientFilterReport: true,
    isDateFilterReport: true,
    reportCategory: 'Other',
    isSelected: false,
  },
  {
    name: 'MissingData',
    isClientFilterReport: false,
    isDateFilterReport: false,
    reportCategory: 'Other',
    isSelected: false,
  },
];
