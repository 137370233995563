/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { RowData } from '../types';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../adalConfig';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';
import CopyToClipboard from './CopyToClipboard';
import { Mode } from '../../../pages/TitlePortalOutreach';

const useStyles = makeStyles({
  titleCompanySection: {
    paddingLeft: '16px',
    borderRight: '1px solid #aaa',
    minWidth: 210,
    '&:last-of-type': {
      border: 'none',
    },
  },
  linkStyle: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  tableContainer: {
    height: '192px',
    overflow: 'auto',
    border: '1px solid #ccc',
    padding: '5px',
  },
  header: {
    marginBottom: '5px',
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    fontFamily: 'Arial, sans-serif',
    fontSize: '10px',
    textAlign: 'left',
  },
  tableHeadCell: {
    backgroundColor: '#f4f4f4',
    borderBottom: '1px solid #ccc',
    padding: '4px',
  },
  tableBodyCell: {
    padding: '4px',
    border: '1px solid #e0e0e0',
  },
  oddRow: {
    backgroundColor: '#f9f9f9',
  },
  hoverRow: {
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
  },
});

type DailyStats = {
  date: string;
  count: number;
};

type MonthlyStats = {
  month: string;
  count: number;
};

type TitlePortalUploadStats = {
  dailyStats: DailyStats[];
  monthlyStats: MonthlyStats[];
};

type Props = {
  firstRow: RowData;
  loansRemaining: number;
  localTime: string;
  mode: Mode;
  children: ReactNode;
};

const getTitlePortalUploadStats = async ({ queryKey }: QueryFunctionContext<number[]>) => {
  const { data } = await apiFetch<TitlePortalUploadStats>(
    `/api/call-tasks/title-portal-upload-stats/${queryKey[1]}`,
  );
  return data;
};

const TitleCompanyInfo = ({ firstRow, loansRemaining, localTime, mode, children }: Props) => {
  const classes = useStyles();

  const { data: uploadStats } = useQuery(
    ['title-portal-upload-stats', firstRow.titleCompanyId],
    getTitlePortalUploadStats,
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div className="df jcsb" style={{ gap: '16px' }}>
      <Paper style={{ width: '100%', padding: '8px' }}>
        <div className={classes.titleCompanySection}>
          <div className="p1 pt0">
            <div className="bold fs16">Title Company</div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {firstRow.titleCompany}
              <Tooltip title="View title company">
                <OpenInNewIcon
                  color="action"
                  onClick={() => window.open(`/title-companies/${firstRow.titleCompanyId}`)}
                />
              </Tooltip>
            </div>
            <div>
              <a
                className={classes.linkStyle}
                href={`tel:${firstRow.titleCompanyPhoneGlobal?.replace(/\D/g, '')}`}
              >
                {firstRow.titleCompanyPhoneGlobal}
              </a>
            </div>
            <div className="df" style={{ gap: '8px' }}>
              <EmailWithLink
                email={firstRow.titleCompanyEmailGlobal}
                token={firstRow.globalEmailToken}
              />
            </div>
            {!!firstRow.titleCompanyAdditionalEmailGlobal && (
              <div className="df" style={{ gap: '8px' }}>
                <EmailWithLink
                  email={firstRow.titleCompanyAdditionalEmailGlobal}
                  token={firstRow.globalAdditionalEmailToken}
                />
              </div>
            )}
            <div
              css={{
                padding: firstRow.timeZone.toLowerCase() !== 'america/new_york' ? '4px 8px' : 0,
                backgroundColor:
                  firstRow.timeZone.toLowerCase() !== 'america/new_york' ? 'yellow' : 'transparent',
              }}
            >
              Local Time: {localTime}
            </div>
          </div>
          <div
            className={`df full-width ${mode === 'queue' ? 'jcsb' : 'gap-1'}`}
            css={{
              marginTop: 8,
              padding: '10px 8px 0',
              borderTop: '1px solid #aaa',
              fontWeight: 'bold',
              fontSize: 16,
              display: 'flex',
              gap: '16px',
            }}
          >
            <div style={{ width: '260px' }}>
              <span style={{ color: 'red' }}>{loansRemaining}</span>
              <span> unresolved loans</span>
            </div>
            {children}
          </div>
        </div>
      </Paper>
      <Paper style={{ width: '100%', padding: '8px' }}>
        <div className={classes.tableContainer}>
          <Typography className={classes.header} variant="h6">
            Daily Upload Stats (Last 6 upload days)
          </Typography>

          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableHeadCell}>Date</th>
                <th className={classes.tableHeadCell}>Uploads</th>
              </tr>
            </thead>
            <tbody>
              {uploadStats?.dailyStats.map((row, index) => (
                <tr
                  key={row.date}
                  className={`${index % 2 === 0 ? '' : classes.oddRow} ${classes.hoverRow}`}
                >
                  <td className={classes.tableBodyCell}>
                    {new Date(row.date).toLocaleDateString()}
                  </td>
                  <td className={classes.tableBodyCell}>{row.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Paper>
      <Paper style={{ width: '100%', padding: '8px' }}>
        <div className={classes.tableContainer}>
          <Typography className={classes.header} variant="h6">
            Monthly Upload Stats (Last 6 months)
          </Typography>

          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableHeadCell}>Month</th>
                <th className={classes.tableHeadCell}>Uploads</th>
              </tr>
            </thead>
            <tbody>
              {uploadStats?.monthlyStats.map((row, index) => (
                <tr
                  key={row.month}
                  className={`${index % 2 === 0 ? '' : classes.oddRow} ${classes.hoverRow}`}
                >
                  <td className={classes.tableBodyCell}>
                    {format(new Date(row.month), 'LLL yyyy')}
                  </td>
                  <td className={classes.tableBodyCell}>{row.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Paper>
    </div>
  );
};

export default TitleCompanyInfo;

const EmailWithLink = ({ email, token }: { email: string | null; token: string | null }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div>
        <a className={classes.linkStyle} href={`mailto:${email}`}>
          {email}
        </a>
      </div>
      {!!token?.length && (
        <div>
          <Tooltip title="Copy link to portal">
            <div>
              <CopyToClipboard text={`https://docprobe.net/title-company-portal/?token=${token}`} />
            </div>
          </Tooltip>
        </div>
      )}
    </Fragment>
  );
};
