import useServerData from './useServerData';
import useClientData from './useClientData';
import {
  ActiveFilters,
  Column,
  DatatableResponse,
  DatatableRow,
  Filter,
  FilterChipValue,
  FilterGroups,
  PaginationData,
  RowDataForExport,
  RowsPerPage,
  Sort,
} from './types';

export type DataHookReturn<Row extends DatatableRow> = {
  data?: DatatableResponse<Row>;
  isLoading: boolean;
  error: Error | null;
  filterGroups: FilterGroups<Row>;
  activeFilters: ActiveFilters;
  filterChips: FilterChipValue[];
  clearFilter: (filterId: string) => void;
  clearFilters: () => void;
  sort: Sort;
  onSortChange: (field: string) => void;
  paginationData: PaginationData;
  onPaginationChange: (field: keyof PaginationData, value: number) => void;
  onFiltersSubmit: (submittedFilters: ActiveFilters) => void;
  refresh?: () => void;
  exportData: () => void;
  isExporting: boolean;
  filtersInitialized: boolean;
};

const useData = <Row extends DatatableRow>(
  columns: Column<Row>[],
  rowsPerPage: RowsPerPage,
  data?: Row[],
  url?: string,
  sortConfig?: Sort,
  filters?: Filter<Row>[],
  exportUrl?: string,
  exportFileName?: string,
  customExport?: (rows: Row[]) => RowDataForExport[],
  allowMultiSearch?: boolean,
) => {
  if (!data && !url) {
    throw new Error('No data or url provided');
  }

  return data
    ? useClientData<Row>(
        columns,
        data || [],
        filters || [],
        rowsPerPage,
        sortConfig,
        exportFileName,
        customExport,
      )
    : useServerData<Row>(
        columns,
        url || '',
        filters || [],
        rowsPerPage,
        sortConfig,
        exportUrl,
        exportFileName,
        customExport,
        allowMultiSearch,
      );
};

export default useData;
