import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { apiFetch } from '../../../adalConfig';
import { RowData } from '../types';

const getNextTitleCompanyLoans = async () => {
  const { data } = await apiFetch<RowData[]>(`/api/call-tasks/get-title-portal-outreach-loans`);
  return data;
};

const useQueueOutstandingLoans = () => {
  const [localTime, setLocalTime] = useState(new Date().toLocaleDateString());
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout>();
  const [datatableData, setDatatableData] = useState([] as RowData[]);

  const {
    refetch: fetchNextSetOfLoans,
    data,
    isFetching,
  } = useQuery(['title-portal-outreach-fetch-next'], getNextTitleCompanyLoans, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    fetchNextSetOfLoans();
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    setDatatableData(data);

    if (!data.length) {
      return;
    }

    if (timeInterval) {
      clearInterval(timeInterval);
    }

    const interval = setInterval(
      () => setLocalTime(new Date().toLocaleTimeString('en-US', { timeZone: data[0].timeZone })),
      1000,
    );

    setTimeInterval(interval);
  }, [data]);

  const loansRemaining = useMemo(() => {
    if (!datatableData.length) {
      return 0;
    }

    return datatableData.filter(row => {
      if (row.isResolved || row.isDeferred) {
        return false;
      }

      return (row.calculatedOutreachStage || 1) <= 2;
    }).length;
  }, [datatableData]);

  return {
    datatableData,
    setDatatableData,
    fetchNextSetOfLoans,
    isLoading: isFetching,
    loansRemaining,
    localTime,
  };
};

export default useQueueOutstandingLoans;
