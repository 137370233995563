import {
  ActiveFilterData,
  ActiveFilters,
  DatatableRow,
  DateRangeValue,
  Filter,
  FilterChips,
  FilterGroups,
  FilterOption,
} from './types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { produce } from 'immer';
import { StringParam, useQueryParams } from 'use-query-params';
import { DecodedValueMap, QueryParamConfigMap } from 'serialize-query-params';
import { isNullOrUndefined } from './utils';
import isValid from 'date-fns/isValid';
import { apiFetch } from '../../../adalConfig';

type QueryParams = {
  [key: string]: string;
};

const dateRangeValues = (dateRange: DateRangeValue | null) => {
  if (isNullOrUndefined(dateRange)) {
    return '';
  }

  const { start, end } = dateRange;
  if (!start || !end) {
    return '';
  }

  return `${start.toLocaleDateString()},${end.toLocaleDateString()}`;
};

const queryParamValues = (filterData: ActiveFilterData) => {
  if (filterData.filterType !== 'dateRange') {
    return filterData.values.join(',');
  }

  return dateRangeValues(filterData.values[0] as DateRangeValue | null);
};

const buildQueryParams = (filters: ActiveFilters) => {
  return Object.entries(filters).reduce((queryParams, [filterId, filterData]) => {
    queryParams[filterId] = queryParamValues(filterData);

    return queryParams;
  }, {} as QueryParams);
};

export const activeValues = (options: FilterOption<string | number | DateRangeValue>[]) =>
  options.filter(x => x.active && !isNullOrUndefined(x.value)).map(x => x.value);

const getActiveFilters = <Row extends DatatableRow>(filters: Filter<Row>[]) => {
  return filters.reduce((activeFilters, filter) => {
    activeFilters[filter.id] = {
      filterType: filter.filterType,
      values: activeValues(filter.options),
    };

    return activeFilters;
  }, {} as ActiveFilters);
};

const getFilterById = <Row extends DatatableRow>(id: string, filters: Filter<Row>[]) => {
  return filters.find(filter => filter.id === id);
};

const filtersFromUrl = <Row extends DatatableRow>(
  urlParams: DecodedValueMap<QueryParamConfigMap>,
  filters: Filter<Row>[],
) => {
  return Object.entries(urlParams).reduce((final, [key, value]) => {
    if (!value) {
      return final;
    }

    const filter = getFilterById(key, filters);
    if (!filter) {
      return final;
    }

    const parsedValue = value.split(',');
    if (filter.filterType === 'dateRange') {
      const [start, end] = parsedValue; // todo validate that start/end are valid dates
      const startDate = new Date(start);
      validateDate(startDate);
      const endDate = new Date(end);
      validateDate(endDate);

      final[key] = {
        filterType: filter.filterType,
        values: [{ start: startDate, end: endDate } as DateRangeValue],
      };

      return final;
    }

    const intValues = parsedValue.map(Number);
    final[key] = {
      filterType: filter.filterType,
      values: !isNaN(intValues[0]) ? intValues : parsedValue,
    };

    return final;
  }, {} as ActiveFilters);
};

const validateDate = (date: Date) => {
  if (isValid(date)) {
    return true;
  }

  throw new Error('Date is not valid');
};

const isValidDateRange = (dateRange: DateRangeValue) => {
  if (!dateRange?.start || !dateRange?.end) {
    return true;
  }

  return dateRange.start <= dateRange.end;
};

const isOptionActive = <Row extends DatatableRow>(
  filter: Filter<Row>,
  value: Filter<Row>['options'][0]['value'],
  activeValues: Array<Filter<Row>['options'][0]['value']>,
) => {
  if (filter.filterType === 'dateRange') {
    const activeValue = activeValues[0] as DateRangeValue;
    return !!(activeValues.length && (activeValue?.start || activeValue?.end));
  }

  if (filter.filterType === 'search') {
    return !!activeValues.length;
  }

  if (isNullOrUndefined(value)) {
    return false;
  }

  const intValue = Number(value.toString());
  if (!isNaN(intValue)) {
    return (
      activeValues.findIndex(activeValue => {
        if (isNullOrUndefined(activeValue)) {
          return false;
        }

        const intActiveValue = Number(activeValue.toString());
        if (!isNaN(intActiveValue)) {
          return intActiveValue === intValue;
        }

        return activeValue === value;
      }) !== -1
    );
  }

  return activeValues.includes(value.toString());
};

const fillOptionsFromFilterUrl = async <Row extends DatatableRow>(
  filters: Filter<Row>[],
): Promise<Filter<Row>[]> => {
  const newFilters = [] as Filter<Row>[];
  for (const filter of filters) {
    if (!filter.optionsUrl) {
      newFilters.push(filter);
      continue;
    }

    const { data } = await apiFetch<FilterOption<string | number>[]>(filter.optionsUrl);
    const clonedFilter = { ...filter };
    clonedFilter.options = data;
    newFilters.push(clonedFilter);
  }

  return newFilters.map(filter => {
    if (filter.filterType !== 'dropdown') {
      return filter;
    }

    const clonedFilter = { ...filter };
    clonedFilter.options.unshift({
      value: -1,
      label: 'Empty',
    } as FilterOption<string | number>);

    return clonedFilter;
  });
};

const useFilters = <Row extends DatatableRow>(initialFilters: Filter<Row>[]) => {
  const [filters, setFilters] = useState<Filter<Row>[]>(initialFilters);
  const [initialized, setInitialized] = useState(false);

  const initialPageLoadRef = useRef<boolean>(true);

  const filterGroups = useMemo(() => {
    if ([...new Set(filters.map(x => x.id))].length < filters.length) {
      throw new Error('Cannot have two filters with the same id');
    }

    const filtersGroupedByType = {
      radio: [],
      checkbox: [],
      dropdown: [],
      dateRange: [],
      search: [],
    } as FilterGroups<Row>;

    filters.forEach(filter => {
      switch (filter.filterType) {
        case 'radio':
          filtersGroupedByType.radio.push(filter);
          break;
        case 'checkbox':
          filtersGroupedByType.checkbox.push(filter);
          break;
        case 'dropdown':
          filtersGroupedByType.dropdown.push(filter);
          break;
        case 'dateRange':
          filtersGroupedByType.dateRange.push(filter);
          break;
        case 'search':
          filtersGroupedByType.search.push(filter);
          break;
        default:
          break;
      }
    });

    const radioFilterWithoutDefaultValue = filtersGroupedByType.radio.find(
      x => x.options.findIndex(y => y.active) === -1,
    );
    if (radioFilterWithoutDefaultValue) {
      throw new Error(
        `The '${radioFilterWithoutDefaultValue.id}' filter is missing a required active value`,
      );
    }

    return filtersGroupedByType;
  }, [filters]);

  const activeFilters = useMemo(() => getActiveFilters(filters), [filters]);

  const filterChips = useMemo(() => {
    const result: FilterChips = [];

    for (const filter of filterGroups.radio) {
      const activeOptions = filter.options.filter(x => x.active);
      if (!activeOptions.length) {
        continue;
      }

      const chipText = isNullOrUndefined(activeOptions[0].label) ? '' : activeOptions[0].label;

      result.push({
        label: filter.label,
        filterId: filter.id,
        value: chipText,
      });
    }

    for (const filter of filterGroups.checkbox) {
      const activeOptions = filter.options.filter(x => x.active);
      if (!activeOptions.length) {
        continue;
      }

      const activeIds = activeOptions.map(x => x.value);
      const chipText = filter.options
        .filter(x => activeIds.includes(x.value))
        .map(x => x.label)
        .join(', ');

      result.push({
        label: filter.label,
        filterId: filter.id,
        value: chipText,
      });
    }

    for (const filter of filterGroups.dateRange) {
      const activeOptions = filter.options.filter(x => x.active);
      if (!activeOptions.length) {
        continue;
      }

      const chipText = isNullOrUndefined(activeOptions[0].value)
        ? ''
        : `${activeOptions[0].value.start?.toLocaleDateString()} - ${activeOptions[0].value.end?.toLocaleDateString()}`;

      result.push({
        label: filter.label,
        filterId: filter.id,
        value: chipText,
      });
    }

    for (const filter of filterGroups.dropdown) {
      const activeOptions = filter.options.filter(x => x.active);
      if (!activeOptions.length) {
        continue;
      }

      const activeIds = activeOptions.map(x => x.value);
      const chipText = filter.options
        .filter(x => activeIds.includes(x.value))
        .map(x => x.label)
        .join(', ');

      result.push({
        label: filter.label,
        filterId: filter.id,
        value: chipText,
      });
    }

    return result;
  }, [filterGroups]);

  const [query, setQuery] = useQueryParams(
    filters.reduce((final, filter) => {
      final[filter.id] = StringParam;
      return final;
    }, {} as { [key: string]: typeof StringParam }),
  );

  useEffect(() => {
    fillOptionsFromFilterUrl(initialFilters).then(newFilters => {
      setFilters(newFilters);

      if (query) {
        updateFilters(filtersFromUrl(query, newFilters));
      }

      setInitialized(true);
    });
  }, []);

  useEffect(() => {
    // On initial page load, this gets called twice, once before the filters are applied and once after. We only want to update `setQuery` after the filters values are applied. The reason for this is so that we don't send out addition requests to the server (in `useServerData.ts`) due to filters being updated (when nothing really updated)
    const isInitialPageLoad = initialPageLoadRef.current;
    if (isInitialPageLoad) {
      initialPageLoadRef.current = false;
      return;
    }

    const queryParams = buildQueryParams(activeFilters);

    setQuery(queryParams);
  }, [activeFilters]);

  const updateFilters = (newSettings: ActiveFilters) => {
    setFilters(
      produce(draft => {
        draft
          .filter((filter: Filter<Row>) => {
            if (!Object.hasOwn(newSettings, filter.id)) {
              return false;
            }

            if (filter.filterType !== 'dateRange') {
              return true;
            }

            if (newSettings[filter.id].values.length === 0) {
              return true;
            }

            return isValidDateRange(newSettings[filter.id].values[0] as DateRangeValue);
          })
          .forEach((filter: Filter<Row>) => {
            const activeValues = newSettings[filter.id].values;

            filter.options.forEach((option: (typeof filter.options)[0]) => {
              option.active = isOptionActive(filter, option.value, activeValues);

              if (!option.active) {
                return;
              }

              if (filter.filterType === 'search') {
                option.value = activeValues.join(',');
              }

              if (filter.filterType === 'dateRange') {
                const { start, end } = activeValues[0] as DateRangeValue;
                option.value = { start: start || new Date(2000, 0, 1), end: end || new Date() };
              }
            });
          });
      }),
    );
  };

  const clearFilter = (filterId: string) => {
    const initialFilter = getFilterById(filterId, initialFilters);
    if (!initialFilter) {
      return;
    }

    const activeFilters = getActiveFilters([initialFilter]);
    const activeFilter = activeFilters[filterId];

    updateFilters({
      ...activeFilters,
      [filterId]: {
        filterType: initialFilter.filterType,
        values: initialFilter.filterType === 'radio' ? activeFilter.values : [],
      },
    });
  };

  const clearFilters = () => {
    const initialActiveFilters = getActiveFilters(initialFilters);

    const newActiveFilters = {} as ActiveFilters;
    for (const filter of initialFilters) {
      const activeFilter = initialActiveFilters[filter.id];
      if (filter.filterType === 'radio') {
        newActiveFilters[filter.id] = activeFilter;
        continue;
      }

      newActiveFilters[filter.id] = { filterType: filter.filterType, values: [] };
    }

    updateFilters(newActiveFilters);
  };

  return {
    filterGroups,
    activeFilters,
    updateFilters,
    filterChips,
    clearFilter,
    clearFilters,
    initialized,
  };
};

export default useFilters;
