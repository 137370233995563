/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { ClientData as RowData } from './types';
import { AuthContext } from '../../components/AuthContext';
import { roleTypes } from '../../constants';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

type Props = {
  clientData: RowData[];
};

const ClientData = ({ clientData }: Props) => {
  const { roles } = useContext(AuthContext);

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'company',
      label: 'Name',
      sortable: true,
    },
    {
      id: 'contactName',
      label: 'Contact Name',
      sortable: true,
    },
    {
      id: 'address',
      label: 'Address',
      sortable: true,
    },
    {
      id: 'city',
      label: 'City',
      sortable: true,
    },
    {
      id: 'state',
      label: 'State',
      sortable: true,
    },
    {
      id: 'zip',
      label: 'Zip',
      sortable: true,
    },
    {
      id: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      id: 'phone',
      label: 'Phone',
      sortable: true,
    },
    {
      id: 'loanDataContactFirstName',
      label: 'Loan Data Contact',
      sortable: true,
      render: ({ loanDataContactFirstName, loanDataContactLastName }) =>
        `${loanDataContactFirstName} ${loanDataContactLastName}`,
    },
    {
      id: 'loanDataContactEmail',
      label: 'Loan Data Contact Email',
      sortable: true,
    },
    {
      id: 'amrPodName',
      label: 'POD',
      sortable: true,
    },
    {
      id: 'accountRep',
      label: 'Account Manager',
      sortable: true,
    },
    {
      id: 'auditor',
      label: 'Auditor',
      sortable: true,
    },
    {
      id: 'startDate',
      label: 'Start Date',
      sortable: true,
      render: ({ startDate }) =>
        startDate ? new Date(startDate).toLocaleDateString() : <Fragment />,
    },
    {
      id: 'baselineLoansPerMonth',
      label: 'Baseline Loans Per Month',
      sortable: true,
    },
    {
      id: 'los',
      label: 'LOS',
      sortable: true,
    },
    {
      id: 'poBox',
      label: 'Record & Return PO Box',
      sortable: true,
    },
    {
      id: 'clientInbox',
      label: 'Client Inbox',
      sortable: true,
    },
    {
      id: 'invoiceEmail',
      label: 'Invoice Email',
      sortable: true,
    },
    {
      id: 'reportSource',
      label: 'Report Source',
      sortable: true,
    },
    {
      id: 'reportFrequency',
      label: 'Purchased Loans Report Frequency',
      sortable: true,
    },
    {
      id: 'followupsRange',
      label: 'Followups Range',
      sortable: true,
    },
  ];

  return (
    <div style={{ maxHeight: '500px', overflow: 'auto' }}>
      <Datatable<RowData>
        title="Client Data"
        columns={columns}
        data={clientData}
        exportFileName="Client Data.csv"
        dense
      />
    </div>
  );
};

export default ClientData;
