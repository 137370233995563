import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {
  Column,
  DatatableResponse,
  DatatableRow,
  Filter,
  LegendItem,
  RowDataForExport,
  RowsPerPage,
  Sort,
} from './types';
import SearchAndFilterPanel from './SearchAndFilterPanel';
import SelectedFiltersPanel from './SelectedFiltersPanel';
import List from './List';
import useData from './useData';
import DataLegend from './DataLegend';
import { isNullOrUndefined } from './utils';

export type SelectionAction = {
  key: string;
  tooltip: string;
  icon: () => JSX.Element;
  onClick: () => void;
};

type Props<Row extends DatatableRow> = {
  data?: Row[];
  url?: string;
  title: string | JSX.Element;
  columns: Column<Row>[];
  sortConfig?: Sort;
  filters?: Filter<Row>[];
  exportUrl?: string;
  exportFileName?: string;
  customExport?: (rows: Row[]) => RowDataForExport[];
  detailsPanel?: (rowData: Row) => JSX.Element;
  searchBarPlaceholder?: string;
  legendItems?: LegendItem[];
  allowMultiSearch?: boolean;
  dense?: boolean;
  rowSelectionActions?: SelectionAction[];
  onSelectionChange?: (rows: Row[]) => void;
  rowStyle?: (row: Row) => CSSProperties;
  rowsPerPage?: RowsPerPage;
};

type FilterRef = {
  filtersInitialized: boolean;
  clearFilters: () => void;
};

type RefreshRef = {
  refreshTable: () => void;
};

export type TableRef = FilterRef & RefreshRef & {};

const Datatable = <Row extends DatatableRow>(
  {
    data,
    url,
    title,
    columns,
    exportUrl,
    exportFileName,
    customExport,
    filters = [],
    sortConfig,
    detailsPanel = undefined,
    searchBarPlaceholder,
    legendItems,
    allowMultiSearch,
    dense = false,
    rowSelectionActions,
    onSelectionChange,
    rowStyle,
    rowsPerPage = 25,
  }: Props<Row>,
  ref: ForwardedRef<TableRef>,
) => {
  const [previousCount, setPreviousCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const {
    data: datatableData,
    isLoading,
    error,
    filterGroups,
    activeFilters,
    filterChips,
    clearFilter,
    clearFilters,
    sort,
    onSortChange,
    paginationData,
    onPaginationChange,
    onFiltersSubmit,
    refresh,
    exportData,
    isExporting,
    filtersInitialized,
  } = useData(
    columns,
    rowsPerPage,
    data,
    url,
    sortConfig,
    filters,
    exportUrl,
    exportFileName,
    customExport,
    allowMultiSearch,
  );

  useImperativeHandle(ref, () => ({
    filtersInitialized,
    clearFilters,
    refreshTable: refresh || (() => {}),
  }));

  useEffect(() => {
    if (selectedRows.size > 0) {
      setSelectedRows(new Set());
    }
  }, [JSON.stringify(datatableData?.dataTableRows)]);

  useEffect(() => {
    if (!isNullOrUndefined(datatableData?.count)) {
      setPreviousCount(datatableData.count);
    }
  }, [datatableData?.count]);

  useEffect(() => {
    if (!onSelectionChange) {
      return;
    }

    onSelectionChange([...selectedRows].map(rowId => findRow(rowId)).filter(row => !!row));
  }, [selectedRows]);

  const rowCount = useMemo(
    () => datatableData?.count ?? previousCount,
    [datatableData?.count, previousCount],
  );

  const tableData = {
    dataTableRows: datatableData?.dataTableRows,
    count: datatableData?.count ?? rowCount,
  } as DatatableResponse<DatatableRow> | undefined;

  const onCheckAllClick = (checked: boolean) => {
    if (!datatableData) {
      return;
    }

    if (checked) {
      const newSelected = datatableData.dataTableRows.map(row => row.rowId as number);
      setSelectedRows(new Set(newSelected));
      return;
    }

    setSelectedRows(new Set());
  };

  const onCheckboxClick = (rowId: number) => {
    if (!selectedRows.has(rowId)) {
      const rowIds = [...selectedRows, rowId];
      setSelectedRows(new Set(rowIds));
      return;
    }

    setSelectedRows(x => {
      const newRows = [...x].filter(rowIdInSet => rowIdInSet !== rowId);
      return new Set(newRows);
    });

    return;
  };

  const findRow = (rowId: number) => {
    return datatableData?.dataTableRows.find(row => row.rowId === rowId);
  };

  return (
    <>
      <div>
        <div style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#004455' }}>{title}</div>
        <SearchAndFilterPanel
          parentFilters={filterGroups}
          activeFilters={activeFilters}
          canExport={Boolean(exportUrl || exportFileName)}
          exportTable={exportData}
          isExporting={isExporting}
          onFiltersSubmit={onFiltersSubmit}
          searchBarPlaceholder={searchBarPlaceholder}
          refreshTable={refresh}
          isLoading={isLoading}
          totalSelected={selectedRows.size}
          rowSelectionActions={rowSelectionActions}
        />

        <SelectedFiltersPanel
          filterChips={filterChips}
          clearFilter={clearFilter}
          clearFilters={clearFilters}
          totalCount={rowCount}
        />

        <DataLegend legendItems={legendItems} />

        <List<DatatableRow>
          datatableData={tableData}
          columns={columns}
          isLoading={isLoading}
          error={error}
          sort={sort}
          onSortChange={onSortChange}
          paginationData={paginationData}
          onPaginationChange={onPaginationChange}
          detailsPanel={detailsPanel}
          dense={dense}
          selectedRows={selectedRows}
          onCheckAllClick={onCheckAllClick}
          onCheckboxClick={onCheckboxClick}
          rowSelectionActions={rowSelectionActions}
          rowStyle={rowStyle}
        />
      </div>
    </>
  );
};

const ForwardedDatatable = forwardRef(Datatable) as <T extends DatatableRow>(
  props: Props<T> & { ref?: ForwardedRef<TableRef> },
) => ReturnType<typeof Datatable<T>>;

export default ForwardedDatatable;
