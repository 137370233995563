/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import BackButton from './BackButton';
import NewClientContact from './ClientContact/NewClientContact';
import EditClientContact from './ClientContact/EditClientContact';

const panelsWrap = {
  margin: '40px',
  marginTop: 24,
  maxWidth: 1280,
  '@media(min-width: 1024px)': {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export enum ClientContactType {
  LoanErrors = 1,
  IncompleteLoanData = 2,
}

export type ClientContact = {
  clientId: number;
  type: ClientContactType;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  fax: string;
};
const ClientContacts = () => {
  return (
    <Fragment>
      <BackButton to="/admin">Admin Page</BackButton>
      <div css={panelsWrap}>
        <NewClientContact />
        <EditClientContact />
      </div>
    </Fragment>
  );
};

export default ClientContacts;
