/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { apiFetch } from '../adalConfig';
import { QueueStatByClient } from '../globalTypes/objects';
import { useClients } from '../Hooks/useClients';

interface PropTypes {
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    report: {
      margin: '10px',
      maxHeight: '400px',
      overflow: 'auto',
      '& table, td, th': {
        border: '1px solid #ddd',
      },
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
      },
      '& tr:hover': {
        backgroundColor: '#f5f5f5',
      },
      '& th, td': {
        padding: '4px',
      },
      '& th': {
        textAlign: 'center',
        verticalAlign: 'middle',
      },
      '& td': {
        textAlign: 'left',
        paddingLeft: '8px',
      },
    },
  }),
);

async function getQueueStats() {
  return (await apiFetch<QueueStatByClient[]>(`/api/reports/Matching-By-Client-Report`)).data;
}

export default function MatchQueueStats({ onClose }: PropTypes) {
  const [loading, setLoading] = useState(false);
  const [queueStats, setQueueStats] = useState<QueueStatByClient[]>();

  const classes = useStyles();
  const clients = useClients();

  const clientsMapping = useMemo(
    () =>
      clients.reduce(
        (prev, current) => prev.set(current.id, current.company),
        new Map<number, string>(),
      ),
    [clients],
  );

  useEffect(() => {
    setLoading(true);
    getQueueStats().then(data => {
      setQueueStats(data);
      setLoading(false);
    });
  }, []);

  return (
    <div css={classes.paper}>
      <Dialog maxWidth="md" onClose={onClose} open={true}>
        <DialogTitle>View Client Stats</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            <div css={{ height: '400px', width: '875px' }}>
              {loading ? (
                <div className="center-in-parent">
                  <CircularProgress size="75" disableShrink />
                </div>
              ) : (
                <div className={classes.report}>
                  <table>
                    <thead>
                      <tr className="tac">
                        <th>Client</th>
                        <th>
                          <div>Count</div>
                        </th>
                        <th>
                          <div>Skipped Match Count</div>
                        </th>
                        <th>
                          <div>Unique Document Count</div>
                        </th>
                        <th>
                          Oldest <small>(days)</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {queueStats
                        ?.filter(stat => clients.some(c => c.id === stat.clientId))
                        .sort((a, b) => b.count - a.count)
                        .map(stat => (
                          <tr key={stat.clientId}>
                            <td>{clientsMapping.get(stat.clientId)}</td>
                            <td>{stat.count}</td>
                            <td>{stat.skippedMatchCount}</td>
                            <td>{stat.uniqueDocumentCount}</td>
                            <td>{stat.daysAged}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
