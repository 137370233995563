import React from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { ActiveFilters, CheckboxFilter, DatatableRow, FilterOption, FilterType } from './types';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

type Props<Row> = {
  filters: CheckboxFilter<Row>[];
  selectedFilters: ActiveFilters;
  onFilterChange: (
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<string | number>['value']>,
  ) => void;
};

const CheckboxFilters = <Row extends DatatableRow>({
  filters,
  selectedFilters,
  onFilterChange,
}: Props<Row>) => {
  const handleOnChange = (
    filterId: string,
    originalSelectedOptions: string[],
    value: string | null,
  ) => {
    if (!value) {
      return;
    }

    const index = originalSelectedOptions.findIndex(x => x === value);
    let newActiveValues: string[];
    if (index === -1) {
      newActiveValues = [...originalSelectedOptions, value];
    } else {
      newActiveValues = originalSelectedOptions.toSpliced(index, 1);
    }

    onFilterChange(filterId, 'checkbox', newActiveValues);
  };

  return (
    <>
      {filters.map(filter => {
        const selectedOptions = selectedFilters[filter.id].values;

        return (
          <Stack key={filter.id}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              {filter.label}
            </Typography>
            <FormGroup>
              {filter.options.map(option => (
                <FormControlLabel
                  key={option.label}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option.value)}
                      onClick={() =>
                        handleOnChange(filter.id, selectedOptions as string[], option.value)
                      }
                    />
                  }
                  label={option.label}
                  sx={{ textTransform: 'capitalize' }}
                />
              ))}
            </FormGroup>
          </Stack>
        );
      })}
    </>
  );
};

export default CheckboxFilters;
