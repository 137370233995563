/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Client } from '../../../globalTypes/objects';
import ConditionalComponent from '../../ConditionalComponent';
import { useClients } from '../../../Hooks/useClients';
import Header from '../../ui/Header';
import Button from '../../ui/Button';
import Icon, { IconType } from '../../ui/Icon';
import MatchQueueStats from '../../MatchQueueStats';
import AuditQueueStats from "../../AuditQueueStats";

const DeterminationOptions = ({ history }) => {
  const SKIPPED = 'skipped';
  const CLIENT = 'client';
  const CLIENT_PHYSICALS = 'clientPhysicals';
  const CLIENT_DIGITALS = 'clientDigitals';
  const ALL = 'all';
  const PHYSICALS = 'physicals';
  const DIGITALS = 'digitals';

  const clients = useClients();

  const [selectedOption, setSelectedOption] = useState(ALL);
  const [displayClientDropdown, setDisplayClientDropdown] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client>({} as Client);
  const [showStats, setShowStats] = useState(false);

  const url = useMemo(() => {
    let redirectUrl = '/determine-document?';
    // eslint-disable-next-line default-case
    switch (selectedOption) {
      case ALL:
        redirectUrl += 'all=1';
        break;
      case CLIENT:
        if (selectedClient?.id) {
          redirectUrl += `clientid=${selectedClient.id}`;
        }
        break;
      case CLIENT_PHYSICALS:
        if (selectedClient?.id) {
          redirectUrl += `clientid=${selectedClient.id}&physicals=1`;
        }
        break;
      case CLIENT_DIGITALS:
        if (selectedClient?.id) {
          redirectUrl += `clientid=${selectedClient.id}&digitals=1`;
        }
        break;
      case SKIPPED:
        redirectUrl += 'skipped=1';
        break;
      case PHYSICALS:
        redirectUrl += 'physicals=1';
        break;
      case DIGITALS:
        redirectUrl += 'digitals=1';
        break;
    }

    return redirectUrl;
  }, [selectedOption, selectedClient]);

  return (
    <Fragment>
      <Header headerText="Audit Docs" />
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          width: 'fit-content',
        }}
      >
        <Card
          variant="outlined"
          css={{
            width: 400,
            margin: '80px 0px 40px 80px',
            padding: 32,
          }}
        >
          <CardHeader title="Audit Options" css={{ backgroundColor: '#e5e5ea', borderRadius: 4 }} />
          <CardContent style={{ paddingBottom: 0 }}>
            <RadioGroup
              value={selectedOption}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSelectedOption(event.target.value);
                if (event.target.value.includes(CLIENT)) {
                  setDisplayClientDropdown(true);
                } else {
                  setDisplayClientDropdown(false);
                }
              }}
            >
              <FormControlLabel value={SKIPPED} control={<Radio />} label="Skipped Docs" />
              <FormControlLabel value={CLIENT} control={<Radio />} label="Docs by Client" />
              <ConditionalComponent display={displayClientDropdown}>
                <Autocomplete
                  options={clients}
                  getOptionLabel={(client: Client) => client.company ?? ''}
                  css={{ marginTop: 8 }}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Select A Client" />
                  )}
                  value={selectedClient}
                  onChange={(e, newValue) => setSelectedClient(newValue)}
                />
              </ConditionalComponent>
              {selectedClient?.id && (
                <Fragment>
                  <FormControlLabel
                    value={CLIENT_PHYSICALS}
                    control={<Radio />}
                    label="Physical Docs"
                    css={{ paddingLeft: 24 }}
                  />
                  <FormControlLabel
                    value={CLIENT_DIGITALS}
                    control={<Radio />}
                    label="Digital Docs"
                    css={{ paddingLeft: 24 }}
                  />
                </Fragment>
              )}
              <FormControlLabel value={PHYSICALS} control={<Radio />} label="All Physical Docs" />
              <FormControlLabel value={DIGITALS} control={<Radio />} label="All Digital Docs" />
              <FormControlLabel value={ALL} control={<Radio />} label="All Docs" />
            </RadioGroup>
          </CardContent>
        </Card>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            width: 400,
            marginLeft: '80px',
          }}
        >
          <Button color="primary" onClick={() => setShowStats(true)}>
            Audit Queue Stats
          </Button>
          <Button
            css={{ display: 'flex', alignItems: 'center' }}
            color="primary"
            disabled={selectedOption.includes(CLIENT) && !selectedClient?.id}
            onClick={() => history.push(url)}
          >
            Continue <Icon icon={IconType.RightArrow} />
          </Button>
        </div>
      </div>
      {showStats && <AuditQueueStats onClose={() => setShowStats(false)} />}
    </Fragment>
  );
};

export default DeterminationOptions;
