import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import RadioButtonFilters from './RadioButtonFilters';
import DateRangeFilters from './DateRangeFilters';
import DropdownFilters from './DropdownFilters';
import { ActiveFilters, DatatableRow, FilterGroups, FilterOption, FilterType } from './types';
import CheckboxFilters from './CheckboxFilters';
import Box from '@mui/material/Box';

type Props<Row> = {
  filters: FilterGroups<Row>;
  selectedFilters: ActiveFilters;
  onFilterChange: <TValue>(
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<TValue>['value']>,
  ) => void;
  isFiltersSectionOpen: boolean;
  setIsFiltersSectionOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const FilterDrawer = <Row extends DatatableRow>({
  filters,
  selectedFilters,
  onFilterChange,
  isFiltersSectionOpen,
  setIsFiltersSectionOpen,
  onSubmit,
}: Props<Row>) => {
  return (
    <Drawer
      anchor="right"
      open={isFiltersSectionOpen}
      onClose={() => setIsFiltersSectionOpen(false)}
      slotProps={{
        backdrop: { invisible: true },
      }}
      PaperProps={{
        sx: { width: 280 },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ py: 2, pr: 1, pl: 2.5 }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Filters
        </Typography>
      </Stack>

      <Divider />

      <Box sx={{ px: 2.5, py: 3 }}>
        <form onSubmit={onSubmit} style={{ overflow: 'auto' }}>
          <Stack spacing={3}>
            <RadioButtonFilters
              filters={filters.radio}
              selectedFilters={selectedFilters}
              onFilterChange={onFilterChange}
            />

            <CheckboxFilters
              filters={filters.checkbox}
              selectedFilters={selectedFilters}
              onFilterChange={onFilterChange}
            />

            <DateRangeFilters
              filters={filters.dateRange}
              selectedFilters={selectedFilters}
              onFilterChange={onFilterChange}
            />

            <DropdownFilters
              filters={filters.dropdown}
              selectedFilters={selectedFilters}
              onFilterChange={onFilterChange}
            />

            <Stack spacing={2.5}>
              <Button type="submit">Submit</Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
