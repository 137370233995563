/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import SortResponse from './SortResponse';
import { SortResponse as SortResponseType } from './SortClientDocuments';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import ClientInvestorData from './ClientInvestorData';
import colors from '../../../../styles/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: 'solid 1px #e6e4e8',
      borderTop: 'none',
      borderRadius: '0 0 4px 4px',
      overflow: 'hidden',
    },
    warning: {
      height: '75px',
      padding: '24px',
      marginBottom: 0,
      fontFamily: 'Roboto, Helvetica, sansSerif',
      fontSize: '24px',
      fontStyle: 'normal',
      lineHeight: 'normal',
      borderRadius: '0',
      borderTop: 'solid 1px #e6e4e8',
      color: colors.red,
      backgroundColor: '#fffeff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    alert: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  }),
);

type Props = {
  sortResponse: SortResponseType;
  isProcessingSort: boolean;
};

const ResultPanel = ({ sortResponse, isProcessingSort }: Props) => {
  const classes = useStyles();

  if (Object.keys(sortResponse).length === 0) {
    return <Fragment />;
  }

  if (isProcessingSort) {
    return (
      <div className="center-in-parent" style={{ height: '150px' }}>
        <CircularProgress size="45" disableShrink />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.container}>
        <SortResponse
          alertType={sortResponse.alertType}
          primaryMessage={sortResponse.message}
          secondaryMessage={sortResponse.additionalInformation}
          isWrongClient={!sortResponse.sortDocumentLocation?.isCorrectClient}
        />
        <ClientInvestorData
          clientName={sortResponse.sortDocumentLocation?.client || ''}
          investorName={sortResponse.sortDocumentLocation?.investor || ''}
        />
        {!sortResponse.sortDocumentLocation?.docUploaded && (
          <div className={classes.warning}>Warning: Document was not yet scanned!</div>
        )}
      </div>
      {!!sortResponse.compartmentLabel && (
        <div className={classes.alert}>
          <Alert severity="info">{sortResponse.compartmentLabel}</Alert>
        </div>
      )}
    </Fragment>
  );
};

export default ResultPanel;
