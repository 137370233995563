/** @jsx jsx */
import { jsx } from '@emotion/core';
import Panel, { PanelHeader } from '../../ui/Panel';
import SearchSelect from '../../ui/SearchSelect';
import { Client } from '../../../globalTypes/objects';
import TextInput from '../../ui/Inputs/TextInput';
import PhoneInput from '../../ui/Inputs/PhoneInput';
import Button from '../../ui/Button';
import { useClients } from '../../../Hooks/useClients';
import { useState } from 'react';
import { ClientContact, ClientContactType } from '../ClientContacts';
import { apiPost } from '../../../adalConfig';
import Dropdown from '../../ui/Dropdown';

type ContactType = {
  label: string;
  value: ClientContactType;
};

const defaultState: ClientContact = {
  clientId: 0,
  type: 2 as ClientContactType,
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  fax: '',
};

export const contactTypes: ContactType[] = [
  {
    label: 'Incomplete loan data',
    value: 2 as ClientContactType,
  },
];

const NewClientContact = () => {
  const [contact, setContact] = useState(defaultState);
  const [isError, setIsError] = useState(false);

  const clients = useClients();

  const onSubmit = async (contact: ClientContact) => {
    const { clientId, type, title, firstName, lastName, email, phone, fax } = contact;
    if (!clientId || !type || !email || !firstName || !lastName) {
      setIsError(true);
      return;
    }

    const clonedContact: ClientContact = {
      title,
      type,
      clientId,
      firstName,
      lastName,
      email,
      phone,
      fax,
    };

    Object.keys(clonedContact).forEach(key => {
      if (!clonedContact[key]) clonedContact[key] = null;
    });

    await apiPost('/api/clients/insertClientContactForType', {
      ...clonedContact,
    });

    setContact(defaultState);
  };

  return (
    <Panel>
      <PanelHeader text="Create Loan Error Client Contact" />
      <div css={{ maxWidth: 320, marginTop: 24 }}>
        <SearchSelect
          options={clients.map((c: Client) => ({
            label: c.company,
            value: c.id,
          }))}
          placeholder="Search clients *"
          {...(contact.clientId && {
            defaultValue: {
              label: clients.find(c => c.id === contact.clientId)?.company,
              value: contact.clientId,
            },
          })}
          onChange={selection => {
            if (selection) {
              setContact(x => ({ ...x, clientId: selection.value }));
            }
          }}
        />
      </div>
      <div css={{ maxWidth: 320, marginTop: 24 }}>
        <span>Contact type</span>
        <Dropdown
          options={contactTypes}
          placeholder="Search contact type *"
          defaultValue={{
            label: contactTypes.find(c => c.value === contact.type)?.label,
            value: contactTypes.find(c => c.value === contact.type)?.value,
          }}
          onChange={selection => {
            if (selection) {
              setContact(x => ({ ...x, type: selection.value }));
            }
          }}
        />
      </div>
      <TextInput
        type="text"
        placeholder="Title"
        value={contact.title}
        onChange={e => setContact(x => ({ ...x, title: e.target.value }))}
        labelOverrides={{ marginTop: 16, width: 320 }}
      />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 16,
        }}
      >
        <TextInput
          type="text"
          placeholder="First Name *"
          value={contact.firstName}
          onChange={e => setContact(x => ({ ...x, firstName: e.target.value }))}
          labelOverrides={{ width: '48%' }}
        />
        <TextInput
          type="text"
          placeholder="Last Name *"
          value={contact.lastName}
          onChange={e => setContact(x => ({ ...x, lastName: e.target.value }))}
          labelOverrides={{ width: '48%' }}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 16,
        }}
      >
        <PhoneInput
          placeholder="Phone"
          value={contact.phone}
          onChange={value => setContact(x => ({ ...x, phone: value }))}
          labelOverrides={{ width: '48%' }}
        />
        <PhoneInput
          placeholder="Fax"
          value={contact.fax}
          onChange={value => setContact(x => ({ ...x, fax: value }))}
          labelOverrides={{ width: '48%' }}
        />
      </div>
      <TextInput
        type="text"
        placeholder="Email *"
        value={contact.email}
        onChange={e => setContact(x => ({ ...x, email: (e.target.value || '').trim() }))}
        labelOverrides={{ margin: '16px 0 24px', maxWidth: 320 }}
      />
      <Button onClick={() => onSubmit(contact)}>Submit</Button>
      {isError && (
        <div style={{ margin: '4px', color: 'red' }}>Please fill out all required fields</div>
      )}
    </Panel>
  );
};

export default NewClientContact;
