/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ClientUpdatedOnDocument as RowData } from './types';
import Link from '@mui/material/Link';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'documentId',
    label: 'Barcode',
    sortable: true,
    render: ({ documentId }) => (
      <Link target="_blank" href={`/documents/${documentId}`}>
        {documentId}
      </Link>
    ),
  },
  {
    id: 'originalClient',
    label: 'Original client',
    sortable: true,
  },
  {
    id: 'newClient',
    label: 'New client',
    sortable: true,
  },
  {
    id: 'updatedAt',
    label: 'Date updated',
    sortable: true,
    render: ({ updatedAt }) => (updatedAt ? new Date(updatedAt).toLocaleDateString() : ''),
  },
  {
    id: 'username',
    label: 'User',
    sortable: true,
  },
  {
    id: 'sortedAt',
    label: 'Date sorted',
    sortable: true,
    render: ({ sortedAt }) => (sortedAt ? new Date(sortedAt).toLocaleDateString() : ''),
  },
  {
    id: 'shipped',
    label: 'Shipped',
    sortable: true,
    render: ({ shipped }) =>
      shipped ? (
        <DoneIcon style={{ color: green[500] }} />
      ) : (
        <CloseIcon style={{ color: red[800] }} />
      ),
  },
];

const ClientUpdatedOnDocument = ({ reportData }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Client updated on physical document"
      columns={columns}
      data={reportData}
      exportFileName="Client updated on physical document.csv"
      dense
    />
  </div>
);

export default ClientUpdatedOnDocument;
