/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ClientLoans as RowData } from './types';
import Link from '@mui/material/Link';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
  fromDate: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
    render: ({ loanId, loanNumber }) => (
      <Link target="_blank" href={`/loans/${loanId}`}>
        {loanNumber}
      </Link>
    ),
  },
  {
    id: 'borrower',
    label: 'Borrower',
    sortable: true,
  },
  {
    id: 'propertyAddress',
    label: 'Property Address',
    sortable: true,
  },
  {
    id: 'city',
    label: 'City',
    sortable: true,
  },
  {
    id: 'state',
    label: 'State',
    sortable: true,
  },
  {
    id: 'zip',
    label: 'Zip',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => dateFunded && new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'loanAmount',
    label: 'Loan Amount',
    sortable: true,
    render: ({ loanAmount }) =>
      loanAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'investorNum',
    label: 'Investor Number',
    sortable: true,
  },
  {
    id: 'titleCompany',
    label: 'Title Company',
    sortable: true,
  },
];

const ClientLoans = ({ reportData, fromDate }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Loans"
      columns={columns}
      data={reportData}
      exportFileName="Loans.csv"
      dense
    />
  </div>
);

export default ClientLoans;
