/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Autocomplete, Button, Grid, Paper, TextField } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { apiFetch } from '../../../adalConfig';
import { DocumentTypeProperties, DocumentTypesContext } from '../../../DocumentTypesContext';
import { Client, Investor } from '../../../globalTypes/objects';
import { useDebounce } from '../../../Hooks';
import { useClients } from '../../../Hooks/useClients';
import { useInvestors } from '../../../Hooks/useInvestors';
import { buildArrayFetchString, DebounceRate } from '../../../Utils';
import { Document } from './index';

enum OptionFilterTypes {
  AgedDocs14,
  AgedDocs90,
  AgedDocs30,
  TrackingNumber,
  LoanNumbers,
  DocumentIds,
}

type SearchOption = {
  option: OptionFilterTypes;
  displayText: string;
};

const searchOptions: SearchOption[] = [
  { option: OptionFilterTypes.AgedDocs14, displayText: 'Documents Aged 14 for Shipping' },
  { option: OptionFilterTypes.AgedDocs90, displayText: 'Documents Aged 90 days for Shipping' },
  {
    option: OptionFilterTypes.AgedDocs30,
    displayText: 'Documents Aged 30 days for Shipping (sorted)',
  },
  { option: OptionFilterTypes.TrackingNumber, displayText: 'Search by Tracking Number' },
  { option: OptionFilterTypes.LoanNumbers, displayText: 'Search by Loan Number' },
  { option: OptionFilterTypes.DocumentIds, displayText: 'Search by Document Id' },
];

export const getDocumentsEndpoint = (
  optionFilterType: OptionFilterTypes | null,
  filterData: string,
) => {
  switch (optionFilterType) {
    case OptionFilterTypes.AgedDocs14:
      return 'getDocumentsAgedForShipping?daysAging=14';
    case OptionFilterTypes.AgedDocs90:
      return 'getDocumentsAgedForShipping?daysAging=90';
    case OptionFilterTypes.AgedDocs30:
      return 'getDocumentsAgedForShipping?daysAging=30';
    case OptionFilterTypes.TrackingNumber:
      return `getDocumentsByTrackingNumber?trackingNumber=${filterData}`;
    case OptionFilterTypes.LoanNumbers: {
      const loanNumbers = filterData
        .split(/[\n,]/)
        .filter(Boolean)
        .map(x => x.trim());
      return `getDocumentsByLoanNumbers?${buildArrayFetchString(loanNumbers, 'nums')}`;
    }
    case OptionFilterTypes.DocumentIds: {
      const documentIds = filterData
        .split(/[\n,]/)
        .filter(Boolean)
        .map(x => x.trim());
      return `getDocumentsByDocumentIds?${buildArrayFetchString(documentIds, 'ids')}`;
    }
    default:
      return '';
  }
};

export const filterDocuments = (
  documents: Document[],
  selectedClient: Client | null,
  selectedInvestor: Investor | null,
  requiresOriginal: boolean,
  selectedDocType: DocumentTypeProperties | null,
) => {
  return documents.filter(
    doc =>
      (selectedClient === null || doc.clientId === selectedClient.id) &&
      (selectedInvestor === null || doc.investor === selectedInvestor.name) &&
      doc.requiresOriginal === requiresOriginal &&
      (selectedDocType === null || doc.docType === selectedDocType.label),
  );
};

export default function ShipRequestsFilters({ state }) {
  const [{ documents }, setState] = state;

  const [selectedFilter, setSelectedFilter] = useState<SearchOption | null>(null);
  const [filterData, setFilterData] = useState<string>('');
  const debouncedFilterData = useDebounce(filterData, DebounceRate);

  const changeFilter = (filterValue: SearchOption | null) => {
    setState({ documents: [] });
    setFilterData('');
    setSelectedFilter(filterValue);
  };

  const filterSelected = useMemo(
    () =>
      selectedFilter !== null &&
      (selectedFilter.option === OptionFilterTypes.AgedDocs14 ||
        selectedFilter.option === OptionFilterTypes.AgedDocs90 ||
        selectedFilter.option === OptionFilterTypes.AgedDocs30 ||
        (!!debouncedFilterData && !!filterData)),

    [selectedFilter, debouncedFilterData, filterData],
  );
  const [xhr, setXhr] = useState<CancelTokenSource | undefined>(undefined);
  useEffect(() => {
    setState({ filterSelected });
    if (filterSelected) {
      if (xhr) {
        xhr.cancel();
      }
      const fetchRequest = axios.CancelToken.source();
      setXhr(fetchRequest);
      const fetchData = async () => {
        setState({ loading: true });
        const { data } = await apiFetch<Document[]>(
          `/api/shipRequests/${getDocumentsEndpoint(selectedFilter!.option, debouncedFilterData)}`,
          { cancelToken: fetchRequest.token },
        );
        setState({ documents: data, loading: false });
        setXhr(undefined);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilterData, filterSelected, selectedFilter]);
  const { docTypes: documentTypes } = useContext(DocumentTypesContext);
  const clients = useClients();
  const investors = useInvestors();

  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedInvestor, setSelectedInvestor] = useState<Investor | null>(null);
  const [selectedDocType, setSelectedDocType] = useState<DocumentTypeProperties | null>(null);
  const [requiresOriginal, setRequiresOriginal] = useState<boolean>(false);

  useEffect(() => {
    const filteredDocuments = filterDocuments(
      documents,
      selectedClient,
      selectedInvestor,
      requiresOriginal,
      selectedDocType,
    );
    setState({ filteredDocuments, selectedIds: new Set() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, selectedClient, selectedInvestor, requiresOriginal, selectedDocType]);

  const clearFilters = () => {
    setSelectedClient(null);
    setSelectedInvestor(null);
    setSelectedDocType(null);
    setSelectedFilter(null);
    setRequiresOriginal(false);
    setFilterData('');
    setState({ documents: [] });
  };
  const disabledClearButton =
    selectedFilter === null &&
    selectedClient === null &&
    selectedInvestor === null &&
    !requiresOriginal &&
    selectedDocType === null;
  return (
    <Paper
      css={{ maxHeight: 'calc(100vh - 64px)', overflow: 'auto', marginBottom: 20, padding: 16 }}
    >
      <Grid container spacing={1}>
        <Grid item container xs={6} spacing={1} direction="column">
          <Grid item>
            <Autocomplete
              autoHighlight
              options={searchOptions}
              getOptionLabel={option => option.displayText}
              renderInput={params => (
                <TextField {...params} label="Select a Filter" variant="outlined" />
              )}
              onChange={(_, value) => changeFilter(value)}
              value={selectedFilter}
            />
          </Grid>
          <Grid item>
            <SearchField selectedFilter={selectedFilter} setFilterData={setFilterData} />
          </Grid>
          <Grid item>
            <Autocomplete
              autoHighlight
              options={investors.filter(x => x.name !== '' && x.name !== null)}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField {...params} label="Select Investor" variant="outlined" />
              )}
              onChange={(_, value) => setSelectedInvestor(value)}
              value={selectedInvestor}
            />
          </Grid>
          <Grid item></Grid>
        </Grid>

        <Grid item container xs={6} spacing={1} direction="column">
          <Grid item>
            <Autocomplete
              autoHighlight
              options={clients}
              getOptionLabel={option => option.company}
              renderInput={params => (
                <TextField {...params} label="Select Client" variant="outlined" />
              )}
              onChange={(_, value) => setSelectedClient(value)}
              value={selectedClient}
            />
          </Grid>
          <Grid item></Grid>
          <Grid item>
            <Autocomplete
              autoHighlight
              options={documentTypes}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField {...params} label="Select Document Type" variant="outlined" />
              )}
              onChange={(_, value) => setSelectedDocType(value)}
              value={selectedDocType}
            />
          </Grid>
          <Grid item>
            <Checkbox
              color="error"
              checked={requiresOriginal}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRequiresOriginal(event.target.checked)
              }
            />
            Requires Original
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Button onClick={clearFilters} disabled={disabledClearButton} variant="contained">
            Clear Filters
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

type SearchFieldProps = {
  selectedFilter: SearchOption | null;
  setFilterData: (data: string) => void;
};

function SearchField({ selectedFilter, setFilterData }: SearchFieldProps): JSX.Element {
  switch (selectedFilter?.option) {
    case OptionFilterTypes.AgedDocs14 ||
      OptionFilterTypes.AgedDocs30 ||
      OptionFilterTypes.AgedDocs90:
      return <Fragment />;
    case OptionFilterTypes.TrackingNumber:
      return (
        <TextField
          key={selectedFilter?.option}
          placeholder="Enter Tracking Number"
          variant="outlined"
          autoFocus
          css={{ width: '50%' }}
          onChange={e => setFilterData(e.target.value)}
        />
      );
    case OptionFilterTypes.LoanNumbers:
      return (
        <TextField
          key={selectedFilter?.option}
          multiline
          autoFocus
          placeholder="Enter loan numbers separated by commas or newlines (limit 100)"
          variant="outlined"
          fullWidth
          onChange={e => setFilterData(e.target.value)}
        />
      );
    case OptionFilterTypes.DocumentIds:
      return (
        <TextField
          key={selectedFilter?.option}
          multiline
          autoFocus
          placeholder="Enter document ids separated by commas or newlines (limit 100)"
          variant="outlined"
          fullWidth
          onChange={e => setFilterData(e.target.value)}
        />
      );
    default:
      return <Fragment />;
  }
}
