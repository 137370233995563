import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Searchbar from './Searchbar';
import { ActiveFilters, DatatableRow, FilterGroups, FilterOption, FilterType } from './types';
import FilterDrawer from './FilterDrawer';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material';
import { SelectionAction } from './Datatable';

type Props<Row extends DatatableRow> = {
  parentFilters: FilterGroups<Row>;
  activeFilters: ActiveFilters;
  canExport: boolean;
  exportTable?: () => void;
  isExporting: boolean;
  onFiltersSubmit: (submittedFilters: ActiveFilters) => void;
  searchBarPlaceholder?: string;
  refreshTable?: () => void;
  isLoading: boolean;
  totalSelected: number;
  rowSelectionActions?: SelectionAction[];
};

const SearchAndFilterPanel = <Row extends DatatableRow>({
  parentFilters,
  activeFilters,
  canExport,
  exportTable,
  isExporting,
  onFiltersSubmit,
  searchBarPlaceholder,
  refreshTable,
  isLoading,
  totalSelected,
  rowSelectionActions,
}: Props<Row>) => {
  const [isFiltersSectionOpen, setIsFiltersSectionOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<ActiveFilters>(activeFilters);

  useEffect(() => {
    setSelectedFilters(activeFilters);
  }, [activeFilters]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onFiltersSubmit(selectedFilters);

    setIsFiltersSectionOpen(false);
  };

  const onSearchTextChange = (newText: string) => onFilterChange('searchText', 'search', [newText]);

  const onFilterChange = <TValue,>(
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<TValue>['value']>,
  ) => setSelectedFilters(x => ({ ...x, [filterId]: { filterType, values } } as ActiveFilters));

  const hasFilters = useMemo(
    () => Object.keys(activeFilters).filter(x => x !== 'searchText').length > 0,
    [activeFilters],
  );

  const hasSearchOrButtons =
    searchBarPlaceholder?.length || canExport || hasFilters || refreshTable;

  if (totalSelected > 0) {
    return (
      <Toolbar
        className="df jcsb"
        sx={{
          mb: 1,
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: theme =>
            alpha(theme.palette.secondary.main, theme.palette.action.activatedOpacity),
        }}
      >
        <>
          <div>{totalSelected} selected</div>
          <div>
            <Typography
              sx={{ flex: '1 1 100%', display: 'flex', gap: '8px' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {(rowSelectionActions || []).map(action => {
                return (
                  <div key={action.key} onClick={action.onClick}>
                    <Tooltip title={action.tooltip}>{action.icon()}</Tooltip>
                  </div>
                );
              })}
            </Typography>
          </div>
        </>
      </Toolbar>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: hasSearchOrButtons ? '8px' : '0',
          ...(hasSearchOrButtons && {
            height: '64px',
          }),
        }}
      >
        {!!searchBarPlaceholder?.length && (
          <div className="full-width">
            <Searchbar
              search={
                selectedFilters.searchText
                  ? selectedFilters.searchText.values[0]?.toString() || ''
                  : ''
              }
              placeholder={searchBarPlaceholder}
              onSearchTextChange={onSearchTextChange}
              onSubmit={onSubmit}
            />
          </div>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '16px',
          }}
        >
          {refreshTable && (
            <LoadingButton
              loading={isLoading}
              sx={{ color: 'black' }}
              variant="text"
              startIcon={<RefreshIcon />}
              onClick={refreshTable}
            >
              Refresh
            </LoadingButton>
          )}
          {canExport && (
            <LoadingButton
              loading={isExporting}
              sx={{ color: 'black' }}
              variant="text"
              startIcon={<CloudDownloadIcon />}
              onClick={exportTable}
            >
              Export
            </LoadingButton>
          )}
          {hasFilters && (
            <div>
              <Button
                onClick={() => setIsFiltersSectionOpen(x => !x)}
                endIcon={<FilterListIcon />}
                sx={{ color: 'black' }}
                variant="text"
              >
                Filters
              </Button>
            </div>
          )}
        </div>
      </div>

      <FilterDrawer
        filters={parentFilters}
        selectedFilters={selectedFilters}
        onFilterChange={onFilterChange}
        onSubmit={onSubmit}
        isFiltersSectionOpen={isFiltersSectionOpen}
        setIsFiltersSectionOpen={setIsFiltersSectionOpen}
      />
    </>
  );
};

export default SearchAndFilterPanel;
