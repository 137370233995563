/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AssociationsGroupedByUser as RowData } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  associationsGroupedByUser: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'associationType',
    label: 'Association Type',
    sortable: true,
  },
  {
    id: 'count',
    label: 'Count',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: (rowData: RowData) => rowData.date && new Date(rowData.date).toLocaleDateString(),
  },
];

const AssociationsGroupedByUser = ({ associationsGroupedByUser }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Associations - By User"
      columns={columns}
      data={associationsGroupedByUser}
      exportFileName="Associations - By User.csv"
      dense
    />
  </div>
);

export default AssociationsGroupedByUser;
