/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useRef, useState } from 'react';
import queryString from 'query-string';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditIcon from '@mui/icons-material/Edit';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ConditionalComponent from '../components/ConditionalComponent';
import { apiFetch } from '../adalConfig';
import { DocumentTypeProperties, DocumentTypesContext } from '../DocumentTypesContext';
import { Event, EventDisplay, getEventNameMappings } from '../components/Documents/EventDisplay';
import UpdateDocumentTypeModal from '../components/Documents/Index/UpdateDocumentTypeModal';
import DeleteDocumentModal from '../components/Documents/Index/DeleteDocumentModal';
import DocumentSuggestedMatchesModal from '../components/Documents/Index/DocumentSuggestedMatchesModal';
import colors from '../styles/colors';
import { openOrDownloadFile } from '../Utils';
import { useClient, useClients } from '../Hooks/useClients';
import { useToaster } from '../Hooks/toasters';
import { useQuery } from '@tanstack/react-query';
import Datatable, { TableRef } from '../components/ui/DataTableV2/Datatable';
import { Column, Filter } from '../components/ui/DataTableV2/types';

export type RowData = {
  loanId: number;
  documentId: number;
  documentType: string;
  appNumber: string;
  hardCopy: boolean;
  documentCreatedBy: string;
  createdAt: Date;
  passedAudit: boolean | null;
  failedVerification: boolean;
  corrected: boolean;
  failedAuditNote: string;
  events: Event[];
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'radio',
    id: 'matched',
    label: 'Matched',
    options: [
      {
        label: 'Not matched and not suggested',
        value: '0',
        active: false,
      },
      {
        label: 'Not matched but suggested',
        value: '1',
        active: false,
      },
      {
        label: 'Matched',
        value: '2',
        active: false,
      },
      {
        label: 'Rejected',
        value: '3',
        active: false,
      },
      {
        label: 'All',
        value: '4',
        active: true,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'audited',
    label: 'Audited',
    options: [
      {
        label: 'Failed and not corrected',
        value: '0',
        active: false,
      },
      {
        label: 'Failed and corrected',
        value: '1',
        active: false,
      },
      {
        label: 'Passed',
        value: '2',
        active: false,
      },
      {
        label: 'All',
        value: '3',
        active: true,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'verification',
    label: 'Verification',
    options: [
      {
        label: 'Failed and not corrected',
        value: '0',
        active: false,
      },
      {
        label: 'Failed and corrected',
        value: '1',
        active: false,
      },
      {
        label: 'Passed',
        value: '2',
        active: false,
      },
      {
        label: 'All',
        value: '3',
        active: true,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'format',
    label: 'Document format',
    options: [
      {
        label: 'Physical',
        value: '0',
        active: false,
      },
      {
        label: 'Digital',
        value: '1',
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'scanned',
    label: 'Scanned',
    options: [
      {
        label: 'Not scanned',
        value: '0',
        active: false,
      },
      {
        label: 'Scanned',
        value: '1',
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'shipped',
    label: 'Shipped',
    options: [
      {
        label: 'Not shipped',
        value: '0',
        active: false,
      },
      {
        label: 'Shipped',
        value: '1',
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'sorted',
    label: 'Sorted',
    options: [
      {
        label: 'Missing investor',
        value: '0',
        active: false,
      },
      {
        label: 'Ready for sorting',
        value: '1',
        active: false,
      },
      {
        label: 'Sorted',
        value: '2',
        active: false,
      },
    ],
  },
];

const ClientDocumentIndex = ({ location }) => {
  const clients = useClients();
  const clientId = queryString.parse(location.search).clientId as string;
  const clientName = useClient(parseInt(clientId))?.company;
  const { data: eventDisplayNameMapping } = useQuery(
    ['event-name-mappings'],
    getEventNameMappings,
    {
      refetchOnWindowFocus: false,
    },
  );
  const tableRef = useRef({} as TableRef);

  const [selectedDocument, setSelectedDocument] = useState<RowData | null>(null);
  const { docTypes } = useContext(DocumentTypesContext);
  const [selectedDocType, setSelectedDocType] = useState<DocumentTypeProperties>(
    {} as DocumentTypeProperties,
  );
  const [isUpdateDocTypeModalOpen, setIsUpdateDocTypeModalOpen] = useState(false);
  const [confirmedDeleteDialogOpen, setConfirmedDeleteDialogOpen] = useState(false);
  const [isDocumentSuggestedMatchesModalOpen, setIsDocumentSuggestedMatchesModalOpen] =
    useState(false);

  const { errorToaster } = useToaster();

  const prepareDocTypeUpdateModal = (event, rowData: RowData) => {
    event.preventDefault();

    setSelectedDocument(rowData);

    if (!selectedDocType.value && docTypes.length) {
      setSelectedDocType(
        docTypes.find(docType => docType.label === rowData.documentType) ??
          ({} as DocumentTypeProperties),
      );
    }
    setIsUpdateDocTypeModalOpen(true);
  };

  const columns: Column<RowData>[] = [
    {
      id: 'documentId',
      label: 'Barcode',
      sortable: true,
      render: ({ documentId }) => (
        <Link target="_blank" href={`/documents/${documentId}`}>
          {documentId}
        </Link>
      ),
    },
    {
      id: 'documentType',
      label: 'Document type',
      sortable: true,
      render: rowData => (
        <div style={{ fontWeight: 'bold' }}>
          <span
            css={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={e => prepareDocTypeUpdateModal(e, rowData)}
          >
            <EditIcon htmlColor={rowData.appNumber ? 'red' : ''} />
          </span>
          <span>{rowData.documentType}</span>

          <ConditionalComponent display={rowData.passedAudit === false && !rowData.corrected}>
            <div style={{ color: 'red', fontSize: '12px' }}>Failed Audit</div>
          </ConditionalComponent>

          <ConditionalComponent display={rowData.failedVerification && !rowData.corrected}>
            <div style={{ color: 'red', fontSize: '12px' }}>Failed Verification</div>
          </ConditionalComponent>

          <ConditionalComponent display={rowData.corrected}>
            <div style={{ color: colors.blue, fontSize: '12px' }}>Corrected</div>
          </ConditionalComponent>
        </div>
      ),
    },
    {
      id: 'appNumber',
      label: 'Loan number',
      sortable: true,
      render: ({ appNumber, loanId }) => (
        <Link target="_blank" href={`/loans/${loanId}`}>
          {appNumber}
        </Link>
      ),
    },
    {
      id: 'hardCopy',
      label: 'Format',
      sortable: true,
      render: ({ hardCopy }) => (hardCopy ? 'Physical' : 'Digital'),
    },
    {
      id: 'documentCreatedBy',
      label: 'User',
      sortable: false,
      render: ({ documentCreatedBy }) =>
        documentCreatedBy?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ''),
    },
    {
      id: 'createdAt',
      label: 'Date created',
      sortable: true,
      render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString(),
    },
    {
      id: 'actions',
      label: 'Actions',
      render: rowData => (
        <div className="df gap-1">
          <div>
            <Tooltip title="Delete document">
              <DeleteIcon
                style={{ color: 'red' }}
                onClick={() => {
                  setSelectedDocument(rowData);
                  setConfirmedDeleteDialogOpen(true);
                }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Download document">
              <CloudDownloadIcon
                color="action"
                onClick={() => downloadDocument(rowData.documentId)}
              />
            </Tooltip>
          </div>
          {!rowData.appNumber && (
            <div>
              <Tooltip title="Search for suggested match">
                <FindInPageIcon
                  color="primary"
                  onClick={() => {
                    setSelectedDocument(rowData);
                    setIsDocumentSuggestedMatchesModalOpen(true);
                  }}
                />
              </Tooltip>
            </div>
          )}
        </div>
      ),
    },
  ];

  const downloadDocument = async (documentId: number) => {
    try {
      const { data } = await apiFetch('/api/documents/getDocumentPdf', {
        responseType: 'blob',
        params: { documentId },
      });

      const url = window.URL.createObjectURL(data as Blob);
      openOrDownloadFile(url, `Document - ${documentId}.pdf`);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage.split('\n')[0] || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const detailPanel = ({ events, failedAuditNote }) => (
    <ConditionalComponent display={events?.length}>
      <div
        style={{
          padding: '16px 60px',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridRowGap: '16px',
        }}
      >
        {events?.map(docEvent => EventDisplay({ docEvent, clients, eventDisplayNameMapping }))}
      </div>
      {!!failedAuditNote && (
        <div style={{ padding: '16px 60px' }}>
          <span style={{ backgroundColor: 'yellow' }}>Failed Audit Note: {failedAuditNote}</span>
        </div>
      )}
    </ConditionalComponent>
  );

  return (
    <div className="m4">
      <Datatable<RowData>
        title={
          <span>
            Documents
            <span css={{ verticalAlign: 'middle' }}>
              <KeyboardArrowRightIcon color="action" />
            </span>
            {clientName}
          </span>
        }
        columns={columns}
        filters={filters}
        searchBarPlaceholder="Search by barcode, loan #, or property address"
        url={`/api/documents/document-index-data?clientId=${clientId}`}
        exportUrl={`/api/documents/document-index-data?clientId=${clientId}`}
        exportFileName={`${clientName} - Document index.xlsx`}
        ref={tableRef}
        detailsPanel={detailPanel}
      />

      <UpdateDocumentTypeModal
        isOpen={isUpdateDocTypeModalOpen}
        setIsOpen={setIsUpdateDocTypeModalOpen}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        selectedDocType={selectedDocType}
        setSelectedDocType={setSelectedDocType}
        refreshDatatable={tableRef.current.refreshTable || (() => {})}
      />

      <DeleteDocumentModal
        isOpen={confirmedDeleteDialogOpen}
        setIsOpen={setConfirmedDeleteDialogOpen}
        selectedDocument={selectedDocument}
        refreshDatatable={tableRef.current.refreshTable || (() => {})}
      />

      <DocumentSuggestedMatchesModal
        isOpen={isDocumentSuggestedMatchesModalOpen}
        setIsOpen={setIsDocumentSuggestedMatchesModalOpen}
        selectedDocument={selectedDocument}
      />
    </div>
  );
};

export default ClientDocumentIndex;
