/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AssociationData as RowData } from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

type Props = {
  investorAssociations: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'client',
    label: 'Client',
    sortable: true,
  },
  {
    id: 'appNumbers',
    label: 'App Numbers',
    sortable: true,
    render: (associationData: RowData) => (
      <div css={{ width: 300 }}>{associationData.appNumbers}</div>
    ),
  },
  {
    id: 'investorAssociation',
    label: 'Investor Association',
    sortable: true,
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'associatedBy',
    label: 'Associated By',
    sortable: true,
  },
  {
    id: 'associatedAt',
    label: 'Associated At',
    sortable: true,
    render: (associationData: RowData) =>
      new Date(associationData.associatedAt).toLocaleDateString(),
  },
];

const AssociationData = ({ investorAssociations }: Props) => {
  return (
    <div style={{ maxHeight: '500px', overflow: 'auto' }}>
      <Datatable<RowData>
        title="Investor Associations"
        columns={columns}
        data={investorAssociations}
        exportFileName="Investor associations.csv"
        dense
      />
    </div>
  );
};

export default AssociationData;
