import React, { FormEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

type Props = {
  search: string;
  onSearchTextChange: (value: string) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  placeholder?: string;
};

const Searchbar = ({ search, onSearchTextChange, onSubmit, placeholder }: Props) => (
  <div style={{ width: '100%', maxWidth: '464px' }}>
    <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
      <form style={{ width: '100%' }} onSubmit={onSubmit}>
        <TextField
          fullWidth
          autoFocus
          autoComplete="one-time-code"
          value={search}
          onChange={event => onSearchTextChange(event.target.value)}
          onPaste={async event => {
            // This needs to happen before reading the clipboard
            const { selectionStart, selectionEnd } = event.target as HTMLInputElement;

            const text = await navigator.clipboard.readText();
            const newLineWithWhitespaceRegex = /(?:\r\n|\r|\n)\s*/g;
            let replacedText = text.replaceAll(newLineWithWhitespaceRegex, ', ');

            // When the user highlights text, we want to replace the highlighted text
            if (selectionStart && selectionEnd) {
              replacedText =
                search.substring(0, selectionStart) + replacedText + search.substring(selectionEnd);
            }

            onSearchTextChange(replacedText);
          }}
          placeholder={placeholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Stack>
  </div>
);

export default Searchbar;
