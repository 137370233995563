/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, forwardRef, SetStateAction } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { Column, Filter } from '../../components/ui/DataTableV2/types';
import Datatable, { TableRef } from '../../components/ui/DataTableV2/Datatable';

export type RowData = {
  id: number;
  client: string;
  loanNumber: string;
  propertyAddress: string;
  borrowerName: string;
  titleCompanyName: string;
  inboundTrackingNumber: string;
  outboundTrackingNumber: string;
  shippingCost: number;
  note: string;
  createdAt: string;
  createdBy: string;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Props = {
  setClosingPackage: Dispatch<SetStateAction<RowData>>;
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'dropdown',
    id: 'clients',
    label: 'Clients',
    multiple: true,
    optionsUrl: '/api/clients/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dateRange',
    id: 'dateAdded',
    label: 'Date added',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
];

export const ClosingPackagesList = forwardRef<TableRef, Props>(({ setClosingPackage }, ref) => {
  const columns: Column<RowData>[] = [
    {
      id: 'client',
      label: 'Client',
      sortable: true,
    },
    {
      id: 'loanNumber',
      label: 'Loan Number',
      sortable: true,
    },
    {
      id: 'propertyAddress',
      label: 'Address',
      sortable: true,
    },
    {
      id: 'borrowerName',
      label: 'Borrower',
      sortable: true,
    },
    {
      id: 'titleCompanyName',
      label: 'Title Company',
      sortable: true,
    },
    {
      id: 'inboundTrackingNumber',
      label: 'Inbound Tracking',
      sortable: true,
    },
    {
      id: 'outboundTrackingNumber',
      label: 'Outbound Tracking',
      sortable: true,
    },
    {
      id: 'shippingCost',
      label: 'Shipping Cost',
      sortable: true,
      render: ({ shippingCost }) => currencyFormat.format(shippingCost),
    },
    {
      id: 'note',
      label: 'Note',
      sortable: true,
    },
    {
      id: 'createdAt',
      label: 'Date Added',
      sortable: true,
      render: ({ createdAt }) => new Date(createdAt).toLocaleDateString(),
    },
    {
      id: 'createdBy',
      label: 'User',
      sortable: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      render: rowData => (
        <Tooltip title="Edit Closing Package">
          <IconButton
            onClick={() => {
              setClosingPackage(rowData);
            }}
          >
            <CreateIcon color="primary" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Datatable<RowData>
      title="Closing Packages"
      columns={columns}
      filters={filters}
      url="/api/closingPackages/getClosingPackages"
      exportUrl="/api/closingPackages/getClosingPackages"
      exportFileName="Closing packages.xlsx"
      searchBarPlaceholder="Search by loan #, inbound tracking #, or outbound tracking #"
      sortConfig={{ field: 'createdAt', direction: 'desc' }}
      ref={ref}
    />
  );
});

export default ClosingPackagesList;
