/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import {
  DocumentEventDrillDownRowData,
  DocumentsMatchedOrRejectedDrillDown,
  DrillDownData,
  RowData,
} from './types';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsMatchedOrRejectedByUser: RowData[];
};

const docEventDrillDownColumns = [
  {
    id: 'barcode',
    label: 'Barcode',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
    render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: ({ date }) => new Date(date).toLocaleDateString(),
  },
  {
    id: 'matchStatus',
    label: 'Match Status',
    sortable: true,
  },
];

const documentsMatchColumns: Column<DocumentsMatchedOrRejectedDrillDown>[] = [
  {
    id: 'id',
    label: 'Document Id',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
  },
  {
    id: 'documentType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'createdAt',
    label: 'Date',
    sortable: true,
    render: ({ createdAt }) =>
      createdAt ? new Date(createdAt).toLocaleDateString() : <Fragment />,
  },
  {
    id: 'hardCopy',
    label: 'Hard Copy',
    sortable: true,
    render: ({ hardCopy }) => (hardCopy ? 'Yes' : 'No'),
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'matchStatus',
    label: 'Match Status',
    sortable: true,
  },
];

export const MatchByUser = ({ documentsMatchedOrRejectedByUser }: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [userDrillDownData, setUserDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'count',
      label: 'Count',
      sortable: true,
      render: ({ count, date, user }) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setUserDrillDownData({
              date: new Date(date),
              user: user,
            })
          }
        >
          {count}
        </Link>
      ),
    },
    {
      id: 'user',
      label: 'User',
      sortable: true,
      render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    },
    {
      id: 'date',
      label: 'Date',
      sortable: true,
      render: ({ date }) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: '3px',
            right: '10px',
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          Drilldown
        </Button>

        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
          <Datatable<RowData>
            title="Documents Matched Or Rejected - By User"
            columns={columns}
            data={documentsMatchedOrRejectedByUser}
            exportFileName="Documents Matched Or Rejected - By User.csv"
            dense
          />
        </div>
      </div>

      <Modal open={!!userDrillDownData} onClose={() => setUserDrillDownData(null)}>
        <div className={classes.paper}>
          <Datatable<DocumentEventDrillDownRowData>
            title={`Document Match Drill Down Report - For ${userDrillDownData?.user}`}
            columns={docEventDrillDownColumns}
            url={`/api/reports/document-match-by-user-drill-down-report?${queryString.stringify({
              user: userDrillDownData?.user,
              date: userDrillDownData?.date.toISOString().split('T')[0],
            })}`}
            exportUrl={`/api/reports/document-match-by-user-drill-down-report?${queryString.stringify(
              {
                user: userDrillDownData?.user,
                date: userDrillDownData?.date.toISOString().split('T')[0],
              },
            )}`}
            exportFileName="Document Match Drill Down Report.xlsx"
          />
        </div>
      </Modal>

      <Modal open={isAllDrillDownModalOpen} onClose={() => setIsAllDrillDownModalOpen(false)}>
        <div className={classes.paper}>
          <Datatable<DocumentsMatchedOrRejectedDrillDown>
            title="Document Match Drill Down Report"
            columns={documentsMatchColumns}
            url="/api/reports/document-match-drill-down-report"
            exportUrl="/api/reports/document-match-drill-down-report"
            exportFileName="Document Match Drill Down Report.xlsx"
            dense
          />
        </div>
      </Modal>
    </Fragment>
  );
};
