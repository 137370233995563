/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CSSProperties, Fragment, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { Column, DatatableRow, Primitive } from './types';
import ExpandedListItem from './ExpandedListItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from '@mui/material/Checkbox';
import { SelectionAction } from './Datatable';

type Props<Row extends DatatableRow> = {
  rowData: Row;
  columns: Column<Row>[];
  detailsPanel?: (rowData: Row) => JSX.Element;
  isRowSelected?: boolean;
  onRowSelected?: SelectionAction[];
  onCheckboxClick: (rowId: number) => void;
  rowStyle?: (row: Row) => CSSProperties;
};

const ListItem = <Row extends DatatableRow>({
  rowData,
  columns,
  detailsPanel,
  isRowSelected,
  onCheckboxClick,
  onRowSelected,
  rowStyle,
}: Props<Row>) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);

  return (
    <Fragment>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        style={rowStyle ? rowStyle(rowData) : {}}
        hover
      >
        {onRowSelected !== undefined && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isRowSelected}
              onClick={() => onCheckboxClick(rowData.rowId as number)}
            />
          </TableCell>
        )}
        {columns.map((column, i) => (
          <TableCell key={column.id}>
            {column.render ? column.render(rowData) : (rowData[column.id] as Primitive)}
          </TableCell>
        ))}

        {!!detailsPanel && (
          <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
            <IconButton
              color={isRowExpanded ? 'inherit' : 'default'}
              onClick={() => setIsRowExpanded(x => !x)}
              sx={{
                ...(isRowExpanded && {
                  bgcolor: 'action.hover',
                }),
              }}
            >
              {isRowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      {!!detailsPanel && (
        <ExpandedListItem
          rowData={rowData}
          detailsPanel={detailsPanel}
          isRowExpanded={isRowExpanded}
          numberOfColumns={columns.length}
        />
      )}
    </Fragment>
  );
};

export default ListItem;
