/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { CopyIcon } from '../../ui/icons';
import { useToaster } from '../../../Hooks/toasters';

type Props = {
  text: string;
};
const CopyToClipboard = ({ text }: Props) => {
  const [copied, setCopied] = useState(false);

  const { warningToaster } = useToaster();

  const copyToClipboard = async (text: string) => {
    if (!text.length) {
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (e) {
      warningToaster('Please allow clipboard access');
    }
  };

  if (copied) {
    return <span css={{ color: 'green' }}>Copied!</span>;
  }

  return (
    <CopyIcon
      onClick={async () => await copyToClipboard(text)}
      css={{ maxWidth: 18, cursor: 'pointer', '&:hover': { g: { fill: 'blue' } } }}
    />
  );
};

export default CopyToClipboard;
