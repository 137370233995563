/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { Client, FlaggedDoc, FlagInformation, Loan } from '../../globalTypes/objects';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import LoanDropdown from '../../components/Utilities/Dropdowns/LoanDropdown';
import { parseRequestError } from '../../Utils';
import SetFlagDocument from './SetFlagDocument';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(_theme => ({
  modalPaper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    backgroundColor: _theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: _theme.shadows[5],
    padding: _theme.spacing(2, 4, 3),
  },
}));

type Props = {
  flagInformation?: FlagInformation;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function FlagDocumentModal({ setModalOpen, flagInformation }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.modalPaper}>
      <SetFlagDocument
        flagInformation={flagInformation}
        setIsFlagDocumentOpen={setModalOpen}
        flaggedDocs={[]}
        setFlaggedDocs={() => {}}
        setHasPartialData={() => {}}
      />
    </div>
  );
}
