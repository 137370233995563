/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { apiFetch } from '../../adalConfig';
import { GeneralReporting as GeneralReportingType } from './types';
import ConditionalComponent from '../../components/ConditionalComponent';
import { ShippingByUser } from './Shipping';
import { StapleByUser } from './Stapling';
import { MatchByUser } from './Matching';
import Coversheet from './Coversheet';
import { ScannedIntoAzureByUser } from './Scanning';
import { SortedByUser } from './Sorting';
import { DeterminationByUser } from './Determination';
import DigitalImportsByUser from './DigitalImports';
import QueueDisplay from './QueueDisplay';
import AggregateProcessingStats from './AggregateProcessingStats';
import AssociationsGroupedByUser from './AssociationsGroupedByUser';
import CallCenterGroupedByUser from './CallCenterGroupedByUser';
import ClientData from './ClientData';
import WaitingToBeScannedIntoAzureDrillDown from './WaitingToBeScannedIntoAzureDrillDown';
import WaitingToBeStapledDrillDown from './WaitingToBeStapledDrillDown';
import AssociationData from './InvestorAssociations';
import ClientInvestors from './InvestorsPerClient';
import { DocumentsCorrectedByUser } from './DocumentsCorrectedCount';
import CallCenterProgressHeader from './CallCenterProgressByUser';
import CallCenterDocumentsFromCallsHeader from './CallCenterDocumentsFromCalls';
import DocumentsAgingForShipping from './DocumentsAgingForShipping';
import CorrectionsTotalByMonthAndClientReport from './CorrectionsByMonthAndClient';
import { useToaster } from '../../Hooks/toasters';
import DigitalDeliveryLog from './DigitalDeliveryLog';
import AutomatedAudits from './AutomatedAudits';
import MonthlyPayments from './MonthlyPayments';
import { Client } from '../../globalTypes/objects';
import AutoDeletedDocuments from './AutoDeletedDocuments';
import { CoversheetPreprintedByUser } from './CoversheetPreprintedByUser';
import CompletedLoans from './CompletedLoans';
import TitleUploadsNotPreAssigned from './TitleUploadsNotPreAssigned';
import PotentialCureDocuments from './PotentialCureDocuments';
import ClientUpdatedOnDocument from './ClientUpdatedOnDocument';
import GuildRetainedDocuments from './GuildRetainedDocuments';
import { FulfilledByUser } from './FulfilledByUser';
import ClientLoans from './ClientLoans';
import MissingData from './MissingData';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  reportsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    width: '100%',
    '& > div': {
      overflowX: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2, 4, 3),
    },
  },
}));

const GeneralReporting = ({ location }) => {
  const selectedReports: Array<string> = location.state?.reports ?? [];
  const selectedClient: Client = location.state?.client;
  const fromDate: Date = location.state?.fromDate ?? new Date();

  const [reports, setReports] = useState<Partial<GeneralReportingType>>({});
  const [showLoader, setShowLoader] = useState(true);

  const classes = useStyles();
  const { errorToaster, warningToaster } = useToaster();
  const history = useHistory();

  useEffect(() => {
    const getReports = async () => {
      const queryParams = queryString.stringify(
        {
          reports: selectedReports,
          fromDate: fromDate.toLocaleDateString(),
          clientId: selectedClient?.id ?? null,
        },
        { arrayFormat: 'index' },
      );

      const { data } = await apiFetch<typeof reports>(
        `/api/reports/general-reporting?${queryParams}`,
      );
      setReports(data);
    };

    if (!selectedReports.length) {
      warningToaster('No reports selected');
      history.push('/reports-dashboard/landing-page');
      return;
    }

    getReports()
      .catch(e => {
        if (e.response?.status === 403) {
          errorToaster("You don't have access to view this report");
          history.push('/reports-dashboard/landing-page');
        } else if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      })
      .finally(() => setShowLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showLoader) {
    return (
      <div className="center-in-parent">
        <CircularProgress size="75" disableShrink />
      </div>
    );
  }

  return (
    <div className="m2">
      <div className={classes.reportsContainer}>
        {!!reports.documentsShippedByUser && (
          <div>
            <ShippingByUser documentsShippedByUser={reports.documentsShippedByUser} />
          </div>
        )}
        {!!reports.documentsStapledByUser && (
          <div>
            <StapleByUser documentsStapledByUser={reports.documentsStapledByUser} />
          </div>
        )}
        {!!reports.investorAssociations && (
          <div>
            <AssociationData investorAssociations={reports.investorAssociations} />
          </div>
        )}
        {!!reports.investorsPerClient && (
          <div>
            <ClientInvestors investorsPerClient={reports.investorsPerClient} />
          </div>
        )}
        {!!reports.documentsCorrectedByUser && (
          <div>
            <DocumentsCorrectedByUser documentsCorrectedByUser={reports.documentsCorrectedByUser} />
          </div>
        )}
        {!!reports.coversheetPreprintedByUser && (
          <div>
            <CoversheetPreprintedByUser
              coversheetPreprintedByUser={reports.coversheetPreprintedByUser}
            />
          </div>
        )}
        {!!reports.documentsMatchedOrRejectedByUser && (
          <div>
            <MatchByUser
              documentsMatchedOrRejectedByUser={reports.documentsMatchedOrRejectedByUser}
            />
          </div>
        )}
        {!!reports.coversheetCreatedEventsByUser && (
          <div>
            <Coversheet coversheetCreatedEventsByUser={reports.coversheetCreatedEventsByUser} />
          </div>
        )}
        {!!reports.pdfImageSavedEventsByUser && (
          <div>
            <ScannedIntoAzureByUser pdfImageSavedEventsByUser={reports.pdfImageSavedEventsByUser} />
          </div>
        )}
        {!!reports.sortedEventsByUser && (
          <div>
            <SortedByUser sortedEventsByUser={reports.sortedEventsByUser} />
          </div>
        )}
        {!!reports.determinationPerformedEventsByUser && (
          <div>
            <DeterminationByUser
              determinationPerformedEventsByUser={reports.determinationPerformedEventsByUser}
            />
          </div>
        )}
        {!!reports.documentsAuditedAutomatically && (
          <div>
            <AutomatedAudits
              documentsAuditedAutomatically={reports.documentsAuditedAutomatically}
            />
          </div>
        )}
        {!!reports.digitalUploadedEventsByUser && (
          <div>
            <DigitalImportsByUser
              digitalUploadedEventsByUser={reports.digitalUploadedEventsByUser}
            />
          </div>
        )}
        {!!reports.associationsGroupedByUser && (
          <div>
            <AssociationsGroupedByUser
              associationsGroupedByUser={reports.associationsGroupedByUser}
            />
          </div>
        )}
        {!!reports.callCenterGroupedByUser && (
          <div>
            <CallCenterGroupedByUser callCenterGroupedByUser={reports.callCenterGroupedByUser} />
          </div>
        )}
        {!!reports.callCenterProgressByUser && (
          <div>
            <CallCenterProgressHeader callCenterProgressHeader={reports.callCenterProgressByUser} />
          </div>
        )}
        {!!reports.callCenterDocumentsFromCallsMonthly && (
          <div>
            <CallCenterDocumentsFromCallsHeader
              callCenterDocumentsFromCallsHeader={reports.callCenterDocumentsFromCallsMonthly}
            />
          </div>
        )}
        {!!reports.callCenterDocumentsFromCallsWeekly && (
          <div>
            <CallCenterDocumentsFromCallsHeader
              callCenterDocumentsFromCallsHeader={reports.callCenterDocumentsFromCallsWeekly}
            />
          </div>
        )}
        {!!reports.clientData && (
          <div>
            <ClientData clientData={reports.clientData} />
          </div>
        )}

        {!!reports.documentsAgingForShipping14 && (
          <div>
            <DocumentsAgingForShipping
              documentsAgingForShipping={reports.documentsAgingForShipping14}
            />
          </div>
        )}
        {!!reports.documentsAgingForShipping30Sorted && (
          <div>
            <DocumentsAgingForShipping
              documentsAgingForShipping={reports.documentsAgingForShipping30Sorted}
            />
          </div>
        )}
        {!!reports.documentsAgingForShipping90 && (
          <div>
            <DocumentsAgingForShipping
              documentsAgingForShipping={reports.documentsAgingForShipping90}
            />
          </div>
        )}
        {!!reports.correctionsTotalByMonthAndClientOld && (
          <div>
            <CorrectionsTotalByMonthAndClientReport
              correctionsTotalByMonthAndClient={reports.correctionsTotalByMonthAndClientOld}
              fromDate={fromDate}
              oldVersion={true}
            />
          </div>
        )}
        {!!reports.correctionsTotalByMonthAndClient && (
          <div>
            <CorrectionsTotalByMonthAndClientReport
              correctionsTotalByMonthAndClient={reports.correctionsTotalByMonthAndClient}
              fromDate={fromDate}
              oldVersion={false}
            />
          </div>
        )}
        {!!reports.completedLoans && (
          <div>
            <CompletedLoans completedLoans={reports.completedLoans} client={selectedClient} />
          </div>
        )}
        {!!reports.monthlyPayments && (
          <div>
            <MonthlyPayments monthlyPayments={reports.monthlyPayments} fromDate={fromDate} />
          </div>
        )}
        {!!reports.aggregateProcessingStats && (
          <div>
            <AggregateProcessingStats
              aggregateProcessingStats={reports.aggregateProcessingStats}
              client={selectedClient.company}
            />
          </div>
        )}
        {!!reports.autoDeletedDocs && (
          <div>
            <AutoDeletedDocuments autoDeletedDocs={reports.autoDeletedDocs} fromDate={fromDate} />
          </div>
        )}
        {!!reports.titleUploadsNotPreAssigned && (
          <div>
            <TitleUploadsNotPreAssigned
              titleUploadsNotPreAssigned={reports.titleUploadsNotPreAssigned}
              fromDate={fromDate}
            />
          </div>
        )}
        {!!reports.digitalDeliveryLog && (
          <div>
            <DigitalDeliveryLog reportData={reports.digitalDeliveryLog} />
          </div>
        )}
        {!!reports.correctionsWithPotentialCure && (
          <div>
            <PotentialCureDocuments reportData={reports.correctionsWithPotentialCure} />
          </div>
        )}
        {!!reports.clientUpdatedOnDocument && (
          <div>
            <ClientUpdatedOnDocument reportData={reports.clientUpdatedOnDocument} />
          </div>
        )}
        {!!reports.guildRetainedDocuments && (
          <div>
            <GuildRetainedDocuments
              reportData={reports.guildRetainedDocuments}
              fromDate={fromDate}
            />
          </div>
        )}
        {!!reports.missingData && (
          <div>
            <MissingData reportData={reports.missingData} />
          </div>
        )}
        {!!reports.fulfilledByUser && (
          <div>
            <FulfilledByUser reportData={reports.fulfilledByUser} fromDate={fromDate} />
          </div>
        )}
        {!!reports.clientLoans && (
          <div>
            <ClientLoans reportData={reports.clientLoans} fromDate={fromDate} />
          </div>
        )}
        <ConditionalComponent
          display={
            reports.readyToBeScannedIntoAzureCount ||
            reports.readyToBeBatchedCount ||
            reports.readyToBeMatched ||
            reports.readyToBeMatchedAsOfTodayCount ||
            reports.readyToBeDeterminedCount ||
            reports.readyToBeDeterminedAsOfTodayCount ||
            reports.readyToBeShippedCount ||
            reports.digitalsNotBeingShippedCount ||
            reports.readyToBeSortedCount ||
            reports.pendingCorrectionCount ||
            reports.pendingTitleCompanyAssociationCount ||
            reports.pendingInvestorAssociationCount
          }
        >
          <div>
            <div style={{ height: '100%' }}>
              <Typography gutterBottom variant="h5" component="h2">
                Queue
              </Typography>
              {!!reports.readyToBeScannedIntoAzureCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Scanned"
                    value={reports.readyToBeScannedIntoAzureCount}
                  >
                    <WaitingToBeScannedIntoAzureDrillDown />
                  </QueueDisplay>
                </Typography>
              )}
              {!!reports.readyToBeMatched && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Unique Documents Ready To Be Matched:</td>
                          <td className="pl1">{reports.readyToBeMatched.uniqueDocumentsCount}</td>
                        </tr>
                        <tr>
                          <td className="tar pt1">Total Matches:</td>
                          <td className="pl1">
                            {reports.readyToBeMatched.matchCount}
                            <Link
                              href={`${window.location.origin}/reports-dashboard/general-reporting/matching`}
                              target="_blank"
                              className="ml2"
                            >
                              more...
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Typography>
              )}
              {!!reports.readyToBeMatchedAsOfTodayCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Added to Match Queue Today"
                    value={reports.readyToBeMatchedAsOfTodayCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/matchingAsOfToday`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeBroadMatchedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Broad Matched"
                    value={reports.readyToBeBroadMatchedCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/broadMatching`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeBatchedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Batched" value={reports.readyToBeBatchedCount}>
                    <WaitingToBeStapledDrillDown />
                  </QueueDisplay>
                </Typography>
              )}
              {!!reports.readyToBeDeterminedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Audited"
                    value={reports.readyToBeDeterminedCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/determination`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {reports.readyToBeDeterminedAsOfTodayCount != undefined && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Added To Audit Queue Today"
                    value={reports.readyToBeDeterminedAsOfTodayCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/determinationAsOfToday`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeShippedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Shipped" value={reports.readyToBeShippedCount} />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/shipping`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.digitalsNotBeingShippedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Digitals Not Being Shipped"
                    value={reports.digitalsNotBeingShippedCount}
                  />
                </Typography>
              )}
              {!!reports.readyToBeSortedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Sorted" value={reports.readyToBeSortedCount} />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/sorting`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingCorrectionCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Documents Pending Correction"
                    value={reports.pendingCorrectionCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/pending-correction`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingTitleCompanyAssociationCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Title Companies Pending Association"
                    value={reports.pendingTitleCompanyAssociationCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/title-companies-pending-association`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingInvestorAssociationCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Investors Pending Association"
                    value={reports.pendingInvestorAssociationCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/investors-pending-association`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
            </div>
          </div>
        </ConditionalComponent>
      </div>
    </div>
  );
};

export default GeneralReporting;
