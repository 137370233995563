import React, { Fragment, useState } from 'react';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Link from '@mui/material/Link';
import { DocumentCorrectionsDrillDownReport, DrillDownData, RowData } from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsCorrectedByUser: RowData[];
};

const docCorrectionsDrillDownColumns: Column<DocumentCorrectionsDrillDownReport>[] = [
  {
    id: 'barcode',
    label: 'Barcode',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
    render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: ({ date }) => new Date(date).toLocaleDateString(),
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'failedAuditDate',
    label: 'Failed Audit Date',
    sortable: true,
    render: ({ failedAuditDate }) =>
      failedAuditDate !== null ? new Date(failedAuditDate!).toLocaleDateString() : <Fragment />,
  },
  {
    id: 'failedVerificationDate',
    label: 'Failed Verification Date',
    sortable: true,
    render: ({ failedVerificationDate }) =>
      failedVerificationDate !== null ? (
        new Date(failedVerificationDate!).toLocaleDateString()
      ) : (
        <Fragment />
      ),
  },
  {
    id: 'failedAuditReason',
    label: 'Failed Audit Reason',
    sortable: true,
  },
  {
    id: 'failedAuditNotes',
    label: 'Failed Audit Notes',
    sortable: true,
  },
  {
    id: 'failedVerificationNotes',
    label: 'Failed Verification Notes',
    sortable: true,
  },
];

export const DocumentsCorrectedByUser = ({ documentsCorrectedByUser }: Props) => {
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'count',
      label: 'Count',
      sortable: true,
      render: ({ count, date, user }) => (
        <Link
          component="button"
          variant="body2"
          underline="hover"
          onClick={() =>
            setDrillDownData({
              date: new Date(date),
              user: user,
            })
          }
        >
          {count}
        </Link>
      ),
    },
    {
      id: 'user',
      label: 'User',
      sortable: true,
      render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    },
    {
      id: 'date',
      label: 'Date',
      sortable: true,
      render: ({ date }) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title="Documents Corrected - By User"
          columns={columns}
          data={documentsCorrectedByUser}
          exportFileName="Documents Corrected - By User.csv"
          dense
        />
      </div>

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <div className={classes.paper}>
          <Datatable<DocumentCorrectionsDrillDownReport>
            title="Documents Corrected Drill Down Report"
            columns={docCorrectionsDrillDownColumns}
            url={`/api/reports/document-corrections-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              documentEvent: 'DocumentCured',
            })}`}
            exportUrl={`/api/reports/document-corrections-drill-down-report?${queryString.stringify(
              {
                user: drillDownData?.user,
                date: drillDownData?.date.toISOString().split('T')[0],
                documentEvent: 'DocumentCured',
              },
            )}`}
            exportFileName="Document Corrected Drill Down Report.xlsx"
            dense
          />
        </div>
      </Modal>
    </Fragment>
  );
};
