import React from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import { FilterChips } from './types';

type Props = {
  filterChips: FilterChips;
  clearFilter: (filterId: string) => void;
  clearFilters: () => void;
  totalCount: number;
};

const numberFormat = new Intl.NumberFormat('en-US');

const SelectedFiltersPanel = ({ filterChips, clearFilter, clearFilters, totalCount }: Props) => {
  if (!filterChips.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div>
          <i>{numberFormat.format(totalCount)} found</i>
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
        {filterChips.map(filterData => (
          <Block key={filterData.filterId} label={`${filterData.label}:`}>
            <Chip
              size="small"
              label={filterData.value}
              onDelete={() => clearFilter(filterData.filterId)}
            />
          </Block>
        ))}

        <Button color="error" onClick={clearFilters} startIcon={<DeleteIcon />}>
          Clear
        </Button>
      </div>
      <div style={{ fontStyle: 'italic', whiteSpace: 'nowrap' }}>
        {numberFormat.format(totalCount)} found
      </div>
    </div>
  );
};

export default SelectedFiltersPanel;

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
