/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useContext, useState } from 'react';
import GradingIcon from '@mui/icons-material/Grading';
import MailIcon from '@mui/icons-material/Mail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import compareAsc from 'date-fns/compareAsc';
import { AuthContext } from '../AuthContext';
import { roleTypes } from '../../constants';
import { RowData, SidebarOptions } from './types';
import colors from '../../styles/colors';
import { poBoxDisplay } from '../../pages/CallCenter';
import differenceInDays from 'date-fns/differenceInDays';
import { Column, RowDataForExport, SortFunc } from '../ui/DataTableV2/types';
import MaterialTable, { SelectionAction } from '../ui/DataTableV2/Datatable';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    linkStyle: {
      color: 'blue',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export const sortDataByPhoneAndClient: SortFunc<RowData> = (a: RowData, b: RowData): number => {
  const phoneComparisonResult = comparePhoneNumbers(a, b);
  if (phoneComparisonResult !== 0) {
    return phoneComparisonResult;
  }

  const clientComparisonResult = compareClients(a, b);
  if (clientComparisonResult !== 0) {
    return clientComparisonResult;
  }

  return compareAsc(new Date(a.dateFunded), new Date(b.dateFunded));
};

const comparePhoneNumbers = (a: RowData, b: RowData) => {
  if (a.loanLevelContactInformation.phone < b.loanLevelContactInformation.phone) {
    return -1;
  }

  if (a.loanLevelContactInformation.phone > b.loanLevelContactInformation.phone) {
    return 1;
  }

  return 0;
};

const compareClients = (a: RowData, b: RowData) => {
  if (a.clientInfo.client < b.clientInfo.client) {
    return -1;
  }

  if (a.clientInfo.client > b.clientInfo.client) {
    return 1;
  }

  return 0;
};

type Props = {
  datatableData: RowData[];
  isClientMode: boolean;
  isLoading: boolean;
  setSelectedRows: Dispatch<SetStateAction<RowData[]>>;
  selectedRows: RowData[];
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
};

export const formatNotes = (notes: RowData['pastNotes']) =>
  notes.map((note, i) => (
    <p key={i}>
      <b>{new Date(note.dateEntered).toLocaleDateString()}</b> -{' '}
      {note.username ? (
        <Fragment>
          <b>{note.username}</b> -{' '}
        </Fragment>
      ) : (
        ''
      )}
      {note.text}
    </p>
  ));

export const customExport = (dataTableRows: RowData[], isClientMode: boolean) => {
  return dataTableRows.map(row => {
    const { borrower, address, city, state, zip, county } = row.propertyInfo;
    let lastNote = '';
    if (row.pastNotes.length > 0) {
      const { dateEntered, username, text } = row.pastNotes[0];
      lastNote += new Date(dateEntered).toLocaleDateString() + ' - ';
      lastNote += username ? `${username} - ` : '';
      lastNote += text;
    }

    const daysAged = row.dateFunded ? differenceInDays(new Date(), new Date(row.dateFunded)) : 0;

    const exportRow: RowDataForExport = {
      'Loan Id': row.id,
      Stage: row.calculatedOutreachStage,
      'Loan Number': row.appNumber,
      'Title Company': row.titleCompany,
      'Title Number': row.titleNumber,
      Borrower: borrower,
      Address: address,
      City: city,
      State: state,
      Zip: zip,
      County: county,
      'Date Funded': new Date(row.dateFunded).toLocaleDateString(),
      'Days Aged': `${daysAged}/${row.daysAgedDeadline}`,
      'Missing Docs': row.missingDocuments,
      'Requires Original': row.requiresOriginalDoc ? 'Yes' : 'No',
      'Latest Disposition': row.latestDisposition?.reason || '',
      'Last Note': lastNote,
    };

    if (!isClientMode) {
      exportRow.Lender = row.clientInfo.client;
      exportRow['Lender Inbox'] = row.clientInfo.clientInbox;
      exportRow['Lender Address'] = row.clientInfo.address;
      exportRow['Lender PO Box'] = row.clientInfo.poBox;
      exportRow[
        'Lender City/State/Zip'
      ] = `${row.clientInfo.city}, ${row.clientInfo.state} ${row.clientInfo.zip}`;
    }

    return exportRow;
  });
};

export const actions = (
  allSelectedResolved: boolean,
  someSelectedResolved: boolean,
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>,
) => {
  const conflictingResolutions = someSelectedResolved && !allSelectedResolved;

  return [
    {
      key: 'updateContactInfo',
      tooltip: 'Update contact information',
      icon: () => <ContactPageIcon style={{ color: 'black' }} />,
      onClick: () => setSidebarOption(prevValue => (prevValue === 'other' ? undefined : 'other')),
    },
    {
      key: 'dispositions',
      tooltip: conflictingResolutions
        ? 'Disabled - one or more loans already have a disposition set '
        : allSelectedResolved
        ? 'Disabled - disposition is already set'
        : 'Set disposition',
      icon: () => (
        <GradingIcon
          style={{
            color:
              !allSelectedResolved && !conflictingResolutions ? 'black' : 'rgba(0, 0, 0, 0.25)',
          }}
        />
      ),
      disabled: allSelectedResolved || conflictingResolutions,
      onClick: () =>
        setSidebarOption(prevValue => (prevValue === 'disposition' ? undefined : 'disposition')),
    },
    {
      key: 'email',
      tooltip: conflictingResolutions
        ? `Disabled - one or more loans don't have a disposition set`
        : !allSelectedResolved
        ? 'Disabled - Please set the disposition'
        : 'Send email',
      icon: () => (
        <MailIcon
          style={{
            color: allSelectedResolved && !conflictingResolutions ? 'black' : 'rgba(0, 0, 0, 0.25)',
          }}
        />
      ),
      disabled: !allSelectedResolved || conflictingResolutions,
      onClick: () => setSidebarOption(prevValue => (prevValue === 'email' ? undefined : 'email')),
    },
  ] as SelectionAction[];
};

const Datatable = ({
  datatableData,
  isClientMode,
  isLoading,
  setSelectedRows,
  selectedRows,
  setSidebarOption,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);

  const { roles } = useContext(AuthContext);
  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'ID',
      sortable: true,
      searchable: true,
      hidden: !roles.includes(roleTypes.Dev),
      render: ({ id }: RowData) => (
        <Link href={`/loans/${id}`} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      id: 'calculatedOutreachStage',
      label: 'Stage',
    },
    {
      id: 'appNumber',
      label: 'Loan/Title number',
      searchable: true,
      render: ({ id, appNumber, titleNumber }: RowData) => (
        <Fragment>
          <div>
            <Link href={`/loans/${id}`} target="_blank">
              {appNumber}
            </Link>
          </div>
          <div style={{ color: 'grey' }}>{titleNumber}</div>
        </Fragment>
      ),
    },
    {
      id: 'clientInformation',
      label: 'Client',
      hidden: isClientMode,
      render: ({ clientInfo }: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{clientInfo.client}</div>
          <span css={{ color: 'blue' }}>{clientInfo.clientInbox}</span>
          <div style={{ color: 'grey' }}>
            <div>{poBoxDisplay(clientInfo)}</div>
          </div>
        </div>
      ),
    },
    {
      id: 'propertyInfo',
      label: 'Property info',
      render: ({ propertyInfo }: RowData) => (
        <Fragment>
          <div>
            <b>{propertyInfo.borrower}</b>
          </div>
          <div>{propertyInfo.address}</div>
          <div>
            <span>{propertyInfo.city}</span>&nbsp;
            <span>{propertyInfo.state}</span>&nbsp;
            <span>{propertyInfo.zip}</span>&nbsp;
          </div>
          <div>{propertyInfo.county}</div>
        </Fragment>
      ),
    },
    {
      id: 'dateFunded',
      label: 'Date funded',
      sortable: true,
      render: ({ dateFunded }: RowData) => dateFunded && new Date(dateFunded).toLocaleDateString(),
    },
    {
      id: 'daysAged',
      label: 'Days aged',
      sortable: true,
      render: ({ dateFunded, daysAgedDeadline }: RowData) => {
        if (!dateFunded) {
          return null;
        }

        const daysAged = differenceInDays(new Date(), new Date(dateFunded));
        return `${daysAged}/${daysAgedDeadline}`;
      },
    },
    {
      id: 'missingDocuments',
      label: 'Missing documents',
      render: ({ missingDocuments, requiresOriginalDoc }: RowData) => (
        <Fragment>
          {missingDocuments.split(',').map((doc, i, arr) => (
            <Fragment key={doc}>
              <span
                style={{
                  color: requiresOriginalDoc && doc.trim() !== 'Policy' ? 'red' : 'inherit',
                }}
              >
                {doc}
              </span>
              {i !== arr.length - 1 && ', '}
            </Fragment>
          ))}
        </Fragment>
      ),
    },
    {
      id: 'loanLevelContactInformation',
      label: 'Loan contacts',
      sortable: true,
      customSort: sortDataByPhoneAndClient,
      render: ({ loanLevelContactInformation, titlePortalContactEmails }: RowData) => (
        <div css={{ maxHeight: 128, overflow: 'auto' }}>
          {(loanLevelContactInformation.phone || loanLevelContactInformation.email) && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark }}>Loan Level:</div>
              <div>
                <a
                  className={classes.linkStyle}
                  href={`tel:${loanLevelContactInformation.phone?.replace(/\D/g, '')}`}
                >
                  {loanLevelContactInformation.phone}
                </a>
              </div>
              <div>{loanLevelContactInformation.email}</div>
            </Fragment>
          )}
          {titlePortalContactEmails.length > 0 && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark, paddingTop: 8 }}>
                Title Portal Contacts:
              </div>
              {titlePortalContactEmails.map(e => (
                <div key={e}>{e}</div>
              ))}
            </Fragment>
          )}
        </div>
      ),
    },
    {
      id: 'pastNotes',
      label: 'Past Notes',
      render: rowData =>
        rowData.pastNotes.length ? (
          <div className="df">
            <div
              style={{ width: '310px', height: '66px', overflowY: 'auto', whiteSpace: 'normal' }}
            >
              {formatNotes(rowData.pastNotes)}
            </div>
            <div>
              <OpenWithIcon style={{ fontSize: '10px' }} onClick={() => setSelectedRow(rowData)} />
            </div>
          </div>
        ) : null,
    },
  ];

  if (isLoading) {
    return (
      <CircularProgress
        css={{ position: 'fixed', top: '50%', left: '50%', zIndex: 11 }}
        size="75"
        disableShrink
      />
    );
  }

  let exportFilenamePrefix = '';
  if (datatableData.length > 0) {
    exportFilenamePrefix = isClientMode
      ? `${datatableData[0].clientInfo.client} - ${datatableData[0].titleCompany}`
      : datatableData[0].titleCompany;
  }

  return (
    <Fragment>
      <MaterialTable
        title="Outstanding Loans"
        columns={columns}
        data={datatableData}
        exportFileName={`${exportFilenamePrefix} Loans.csv`}
        customExport={rows => customExport(rows, isClientMode)}
        searchBarPlaceholder="Search by loan id or loan #"
        dense
        sortConfig={{ field: 'loanLevelContactInformation', direction: 'asc' }}
        rowStyle={({ isResolved }) => ({
          backgroundColor: isResolved ? 'rgb(237, 247, 237)' : '#FFF',
        })}
        rowSelectionActions={actions(
          selectedRows.every(r => r.isResolved),
          selectedRows.some(r => r.isResolved),
          setSidebarOption,
        )}
        onSelectionChange={rows => {
          setSelectedRows(rows);
          setSidebarOption(undefined);
        }}
      />

      <Modal open={!!selectedRow} onClose={() => setSelectedRow(null)}>
        <div className={classes.paper}>{selectedRow && formatNotes(selectedRow.pastNotes)}</div>
      </Modal>
    </Fragment>
  );
};

export default Datatable;
