import React, { Fragment, useEffect, useState } from 'react';
import { match as matchType } from 'react-router-dom';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';
import MappedLoanImportDataTable from '../../components/Loans/MappedLoanImportDataTable';
import { jsx } from '@emotion/core';
import Link from '@mui/material/Link';

type RowData = {
  barcode: number;
  dateCreated: Date;
  documentType: string;
  client: string;
  loanNumber: string;
  loanId: number | null;
  matchedBy: string;
  dateAddedToQueue: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'barcode',
    label: 'Barcode',
    render: ({ barcode }: RowData) => (
      <Link target="_blank" href={`/documents/${barcode}`}>
        {barcode}
      </Link>
    ),
  },
  {
    id: 'dateCreated',
    label: 'Date Created',
    render: (rowData: RowData) =>
      rowData.dateCreated && new Date(rowData.dateCreated).toLocaleDateString(),
  },
  {
    id: 'documentType',
    label: 'Document Type',
  },
  {
    id: 'client',
    label: 'Client',
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    render: ({ loanId, loanNumber }: RowData) => (
      <Link target="_blank" href={`/loans/${loanId}`}>
        {loanNumber}
      </Link>
    ),
  },
];

type Props = { match: matchType<{ clientId: string }> };

const AuditQueueByClientDrillDownReport = ({ match }: Props) => {
  const clientId = parseInt(match.params.clientId, 10);

  const [isLoading, setIsLoading] = useState(false);
  const [datatableData, setDatatableData] = useState<RowData[]>([]);

  useEffect(() => {
    const getDatatableData = async () => {
      setIsLoading(true);
      const { data } = await apiFetch<RowData[]>(
        `/api/reports/Audit-By-Client-Report-DrillDown/${clientId}`,
      );
      setDatatableData(data);
      setIsLoading(false);
    };

    getDatatableData();
  }, [clientId]);

  return (
    <Fragment>
      {isLoading ? (
        <div className="center-in-parent">
          <CircularProgress size="40" disableShrink />
        </div>
      ) : (
        <Datatable<RowData>
          exportFileName={'Audit Queue Drill Down Report'}
          title="Audit Queue By Client Drill Down Report"
          columns={columns}
          data={datatableData}
        />
      )}
    </Fragment>
  );
};

export default AuditQueueByClientDrillDownReport;
