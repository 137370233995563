/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  InvestorData,
  ReportType,
  RowData,
  Shipping,
} from '../../components/ReportsDashboard/types';
import { roleTypes } from '../../constants';
import { AuthContext } from '../../components/AuthContext';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import ShippingDrillDown from './ShippingDrillDown';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';
import Tooltip from '@mui/material/Tooltip';

export type DrillDownData = {
  id: number;
  reportType?: ReportType;
  name: string;
};

export type ShippingDrillDownRowData = {
  loanNumber: string;
  barcode: number;
  docType: string;
  format: string;
  dateUploaded: Date;
  client: string;
  investor: string;
  requiresOriginal: boolean;
};

const DetailedShippingReport = () => {
  const [shippingReport, setShippingReport] = useState<Shipping>({
    percentOfMonthlyGoal: 0,
    percentThatAreHardCopies: 0,
    readyForShippingGroupedByClients: [],
    readyForShippingGroupedByInvestors: [],
    readyToBeShipped: 0,
    digitalsNotBeingShipped: 0,
    readyToBeShippedAsOfToday: 0,
    digitalExclusionsAsOfToday: 0,
    topFiveInvestors: [],
    totalDocsShippedThisMonth: 0,
    totalDocsShippedToday: 0,
    totalYearlyToDate: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const { roles } = useContext(AuthContext);

  useEffect(() => {
    const getReportData = async () => {
      const { data } = await apiFetch<Shipping>('/api/reports/detailed-shipping-report');
      setShippingReport(data);
      setIsLoading(false);
    };

    getReportData();
  }, []);

  const columns = (reportType: ReportType) =>
    [
      {
        id: 'id',
        label: reportType == ReportType.Investor ? 'Investor Id' : 'Client Id',
        sortable: true,
        searchable: true,
        hidden: !roles.includes(roleTypes.Dev),
        render: ({ id }) => (
          <Tooltip title={`View ${reportType == ReportType.Investor ? 'Investor' : 'Client'}`}>
            <Link
              color="inherit"
              underline="always"
              target="_blank"
              href={`/${reportType === ReportType.Investor ? 'investors' : 'clients'}/${id}`}
              sx={{ cursor: 'pointer' }}
            >
              {id}
            </Link>
          </Tooltip>
        ),
      },
      {
        id: reportType == ReportType.Investor ? 'investor' : 'client',
        label: reportType == ReportType.Investor ? 'Investor' : 'Client',
        sortable: true,
        searchable: true,
        render: rowData => {
          // The next few lines were added because of a typescript error.
          // rowData.kind is never actually set other than for TS to know if it's a ClientData or InvestorData (RowData could be either one)
          let value = '';
          if (reportType === ReportType.Investor && rowData.kind !== 'client') {
            value = rowData.investor;
          } else if (reportType === ReportType.Client && rowData.kind !== 'investor') {
            value = rowData.client;
          }

          return (
            <Tooltip title={`View ${reportType == ReportType.Investor ? 'Investor' : 'Client'}`}>
              <Link
                color="inherit"
                underline="always"
                target="_blank"
                href={`/${reportType === ReportType.Investor ? 'investors' : 'clients'}/${
                  rowData.id
                }`}
                sx={{ cursor: 'pointer' }}
              >
                {value}
              </Link>
            </Tooltip>
          );
        },
      },
      {
        id: 'documentCountReadyToBeShipped',
        label: 'Document Count Ready To Be Shipped',
        sortable: true,
        render: rowData => (
          <Link
            component="button"
            underline="hover"
            variant="body2"
            onClick={() =>
              setDrillDownData({
                id: rowData.id,
                reportType,
                name: rowData.kind === 'client' ? rowData.client : rowData.investor,
              })
            }
          >
            {rowData.documentCountReadyToBeShipped}
          </Link>
        ),
      },
      {
        id: 'digitalsNotBeingShipped',
        label: 'Digitals Not Being Shipped',
        sortable: true,
      },
    ] as Column<RowData>[];

  if (isLoading) {
    return (
      <div className="center-in-parent">
        <CircularProgress size="75" disableShrink />
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ padding: '10px' }}>
        <Paper style={{ padding: '20px' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, 1fr)',
              gap: '16px',
              rowGap: '24px',
            }}
          >
            <div>
              <b>Total Docs Shipped Today</b>:{' '}
              {shippingReport.totalDocsShippedToday.toLocaleString()}
            </div>
            <div>
              <b>Percent That are hard copies</b>: {shippingReport.percentThatAreHardCopies}
            </div>
            <div>
              <b>Ready To Be Shipped</b>:{' '}
              <Link
                component="button"
                underline="hover"
                variant="body2"
                onClick={() => setDrillDownData({ id: 0, reportType: undefined, name: '' })}
              >
                {shippingReport.readyToBeShipped.toLocaleString()}
              </Link>
            </div>
            <div>
              <b>Digitals Not Being Shipped</b>:{' '}
              {shippingReport.digitalsNotBeingShipped.toLocaleString()}
            </div>
            <div>
              <b>Ready To Be Shipped As Of Today</b>:{' '}
              {shippingReport.readyToBeShippedAsOfToday.toLocaleString()}
            </div>
            <div>
              <b>Digital Exclusions As Of Today</b>:{' '}
              {shippingReport.digitalExclusionsAsOfToday.toLocaleString()}
            </div>
            <div>
              <b>Total Docs Shipped This Month</b>:{' '}
              {shippingReport.totalDocsShippedThisMonth.toLocaleString()}
            </div>
            <div>
              <b>Percent Of Monthly Goal</b>: {shippingReport.percentOfMonthlyGoal}
            </div>
            <div>
              <b>Total Yearly To Date</b>: {shippingReport.totalYearlyToDate.toLocaleString()}
            </div>
          </div>
        </Paper>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
            gap: '16px',
            marginTop: '16px',
          }}
        >
          <div>
            <Paper style={{ padding: '8px' }}>
              <Datatable<InvestorData>
                title="Top 5 Investors"
                columns={columns(ReportType.Investor)}
                searchBarPlaceholder="Search by investor id or name"
                data={shippingReport.topFiveInvestors}
                exportFileName="Top 5 Investors.csv"
                sortConfig={{ field: 'documentCountReadyToBeShipped', direction: 'desc' }}
              />
            </Paper>
          </div>
          <div>
            <Paper style={{ padding: '8px' }}>
              <Datatable<InvestorData>
                title="Ready To Be Shipped Grouped By Investor"
                columns={columns(ReportType.Investor)}
                searchBarPlaceholder="Search by investor id or name"
                data={shippingReport.readyForShippingGroupedByInvestors}
                exportFileName="Ready To Be Shipped Grouped By Investor.csv"
                sortConfig={{ field: 'documentCountReadyToBeShipped', direction: 'desc' }}
              />
            </Paper>
          </div>
          <div>
            <Paper style={{ padding: '8px' }}>
              <Datatable<RowData>
                title="Ready To Be Shipped Grouped By Client"
                columns={columns(ReportType.Client)}
                searchBarPlaceholder="Search by client id or name"
                data={shippingReport.readyForShippingGroupedByClients}
                exportFileName="Ready To Be Shipped Grouped By Client.csv"
                sortConfig={{ field: 'documentCountReadyToBeShipped', direction: 'desc' }}
              />
            </Paper>
          </div>
        </div>
      </div>

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <Fragment>
          <ShippingDrillDown drillDownData={drillDownData} />
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default DetailedShippingReport;
