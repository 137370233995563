/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DocumentsAgingForShipping as RowData } from './types';
import { Column, RowDataForExport } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

type Props = {
  documentsAgingForShipping: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'id',
    label: 'Barcode Number',
    sortable: true,
  },
  {
    id: 'client',
    label: 'Client',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'docType',
    label: 'Doc Type',
    sortable: true,
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'daysAging',
    label: 'Stall Days',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'DateFunded',
    sortable: true,
    render: ({ dateFunded }) => dateFunded && new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'dateUploaded',
    label: 'Barcode Created',
    sortable: true,
    render: ({ dateUploaded }) => dateUploaded && new Date(dateUploaded).toLocaleDateString(),
  },
  {
    id: 'dateStapled',
    label: 'Date Stapled',
    sortable: true,
    render: ({ dateStapled }) => dateStapled && new Date(dateStapled).toLocaleDateString(),
  },
  {
    id: 'dateMatched',
    label: 'Date Matched',
    sortable: true,
    render: ({ dateMatched }) => dateMatched && new Date(dateMatched).toLocaleDateString(),
  },
  {
    id: 'dateAudited',
    label: 'Date Audited',
    sortable: true,
    render: ({ dateAudited }) => dateAudited && new Date(dateAudited).toLocaleDateString(),
  },
  {
    id: 'dateSorted',
    label: 'Date Sorted',
    sortable: true,
    render: ({ dateSorted }) => dateSorted && new Date(dateSorted).toLocaleDateString(),
  },
  {
    id: 'investorSetAt',
    label: 'Investor Added To Loan',
    sortable: true,
    render: ({ investorSetAt }) => investorSetAt && new Date(investorSetAt).toLocaleDateString(),
  },
  {
    id: 'datePdfImageSaved',
    label: 'Date Scanned',
    sortable: true,
    render: ({ datePdfImageSaved }) =>
      datePdfImageSaved && new Date(datePdfImageSaved).toLocaleDateString(),
  },
  {
    id: 'dateCorrected',
    label: 'Date Corrected',
    sortable: true,
    render: ({ dateCorrected }) => dateCorrected && new Date(dateCorrected).toLocaleDateString(),
  },
  {
    id: 'format',
    label: 'Document Format',
    sortable: true,
  },
];

const customExport = (rows: RowData[]) =>
  rows.map(
    row =>
      ({
        'Barcode Number': row.id,
        Client: row.client,
        'Loan Number': row.loanNumber,
        'Document Type': row.docType,
        Investor: row.investor,
        'Days Stalled': row.daysAging,
        'Date Funded': row.dateFunded && new Date(row.dateFunded).toLocaleDateString(),
        'Date Uploaded': row.dateUploaded && new Date(row.dateUploaded).toLocaleDateString(),
        'Date Stapled': row.dateStapled && new Date(row.dateStapled).toLocaleDateString(),
        'Date Matched': row.dateAudited && new Date(row.dateMatched).toLocaleDateString(),
        'Date Audited': row.dateAudited && new Date(row.dateAudited).toLocaleDateString(),
        'Date Sorted': row.dateSorted && new Date(row.dateSorted).toLocaleDateString(),
        'Date Scanned':
          row.datePdfImageSaved && new Date(row.datePdfImageSaved).toLocaleDateString(),
        'Date Corrected': row.dateCorrected && new Date(row.dateCorrected).toLocaleDateString(),
        'Document Format': row.format,
      } as RowDataForExport),
  );

const DocumentsAgingForShipping = ({ documentsAgingForShipping }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Documents Aging for Shipping"
      columns={columns}
      data={documentsAgingForShipping}
      exportFileName="Documents Aging For Shipping.csv"
      customExport={customExport}
      dense
    />
  </div>
);
export default DocumentsAgingForShipping;
