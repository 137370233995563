import { OutreachDisposition } from '../../globalTypes/objects';
import { DatatableRow } from '../ui/DataTableV2/types';

export type SidebarOptions = 'disposition' | 'email' | 'other' | undefined;

export type ClientInformation = {
  id: number;
  client: string;
  clientInbox: string;
  poBox: string | null;
  address: string;
  city: string;
  state: string;
  zip: string;
};

type PropertyInformation = {
  borrower: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  county: string;
};

type ContactInformation = {
  phone: string;
  email: string;
};

export type Note = {
  loanId?: number;
  dateEntered: Date;
  username: string;
  text: string;
};

export type DatatableResponse = {
  report: RowData[];
  errorsInformation: ErrorInformation[];
};

export type RowData = DatatableRow & {
  id: number;
  appNumber: string;
  clientInfo: ClientInformation;
  propertyInfo: PropertyInformation;
  dateFunded: Date;
  daysAgedDeadline: number;
  missingDocuments: string;
  titleCompany: string;
  loanLevelContactInformation: ContactInformation;
  titleCompanyId: number;
  titleCompanyPhoneGlobal: string;
  titleCompanyEmailGlobal: string;
  globalEmailToken: string;
  titleCompanyAdditionalEmailGlobal: string | null;
  globalAdditionalEmailToken: string | null;
  titleNumber: string;
  requiresOriginalDoc: boolean;
  pastNotes: Note[];
  timeZone: string;
  isResolved: boolean;
  titlePortalContactEmails: string[];
  calculatedOutreachStage?: number;
  podNumber?: number;
  isDeferred: boolean;
  loanImportedAt: string;
  latestDisposition?: OutreachDisposition;
};

export type DropdownListItem = {
  id: number;
  label: string;
};

export type Client = DropdownListItem & {
  clientInbox: string;
  poBox: string | null;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export type ErrorInformation = {
  titleCompanyId: number;
  titleCompany: string;
  error: string;
};
export enum IdType {
  client,
  titleCompany,
}
