import { useMemo, useState } from 'react';
import {
  ActiveFilters,
  Column,
  DatatableResponse,
  DatatableRow,
  DateRangeValue,
  Filter,
  FilterGroups,
  PaginationData,
  Primitive,
  RowDataForExport,
  RowsPerPage,
  SearchFilter,
  Sort,
} from './types';
import { DataHookReturn } from './useData';
import useFilters, { activeValues } from './useFilters';
import { isNullOrUndefined } from './utils';
import { downloadCsv } from '../../../Utils';

type CellDataForExport = {
  label: string;
  value: Primitive;
};

const filterData = <Row extends DatatableRow>(rows: Row[], filterGroups: FilterGroups<Row>) => {
  let newRows = structuredClone(rows);
  filterGroups.radio.forEach(filter => {
    const activeFilterValues = activeValues(filter.options) as Array<string | number>;
    if (filter.filterData && activeFilterValues.length && activeFilterValues[0] !== null) {
      newRows = filter.filterData(newRows, activeFilterValues[0]);
    }
  });

  filterGroups.checkbox.forEach(filter => {
    const activeFilterValues = activeValues(filter.options) as string[];
    if (filter.filterData && activeFilterValues.length > 0) {
      newRows = filter.filterData(newRows, activeFilterValues);
    }
  });

  filterGroups.dateRange.forEach(filter => {
    const activeFilterValues = activeValues(filter.options) as Array<DateRangeValue>;
    if (filter.filterData && activeFilterValues.length && activeFilterValues[0] !== null) {
      newRows = filter.filterData(newRows, activeFilterValues[0]);
    }
  });

  filterGroups.dropdown.forEach(filter => {
    const activeFilterValues = activeValues(filter.options) as Array<string | number>;
    if (filter.filterData && activeFilterValues.length > 0) {
      newRows = filter.filterData(
        newRows,
        filter.multiple ? activeFilterValues : activeFilterValues[0],
      );
    }
  });

  filterGroups.search.forEach(filter => {
    const activeFilterValues = activeValues(filter.options) as Array<string>;
    if (activeFilterValues.length && activeFilterValues[0] !== null) {
      const searchText = activeFilterValues[0].toString().toLowerCase();
      newRows = newRows.filter(row => {
        const rowDataAsArray = Object.entries(row);
        return rowDataAsArray.find(([field, cellValue]) => {
          if (Array.isArray(cellValue)) {
            return false;
          }

          if (isNullOrUndefined(cellValue as Primitive)) {
            return false;
          }

          if (!filter.searchableColumns?.includes(field)) {
            return false;
          }

          return cellValue!.toString().toLowerCase().includes(searchText);
        });
      });
    }
  });

  return newRows;
};

const sortedFilteredData = <Row extends DatatableRow>(
  rows: Row[],
  filterGroups: FilterGroups<Row>,
  sort: Sort,
  columns: Column<Row>[],
) => {
  const filteredData = filterData(rows, filterGroups);

  return filteredData.toSorted((a, b) => {
    const sortColumn = columns.find(column => column.id === sort.field);
    if (sortColumn !== undefined && sortColumn.customSort) {
      const result = sortColumn.customSort(a, b);
      return sort.direction === 'asc' ? result : -result;
    }

    if (typeof a[sort.field] === 'object' || typeof b[sort.field] === 'object') {
      return 0;
    }

    if (!a || isNullOrUndefined(a[sort.field] as Primitive)) {
      return sort.direction === 'asc' ? -1 : 1;
    }

    if (!b || isNullOrUndefined(b[sort.field] as Primitive)) {
      return sort.direction === 'asc' ? 1 : -1;
    }

    if (!isNaN(Number(a[sort.field])) && !isNaN(Number(b[sort.field]))) {
      const sortFieldA = Number(a[sort.field]);
      const sortFieldB = Number(b[sort.field]);

      return sort.direction === 'asc' ? sortFieldA - sortFieldB : sortFieldB - sortFieldA;
    }

    const sortFieldA = a[sort.field]!.toString();
    const sortFieldB = b[sort.field]!.toString();
    return sort.direction === 'asc'
      ? sortFieldA.localeCompare(sortFieldB)
      : sortFieldB.localeCompare(sortFieldA);
  });
};

const dataToDisplay = <Row extends DatatableRow>(
  rows: Row[],
  filterGroups: FilterGroups<Row>,
  paginationData: PaginationData,
  sort: Sort,
  columns: Column<Row>[],
): DatatableResponse<Row> => {
  const start = paginationData.pageNumber * paginationData.rowsPerPage;

  const data = sortedFilteredData(rows, filterGroups, sort, columns);

  return {
    dataTableRows: data.slice(start, start + paginationData.rowsPerPage),
    count: data.length,
  } as DatatableResponse<Row>;
};

const mapColumnsForExport = <Row extends DatatableRow>(columns: Column<Row>[]) =>
  columns.reduce((final, column) => {
    final[column.id] = (row: Row) => {
      const rendered = column.render ? column.render(row) : null;
      // We can't add JSX to the export so we'll only user rendered value if it's a string
      if (typeof rendered === 'string') {
        return { label: column.label, value: rendered };
      }

      if (Array.isArray(row[column.id])) {
        const cellValue = row[column.id] as Primitive[];
        return { label: column.label, value: cellValue.join(', ') };
      }

      return { label: column.label, value: row[column.id] as Primitive };
    };

    return final;
  }, {} as { [columnId: string]: (row: Row) => CellDataForExport });

const prepareDataForExport = <Row extends DatatableRow>(rows: Row[], columns: Column<Row>[]) => {
  if (!rows.length) {
    return [];
  }

  const mappedColumns = mapColumnsForExport(columns);

  const rowFields = Object.keys(rows[0]);

  return rows.map(row => {
    return rowFields.reduce((final, field) => {
      // i.e. a column hidden from user
      if (!mappedColumns[field]) {
        return final;
      }

      const mappedValue = mappedColumns[field](row);

      final[mappedValue.label] = mappedValue.value;
      return final;
    }, {} as RowDataForExport);
  });
};

const useClientData = <Row extends DatatableRow>(
  columns: Column<Row>[],
  rows: Row[],
  filters: Filter<Row>[],
  rowsPerPage: RowsPerPage,
  sortConfig?: Sort,
  exportFileName?: string,
  customExport?: (rows: Row[]) => RowDataForExport[],
) => {
  const rowsWithUniqueId = useMemo(() => rows.map((row, i) => ({ ...row, rowId: i })), [rows]);

  const searchFilter = (): SearchFilter<Row>[] => {
    const searchableColumns = columns.filter(column => column.searchable);
    if (!searchableColumns.length) {
      return [];
    }

    return [
      {
        filterType: 'search',
        id: 'searchText',
        label: 'Search',
        options: [
          {
            label: '',
            value: null,
            active: false,
          },
        ],
        searchableColumns: searchableColumns.map(column => column.id),
      },
    ];
  };

  const [isExporting, setIsExporting] = useState(false);

  const [paginationData, setPaginationData] = useState<PaginationData>({
    pageNumber: 0,
    rowsPerPage,
  });
  const [sort, setSort] = useState<Sort>(sortConfig || { field: columns[0].id, direction: 'desc' });

  const {
    filterGroups,
    activeFilters,
    updateFilters,
    filterChips,
    clearFilter,
    clearFilters,
    initialized,
  } = useFilters([...filters, ...searchFilter()]);

  const onSortChange = (field: string) => {
    setSort(prev => {
      if (prev.field === field) {
        return { field, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
      }

      return { ...prev, field };
    });
  };

  const resetPagination = () => {
    setPaginationData(x => ({ ...x, pageNumber: 0 }));
  };

  const onPaginationChange = (field: keyof PaginationData, value: number) => {
    setPaginationData(x => ({ ...x, [field]: value }));
  };

  const onFiltersSubmit = (submittedFilters: ActiveFilters) => {
    updateFilters(submittedFilters);
    resetPagination();
  };

  const exportData = () => {
    setIsExporting(true);

    const data = sortedFilteredData(rows, filterGroups, sort, columns);

    let dataForExport: RowDataForExport[];
    if (customExport) {
      dataForExport = customExport(data);
    } else {
      dataForExport = prepareDataForExport(data, columns);
    }

    downloadCsv(dataForExport, exportFileName || 'export.csv', 'text/csv');

    setIsExporting(false);
  };

  return {
    data: dataToDisplay(rowsWithUniqueId, filterGroups, paginationData, sort, columns),
    isLoading: false,
    error: null,
    filterGroups,
    activeFilters,
    filterChips,
    clearFilter,
    clearFilters,
    sort,
    onSortChange,
    paginationData,
    onPaginationChange,
    onFiltersSubmit,
    exportData,
    isExporting,
    filtersInitialized: initialized,
  } as DataHookReturn<Row>;
};

export default useClientData;
