/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { ClientContact } from '../ClientContacts';
import Panel, { PanelHeader } from '../../ui/Panel';
import SearchSelect from '../../ui/SearchSelect';
import TextInput from '../../ui/Inputs/TextInput';
import PhoneInput from '../../ui/Inputs/PhoneInput';
import Button from '../../ui/Button';
import { useClients } from '../../../Hooks/useClients';
import { apiFetch, apiPost } from '../../../adalConfig';
import Dropdown from '../../ui/Dropdown';
import { contactTypes } from './NewClientContact';

const EditClientContact = () => {
  const [selectedClient, setSelectedClient] = useState<number | null>(null);
  const [selectedContact, setSelectedContact] = useState<ClientContact | null>(null);
  const [contactList, setContactList] = useState<ClientContact[] | null>(null);
  const [isError, setIsError] = useState(false);

  const clients = useClients();

  const onEditSubmit = async (contact: ClientContact) => {
    const { firstName, lastName, email } = contact;
    if (!email || !firstName || !lastName) {
      setIsError(true);
      return;
    }

    await apiPost('/api/clients/updateClientContact', contact);

    setSelectedContact(null);
  };

  const onClientSelect = async (clientId: number) => {
    setSelectedClient(clientId);
    setSelectedContact(null);
    await getClientContacts(clientId);
  };

  const getClientContacts = async (clientId: number) => {
    const { data } = await apiFetch<ClientContact[]>(
      `/api/clients/getClientContacts?clientId=${clientId}`,
      {},
    );

    setContactList(data);
  };

  return (
    <Panel>
      <PanelHeader text="Edit Client Contact" />
      <div css={{ maxWidth: 320, marginTop: 24 }}>
        <SearchSelect
          options={clients.map(c => ({
            label: c.company,
            value: c.id,
          }))}
          placeholder="Search clients *"
          {...(selectedClient &&
            clients.length !== 0 && {
              defaultValue: {
                label: clients.find(c => c.id === selectedClient)?.company,
                value: selectedClient,
              },
            })}
          onChange={selection => selection && onClientSelect(selection.value)}
        />
      </div>
      {contactList && (
        <Fragment>
          <div css={{ maxWidth: 320, marginTop: 24 }}>
            <Dropdown
              options={contactList!.map((c: ClientContact) => ({
                label: `${c.firstName} ${c.lastName}`,
                value: c,
              }))}
              placeholder="Select contact"
              onChange={(selection: SelectOption<ClientContact>) =>
                setSelectedContact(selection.value)
              }
            />
          </div>
          {selectedContact && (
            <Fragment>
              <TextInput
                type="text"
                label="Contact type"
                value={contactTypes.find(c => c.value === selectedContact.type)?.label}
                labelOverrides={{ marginTop: 16, width: 320 }}
                disabled
              />
              <TextInput
                type="text"
                label="Title"
                placeholder="Title"
                value={selectedContact!.title}
                labelOverrides={{ marginTop: 16, width: 320 }}
                onChange={e =>
                  setSelectedContact((x: ClientContact) => ({
                    ...x,
                    title: e.target.value,
                  }))
                }
              />
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 16,
                }}
              >
                <TextInput
                  label="First Name"
                  type="text"
                  placeholder="First Name *"
                  value={selectedContact.firstName}
                  onChange={e =>
                    setSelectedContact((x: ClientContact) => ({
                      ...x,
                      firstName: e.target.value,
                    }))
                  }
                />
                <TextInput
                  label="Last Name"
                  type="text"
                  placeholder="Last Name *"
                  value={selectedContact.lastName}
                  onChange={e =>
                    setSelectedContact((x: ClientContact) => ({
                      ...x,
                      lastName: e.target.value,
                    }))
                  }
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 16,
                }}
              >
                <PhoneInput
                  label="Phone"
                  placeholder="Phone"
                  value={selectedContact.phone}
                  onChange={value =>
                    setSelectedContact((x: ClientContact) => ({
                      ...x,
                      phone: value,
                    }))
                  }
                />
                <PhoneInput
                  label="Fax"
                  placeholder="Fax"
                  value={selectedContact.fax}
                  onChange={value =>
                    setSelectedContact((x: ClientContact) => ({
                      ...x,
                      fax: value,
                    }))
                  }
                />
              </div>
              <TextInput
                label="Email"
                type="text"
                placeholder="Email *"
                value={selectedContact.email}
                labelOverrides={{ margin: '16px 0 24px', maxWidth: 320 }}
                onChange={e =>
                  setSelectedContact((x: ClientContact) => ({
                    ...x,
                    email: (e.target.value || '').trim(),
                  }))
                }
              />
              <Button onClick={() => onEditSubmit(selectedContact)}>Submit</Button>
              {isError && (
                <div style={{ margin: '4px', color: 'red' }}>
                  Please fill out all required fields
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Panel>
  );
};

export default EditClientContact;
