/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { DrillDownData, ShippingDrillDownRowData } from './DetailedShippingReport';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';
import { ReportType } from '../../components/ReportsDashboard/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';

type Props = {
  drillDownData: DrillDownData | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const ShippingDrillDown = ({ drillDownData }: Props) => {
  const [reportData, setReportData] = useState<ShippingDrillDownRowData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getDrillDownData = async () => {
      setIsLoading(true);
      const { data } = await apiFetch<ShippingDrillDownRowData[]>(
        `/api/reports/shipping-drill-down-report?id=${drillDownData?.id}&idType=${
          drillDownData?.reportType === ReportType.Investor ? 2 : 0
        }`,
      );
      setReportData(data);
      setIsLoading(false);
    };

    getDrillDownData();
  }, []);

  const classes = useStyles();

  const shippingDrillDownColumns: Column<ShippingDrillDownRowData>[] = [
    {
      id: 'loanNumber',
      label: 'Loan Number',
      sortable: true,
      searchable: true,
    },
    {
      id: 'barcode',
      label: 'Barcode',
      sortable: true,
      searchable: true,
      render: ({ barcode }) => (
        <Tooltip title="View document">
          <Link
            color="inherit"
            underline="always"
            target="_blank"
            href={`/documents/${barcode}`}
            sx={{ cursor: 'pointer' }}
          >
            {barcode}
          </Link>
        </Tooltip>
      ),
    },
    {
      id: 'docType',
      label: 'Document Type',
      sortable: true,
      searchable: true,
    },
    {
      id: 'format',
      label: 'Format',
      sortable: true,
      searchable: true,
    },
    {
      id: 'requiresOriginal',
      label: 'Original only',
      sortable: true,
      searchable: true,
      render: ({ requiresOriginal }) => (requiresOriginal ? 'Yes' : 'No'),
    },
    {
      id: 'dateUploaded',
      label: 'Available For Shipping Since',
      sortable: true,
      searchable: true,
      render: ({ dateUploaded }) => new Date(dateUploaded).toLocaleDateString(),
    },
    {
      id: 'client',
      label: 'Client',
      sortable: true,
      searchable: true,
      hidden: drillDownData?.id !== 0 && drillDownData?.reportType === ReportType.Investor,
    },
    {
      id: 'investor',
      label: 'Investor',
      sortable: true,
      searchable: true,
      hidden: drillDownData?.id !== 0 && drillDownData?.reportType === ReportType.Client,
    },
  ];

  if (isLoading) {
    return (
      <div className="center-in-parent">
        <CircularProgress disableShrink size={60} />
      </div>
    );
  }

  return (
    <div className={classes.paper}>
      <Datatable<ShippingDrillDownRowData>
        title={
          <div>
            <p>
              {drillDownData?.reportType === ReportType.Investor ? 'Investor' : 'Client'} -
              Drilldown Report
            </p>
            <p>
              <span>
                <b>{drillDownData?.name}</b>
              </span>
            </p>
          </div>
        }
        searchBarPlaceholder="Search by any field"
        columns={shippingDrillDownColumns}
        data={reportData}
        exportFileName="Shipping drilldown report.csv"
        sortConfig={{ field: 'dateUploaded', direction: 'asc' }}
      />
    </div>
  );
};

export default ShippingDrillDown;
