import React, { CSSProperties, useMemo } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import ListItem from './ListItem';
import { Column, DatatableResponse, DatatableRow, PaginationData, Sort } from './types';
import ColumnHeader from './ColumnHeader';
import TableCell from '@mui/material/TableCell';
import { AxiosError } from 'axios';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import { parseRequestError } from '../../../Utils';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import { SelectionAction } from './Datatable';

type Props<Row extends DatatableRow> = {
  datatableData: DatatableResponse<Row> | undefined;
  columns: Column<Row>[];
  isLoading: boolean;
  error?: Error | null;
  sort: Sort;
  onSortChange: (field: string) => void;
  paginationData: PaginationData;
  onPaginationChange: (field: keyof PaginationData, value: number) => void;
  detailsPanel?: (rowData: Row) => JSX.Element;
  dense: boolean;
  rowSelectionActions?: SelectionAction[];
  selectedRows: Set<number>;
  onCheckAllClick: (checked: boolean) => void;
  onCheckboxClick: (rowId: number) => void;
  rowStyle?: (row: Row) => CSSProperties;
};

const List = <Row extends DatatableRow>({
  datatableData,
  columns,
  isLoading,
  error,
  sort,
  onSortChange,
  paginationData,
  onPaginationChange,
  detailsPanel,
  dense,
  rowSelectionActions,
  selectedRows,
  onCheckAllClick,
  onCheckboxClick,
  rowStyle,
}: Props<Row>) => {
  if (error) {
    const errorMessage = parseRequestError(error as AxiosError);

    return (
      <div className="center-child">
        <Alert severity="error" variant="outlined">
          {errorMessage}
        </Alert>
      </div>
    );
  }

  const availableRowCount = datatableData?.dataTableRows?.length || 0;
  const totalSelected = selectedRows.size;
  const totalRowCount = datatableData?.count || 0;
  const columnsToDisplay = useMemo(() => columns.filter(column => !column.hidden), [columns]);

  return (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Table stickyHeader size={dense ? 'small' : 'medium'}>
          <TableHead>
            <TableRow>
              {rowSelectionActions !== undefined && (
                <TableCell padding="checkbox" sx={{ bgcolor: grey[200], color: '#004455' }}>
                  <Checkbox
                    color="primary"
                    indeterminate={totalSelected > 0 && totalSelected < availableRowCount}
                    checked={availableRowCount > 0 && totalSelected === availableRowCount}
                    onChange={(event, checked) => onCheckAllClick(checked)}
                  />
                </TableCell>
              )}
              {columnsToDisplay.map(column => (
                <ColumnHeader<Row>
                  key={column.label}
                  column={column}
                  sort={sort}
                  onSortChange={onSortChange}
                />
              ))}
              {!!detailsPanel && <ColumnHeader column={{} as Column<DatatableRow>}></ColumnHeader>}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {columnsToDisplay.map((column, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {columnsToDisplay.map((column, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>

                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {columnsToDisplay.map((column, index) => {
                    return (
                      <TableCell key={index}>
                        <Skeleton />
                      </TableCell>
                    );
                  })}
                </TableRow>
              </>
            ) : (
              datatableData?.dataTableRows?.map((rowData, i) => (
                <ListItem
                  key={rowData.rowId as number}
                  rowData={rowData}
                  columns={columnsToDisplay}
                  detailsPanel={detailsPanel}
                  isRowSelected={selectedRows.has(rowData.rowId as number)}
                  onRowSelected={rowSelectionActions}
                  onCheckboxClick={onCheckboxClick}
                  rowStyle={rowStyle}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <TablePagination
          rowsPerPageOptions={[25, 50, 100, 250, 500]}
          component="div"
          count={totalRowCount}
          rowsPerPage={paginationData.rowsPerPage}
          page={paginationData.pageNumber}
          onPageChange={(event, page) => onPaginationChange('pageNumber', page)}
          onRowsPerPageChange={event => onPaginationChange('rowsPerPage', +event.target.value)}
        />
      )}
    </>
  );
};

export default List;
