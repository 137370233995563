/** @jsx jsx */
import { jsx } from '@emotion/core';
import { CorrectionsWithPotentialCure as RowData } from './types';
import { Link } from '@mui/material';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'client',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'documentId',
    label: 'Document Id',
    sortable: true,
    render: ({ documentId }) => (
      <Link target="_blank" href={`/documents/${documentId}`}>
        {documentId}
      </Link>
    ),
  },
  {
    id: 'potentialCureDocumentId',
    label: 'Potential Cure Document Id',
    sortable: true,
    render: ({ potentialCureDocumentId }) => (
      <Link target="_blank" href={`/documents/${potentialCureDocumentId}`}>
        {potentialCureDocumentId}
      </Link>
    ),
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => (dateFunded ? new Date(dateFunded).toLocaleDateString() : ''),
  },
  {
    id: 'borrower',
    label: 'Borrower',
    sortable: true,
  },
  {
    id: 'dateFailed',
    label: 'Date Failed',
    sortable: true,
    render: ({ dateFailed }) => (dateFailed ? new Date(dateFailed).toLocaleDateString() : ''),
  },
];

const PotentialCureDocuments = ({ reportData }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Potential Cure Documents"
      columns={columns}
      data={reportData}
      exportFileName="Potential Cure Documents.csv"
      dense
    />
  </div>
);

export default PotentialCureDocuments;
