/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Column } from '../ui/DataTableV2/types';
import Datatable from '../ui/DataTableV2/Datatable';

export type RowData = {
  loanNumber: string;
  clientId: number;
  borrowerFullName: string;
  propertyAddress: string;
  propertyCity: string;
  propertyState: string;
  propertyZip: string;
  propertyType: string;
  propertyCounty: string;
  investorName: string;
  servicer: string;
  subServicer: string;
  custodian: string;
  trailingDocsAddress: string;
  investorCommitmentNumber: string;
  loanAmount: number;
  paymentStream: string;
  dateDocumentsDrawn: string;
  purchaseAdviceDate: string;
  titleCompanyName: string;
  titleOrderNumber: string;
  titleCompanyAddress: string;
  titleCompanyCity: string;
  titleCompanyState: string;
  titleCompanyZip: string;
  titleCompanyPhone: string;
  titleCompanyFax: string;
  titleCompanyEmail: string;
  additionalInformation: string;
  documentUploadLocation: string;
  poa: number;
  cema: number;
  mortgage: number;
  policy: number;
  secondMortgage: number;
  assignment: number;
  mortgageType: string;
  isGinnieMaeLoanType: boolean;
  poolNumber: string;
  lienPosition: number;
};

type Props = {
  datatableData: RowData[];
  exportFileName?: string;
};

const columns: Column<RowData>[] = [
  {
    id: 'loanNumber',
    label: 'Loan Number',
    searchable: true,
  },
  {
    id: 'clientId',
    label: 'Client Id',
    searchable: true,
  },
  {
    id: 'borrowerFullName',
    label: 'Borrower Full Name',
    searchable: true,
  },
  {
    id: 'propertyAddress',
    label: 'Property Address',
    searchable: true,
  },
  {
    id: 'propertyCity',
    label: 'Property City',
    searchable: true,
  },
  {
    id: 'propertyState',
    label: 'Property State',
    searchable: true,
  },
  {
    id: 'propertyZip',
    label: 'Property Zip',
    searchable: true,
  },
  {
    id: 'propertyType',
    label: 'Property Type',
    searchable: true,
  },
  {
    id: 'propertyCounty',
    label: 'Property County',
    searchable: true,
  },
  {
    id: 'investorName',
    label: 'Investor Name',
    searchable: true,
  },
  {
    id: 'servicer',
    label: 'Servicer',
    searchable: true,
  },
  {
    id: 'subServicer',
    label: 'SubServicer',
    searchable: true,
  },
  {
    id: 'custodian',
    label: 'Custodian',
    searchable: true,
  },
  {
    id: 'trailingDocsAddress',
    label: 'Trailing Docs Address',
    searchable: true,
  },
  {
    id: 'investorCommitmentNumber',
    label: 'Investor Commitment Number',
    searchable: true,
  },
  {
    id: 'loanAmount',
    label: 'Loan Amount',
    searchable: true,
  },
  {
    id: 'paymentStream',
    label: 'Payment Stream',
    searchable: true,
  },
  {
    id: 'dateDocumentsDrawn',
    label: 'Date Documents Drawn',
    searchable: true,
  },
  {
    id: 'purchaseAdviceDate',
    label: 'Purchase Date',
    searchable: true,
  },
  {
    id: 'titleCompanyName',
    label: 'Title Company Name',
    searchable: true,
  },
  {
    id: 'titleOrderNumber',
    label: 'Title Order Number',
    searchable: true,
  },
  {
    id: 'titleCompanyAddress',
    label: 'Title Company Address',
    searchable: true,
  },
  {
    id: 'titleCompanyCity',
    label: 'Title Company City',
    searchable: true,
  },
  {
    id: 'titleCompanyState',
    label: 'Title Company State',
    searchable: true,
  },
  {
    id: 'titleCompanyZip',
    label: 'Title Company Zip',
    searchable: true,
  },
  {
    id: 'titleCompanyPhone',
    label: 'Title Company Phone',
    searchable: true,
  },
  {
    id: 'titleCompanyFax',
    label: 'Title Company Fax',
    searchable: true,
  },
  {
    id: 'titleCompanyEmail',
    label: 'Title Company Email',
    searchable: true,
  },
  {
    id: 'additionalInformation',
    label: 'Additional Information',
    searchable: true,
  },
  {
    id: 'documentUploadLocation',
    label: 'Document Upload Location',
    searchable: true,
  },
  {
    id: 'poa',
    label: 'Poa',
    searchable: true,
  },
  {
    id: 'cema',
    label: 'Cema',
    searchable: true,
  },
  {
    id: 'mortgage',
    label: 'Mortgage',
    searchable: true,
  },
  {
    id: 'policy',
    label: 'Policy',
    searchable: true,
  },
  {
    id: 'secondMortgage',
    label: 'Second Mortgage',
    searchable: true,
  },
  {
    id: 'assignment',
    label: 'Assignment',
    searchable: true,
  },
  {
    id: 'mortgageType',
    label: 'Loan Type',
    searchable: true,
    render: (rowData: RowData) => {
      if (rowData.mortgageType) {
        return rowData.mortgageType;
      }

      return rowData.isGinnieMaeLoanType ? 'FHA/VA/USDA' : '';
    },
  },
  {
    id: 'poolNumber',
    label: 'Pool Number',
    searchable: true,
  },
  {
    id: 'lienPosition',
    label: 'Lien Position',
    searchable: true,
  },
  {
    id: 'channel',
    label: 'Loan Channel',
    searchable: true,
  },
  {
    id: 'secondInvestor',
    label: 'Second Investor',
    searchable: true,
  },
];

const MappedLoanImportDataTable = ({ datatableData, exportFileName }: Props) => (
  <div className="m4">
    <Datatable<RowData>
      title="Loan imports"
      columns={columns}
      data={datatableData}
      searchBarPlaceholder="Search by any field"
      exportFileName={`${exportFileName || 'Loan Import Data'}.csv`}
    />
  </div>
);

export default MappedLoanImportDataTable;
