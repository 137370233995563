/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Link from '@mui/material/Link';
import { DocumentEventDrillDownRowData, DrillDownData, RowData } from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  coversheetCreatedEventsByUser: RowData[];
};

const docEventDrillDownColumns: Column<DocumentEventDrillDownRowData>[] = [
  {
    id: 'barcode',
    label: 'Barcode',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
    render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: ({ date }) => new Date(date).toLocaleDateString(),
  },
];

const Coversheet = ({ coversheetCreatedEventsByUser }: Props) => {
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'count',
      label: 'Count',
      sortable: true,
      render: ({ count, date, user }) => (
        <Link
          component="button"
          variant="body2"
          underline="hover"
          onClick={() =>
            setDrillDownData({
              date: new Date(date),
              user: user,
            })
          }
        >
          {count}
        </Link>
      ),
    },
    {
      id: 'user',
      label: 'User',
      sortable: true,
      render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    },
    {
      id: 'date',
      label: 'Date',
      sortable: true,
      render: ({ date }) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title="Coversheet Created - By User"
          columns={columns}
          data={coversheetCreatedEventsByUser}
          exportFileName="Coversheet Created - By User.csv"
          dense
        />
      </div>

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <div className={classes.paper}>
          <Datatable<DocumentEventDrillDownRowData>
            title="Coversheet Created Drill Down Report"
            columns={docEventDrillDownColumns}
            url={`/api/reports/document-event-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              documentEvent: 'CoversheetCreated',
            })}`}
            exportUrl={`/api/reports/document-event-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              documentEvent: 'CoversheetCreated',
            })}`}
            exportFileName="Coversheet Created Drill Down Report.xlsx"
            dense
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Coversheet;
