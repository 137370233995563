/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import {
  RowData as BasicRowData,
  ShippingDrillDownData,
  ShippingUserDrillDownRowData,
} from './types';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import queryString from 'query-string';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Link from '@mui/material/Link';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsShippedByUser: RowData[];
};

export type RowData = BasicRowData & { hardCopy: boolean };

const docEventDrillDownColumns: Column<ShippingUserDrillDownRowData>[] = [
  {
    id: 'barcode',
    label: 'Barcode',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'user',
    label: 'User',
    sortable: true,
    render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
  },
  {
    id: 'date',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => new Date(dateFunded).toLocaleDateString(),
  },
];

export const ShippingByUser = ({ documentsShippedByUser }: Props) => {
  const [drillDownData, setDrillDownData] = useState<ShippingDrillDownData | null>(null);
  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'count',
      label: 'Count',
      sortable: true,
      render: ({ count, date, hardCopy, user }) => (
        <Link
          component="button"
          variant="body2"
          underline="hover"
          onClick={() =>
            setDrillDownData({
              date: new Date(date),
              user: user,
              hardCopy: hardCopy,
            })
          }
        >
          {count}
        </Link>
      ),
    },
    {
      id: 'hardCopy',
      label: 'Hard Copy',
      sortable: true,
      render: ({ hardCopy }) => <Checkbox color="primary" checked={hardCopy} />,
    },
    {
      id: 'user',
      label: 'User',
      sortable: true,
      render: ({ user }) => user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    },
    {
      id: 'date',
      label: 'Date',
      sortable: true,
      render: ({ date }) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title="Documents Shipped - By User"
          columns={columns}
          data={documentsShippedByUser}
          exportFileName="Documents Shipped - By User.csv"
          dense
        />
      </div>

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <div className={classes.paper}>
          <Datatable<ShippingUserDrillDownRowData>
            title="Shipped By User Drill Down Report"
            columns={docEventDrillDownColumns}
            url={`/api/reports/document-shipped-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              hardCopy: drillDownData?.hardCopy,
            })}`}
            exportUrl={`/api/reports/document-shipped-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              hardCopy: drillDownData?.hardCopy,
            })}`}
            exportFileName="Shipped By User Drill Down Report.xlsx"
            dense
          />
        </div>
      </Modal>
    </Fragment>
  );
};
