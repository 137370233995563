/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactNode, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TitleCompanyInfo from './TitleCompanyInfo';
import LoansList from './LoansList';
import VirtualListComponent from '../../ui/DataTableV2/VirtualListComponent';
import Header from '../../ui/Header';
import { DropdownListItem } from '../types';
import { apiFetch } from '../../../adalConfig';
import useOnDemandOutstandingLoans from './useOnDemandOutstandingLoans';
import { TableRef } from '../../ui/DataTableV2/Datatable';

const getTitleCompanyDropdownData = async () => {
  const { data } = await apiFetch<DropdownListItem[]>(
    '/api/titleCompanies/title-portal-dropdown-data',
  );
  return data;
};

const OnDemandMode = () => {
  const [selectedTitleCompany, setSelectedTitleCompany] = useState<DropdownListItem | null>(null);

  const { datatableData, setDatatableData, isLoading, loansRemaining, localTime } =
    useOnDemandOutstandingLoans(selectedTitleCompany?.id);

  const tableRef = useRef({} as TableRef);
  const { data: dropdownData } = useQuery(
    ['title-company-dropdown', ''],
    getTitleCompanyDropdownData,
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!tableRef.current?.filtersInitialized) {
      return;
    }

    const { clearFilters } = tableRef.current;
    clearFilters && clearFilters();
  }, [datatableData.map(x => x.id).join(','), tableRef.current?.filtersInitialized]);

  if (isLoading) {
    return (
      <div className="center-in-parent">
        <CircularProgress disableShrink size={35} />
      </div>
    );
  }

  if (!selectedTitleCompany || (!datatableData.length && !isLoading)) {
    return (
      <div className="full-width">
        <Header headerText="Title portal outreach on demand" />
        <div className="m4">
          <Autocomplete
            options={dropdownData || []}
            renderOption={(props, option, state) => [props, option, state.index] as ReactNode}
            onChange={(e, value) => value && setSelectedTitleCompany(value)}
            value={dropdownData?.find(x => x.id === selectedTitleCompany?.id) || null}
            isOptionEqualToValue={(x, y) => x?.id === y?.id}
            autoHighlight
            ListboxComponent={VirtualListComponent}
            renderInput={params => (
              <TextField
                style={{ width: '400px' }}
                placeholder="Select title company"
                {...params}
              />
            )}
          />

          {!!selectedTitleCompany && !datatableData.length && (
            <div className="mt-4 center-in-parent">No outstanding loans</div>
          )}
        </div>
      </div>
    );
  }

  if (!isLoading && datatableData.length === 0) {
    return <div className="center-in-parent">No outstanding loans</div>;
  }

  return (
    <div className="m2 df col" style={{ gap: '16px', height: '100%' }}>
      {datatableData.length > 0 && (
        <TitleCompanyInfo
          firstRow={datatableData[0]}
          loansRemaining={loansRemaining}
          localTime={localTime}
          mode="on-demand"
        >
          <div className="full-width">
            <Autocomplete
              options={dropdownData || []}
              renderOption={(props, option, state) => [props, option, state.index] as ReactNode}
              onChange={(e, value) => value && setSelectedTitleCompany(value)}
              value={dropdownData?.find(x => x.id === selectedTitleCompany?.id) || null}
              isOptionEqualToValue={(x, y) => x?.id === y?.id}
              autoHighlight
              ListboxComponent={VirtualListComponent}
              renderInput={params => <TextField placeholder="Select title company" {...params} />}
              size="small"
            />
          </div>
        </TitleCompanyInfo>
      )}

      {isLoading ? (
        <div className="center-in-parent">
          <CircularProgress size="35" disableShrink />
        </div>
      ) : (
        <LoansList rows={datatableData} setDatatableData={setDatatableData} ref={tableRef} />
      )}
    </div>
  );
};

export default OnDemandMode;
