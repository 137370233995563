/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { Client, FlaggedDoc, FlagInformation } from '../../globalTypes/objects';
import { apiPost } from '../../adalConfig';
import { useClients } from '../../Hooks/useClients';
import { getDocTypes } from '../../Utils';
import Dropdown from '../../components/ui/Dropdown';
import { FlaggedDocumentWithDetail } from './FlaggedDocsList';
import { useToaster } from '../../Hooks/toasters';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(_theme => ({
  modalPaper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    backgroundColor: _theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: _theme.shadows[5],
    padding: _theme.spacing(2, 4, 3),
    zIndex: 1000,
  },
}));

type DocType = {
  label: string;
  value: number;
};
type NewFlaggedDoc = {
  id: number;
  clientId: number;
  documentType: number;
  loanNumber: string;
  reason: string;
  address: string;
  borrower: string;
  isUrgent: boolean;
  pendingLabel: boolean;
  pendingLocating: boolean;
};

type Props = {
  flaggedDocument: FlaggedDocumentWithDetail;
  setIsEditFlagOpen: Dispatch<SetStateAction<boolean>>;
};
export default function EditFlaggedDocument({ flaggedDocument, setIsEditFlagOpen }: Props) {
  const clients = useClients();
  useEffect(() => {
    getDocTypes().then(setDocTypes);
  }, []);

  const [address, setAddress] = useState<string>(flaggedDocument.address);
  const [borrower, setBorrower] = useState<string>(flaggedDocument.borrower);
  const [reason, setReason] = useState<string>(flaggedDocument.reason);
  const [loanNumber, setLoanNumber] = useState<string>(flaggedDocument.loanNumber);
  const [urgent, setUrgent] = useState<boolean>(flaggedDocument.isUrgent);
  const [pendingLabel, setPendingLabel] = useState<boolean>(flaggedDocument.pendingLabel);
  const [pendingLocating, setPendingLocating] = useState<boolean>(flaggedDocument.pendingLocating);

  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [selectedDocType, setSelectedDocType] = useState<number>(
    flaggedDocument.documentType ?? -1,
  );
  const [clientId, setClientId] = useState<number>(flaggedDocument.clientId);
  const [newFlaggedDocument, setNewFlaggedDocument] = useState<NewFlaggedDoc | null>(null);
  const [isValidForSubmit, setIsValidForSubmit] = useState<boolean>(false);

  useEffect(() => {
    const docType = selectedDocType;
    if (clientId && docType && loanNumber && reason && address && borrower) {
      setIsValidForSubmit(true);
    } else {
      setIsValidForSubmit(false);
    }
    const newFlaggedDoc: NewFlaggedDoc = {
      id: flaggedDocument.id,
      clientId,
      documentType: docType,
      loanNumber,
      reason,
      address,
      borrower,
      isUrgent: urgent,
      pendingLabel: pendingLabel,
      pendingLocating: pendingLocating,
    };
    setNewFlaggedDocument(newFlaggedDoc);
  }, [
    clientId,
    flaggedDocument,
    loanNumber,
    reason,
    address,
    selectedDocType,
    borrower,
    urgent,
    pendingLabel,
    pendingLocating,
  ]);

  const [status, setStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { errorToaster } = useToaster();

  const setModalClose = async () => {
    setIsEditFlagOpen(false);
  };

  const flagDoc = async () => {
    let data;
    if (newFlaggedDocument) {
      setStatus(StatusTypes.loading);
      data = await apiPost<FlaggedDoc>(`/api/documents/editflagdocument`, newFlaggedDocument);
    }
    return data;
  };
  const flagDocument = async () => {
    flagDoc()
      .then(({ data: newFd }) => {
        setStatus(StatusTypes.success);
      })
      .catch(e => {
        if (!e.response.data?.errors) {
          errorToaster(e.response.data.split('\n')[0]);
          return;
        }

        errorToaster(
          <Fragment>
            {e.response.data.errors.map((error, i) => (
              <p key={i}>{error.message}</p>
            ))}
          </Fragment>,
        );

        setStatus(StatusTypes.error);
      })
      .finally(() => setModalClose());
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.modalPaper}>
        <div css={{ margin: 40, maxWidth: 1300 }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Panel>
              <PanelHeader text="Edit Document Flag" />
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  height: '28rem',
                }}
              >
                <ClientSearch onChange={c => setClientId(c.id)} selectedClient={clientId} />
                <div className="df jcsb aife">
                  <div css={{ width: 200 }}>
                    <Dropdown
                      options={docTypes}
                      placeholder="Document Type"
                      value={docTypes.find(d => d.value === selectedDocType)}
                      onChange={docType => setSelectedDocType(docType.value)}
                    />
                  </div>
                  <div css={{ width: 330 }}>
                    <TextInput
                      placeholder="Loan Number"
                      name="loanNumber"
                      value={loanNumber}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        setLoanNumber(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="df jcsb aife">
                  <div css={{ width: 280 }}>
                    <TextInput
                      placeholder="Address"
                      name="address"
                      value={address}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        setAddress(e.target.value)
                      }
                    />
                  </div>
                  <div css={{ width: 250 }}>
                    <TextInput
                      placeholder="Borrower"
                      name="borrower"
                      value={borrower}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        setBorrower(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="df jcsb aife" css={{ alignItems: 'center' }}>
                  <div css={{ width: '100%' }}>
                    <TextInput
                      placeholder="Reason"
                      name="reason"
                      value={reason}
                      onChange={(e: React.ChangeEvent<{ value: string }>) =>
                        setReason(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="df jcsb aife" css={{ alignItems: 'center' }}>
                  <div
                    css={{
                      width: 210,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      fontSize: '14px',
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={urgent}
                        onChange={e => setUrgent(e.target.checked)}
                        style={{ marginRight: '8px' }}
                      />
                      Mark as Urgent
                    </label>
                  </div>
                  <div
                    css={{
                      width: 210,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      fontSize: '14px',
                    }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={pendingLabel}
                        onChange={e => setPendingLabel(e.target.checked)}
                        style={{ marginRight: '8px' }}
                      />
                      Pending Label
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        checked={pendingLocating}
                        onChange={e => setPendingLocating(e.target.checked)}
                        style={{ marginRight: '8px' }}
                      />
                      Pending Locating
                    </label>
                  </div>
                </div>

                <div css={{ display: 'flex' }}>
                  <div css={{ paddingRight: 10 }}>
                    <StatusButton onClick={setModalClose} text="Cancel" />
                  </div>
                  <StatusButton
                    status={status}
                    onClick={flagDocument}
                    text="Save"
                    disabled={!isValidForSubmit}
                  />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
