import React, { createContext } from 'react';
import { OutreachDisposition } from '../../globalTypes/objects';
import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../adalConfig';

const DispositionsContext = createContext<OutreachDisposition[]>([]);

const getSortedDispositions = async () => {
  const { data } = await apiFetch<OutreachDisposition[]>('/api/call-tasks/outreach-dispositions');
  return data.sort((a, b) => (a.stage + a.reason < b.stage + b.reason ? -1 : 1));
};

export const DispositionsProvider = ({ children }) => {
  const { data: dispositions } = useQuery(['outreach-dispositions'], getSortedDispositions, {
    refetchOnWindowFocus: false,
  });

  return (
    <DispositionsContext.Provider value={dispositions || []}>
      {children}
    </DispositionsContext.Provider>
  );
};

export default DispositionsContext;
