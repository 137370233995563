/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { TextField } from '@mui/material';
import { apiPost } from '../adalConfig';
import { useToaster } from '../Hooks/toasters';
import { parseRequestError } from '../Utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LoadingButton } from '@mui/lab';
import Header from '../components/ui/Header';

const NewDocumentType = () => {
  const [documentType, setDocumentType] = useState('');

  const { successToaster, errorToaster } = useToaster();

  const { mutate, isLoading } = useMutation({
    mutationKey: ['add-document-type', documentType],
    mutationFn: async (documentType: string) => {
      await apiPost('/api/documents/add-document-type', { label: documentType });
      setDocumentType('');
    },
    onSuccess: () => successToaster('Successfully added document type'),
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  return (
    <Fragment>
      <Header headerText="Add new document type" />
      <form
        className="m4 half df aic"
        style={{ gap: '16px' }}
        onSubmit={e => {
          e.preventDefault();
          mutate(documentType);
        }}
      >
        <div>
          <TextField
            label="Document Type"
            value={documentType}
            onChange={e => setDocumentType(e.target.value)}
            style={{ width: '300px' }}
          />
        </div>
        <div>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disabled={!documentType.trim().length}
            type="submit"
          >
            Submit
          </LoadingButton>
        </div>
      </form>
    </Fragment>
  );
};

export default NewDocumentType;
