import React from 'react';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { ActiveFilterData, DatatableRow, DropdownFilter, FilterOption, FilterType } from './types';
import VirtualListComponent from './VirtualListComponent';
import { FilterOptionsState } from '@mui/material';

type Props<Row> = {
  filter: DropdownFilter<Row>;
  selectedFilter: ActiveFilterData;
  onFilterChange: (
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<string | number>['value']>,
  ) => void;
};

const OPTIONS_LIMIT = 50;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (
  options: FilterOption<string | number>[],
  state: FilterOptionsState<FilterOption<string | number>>,
) => {
  return defaultFilterOptions(options, state as FilterOptionsState<unknown>).slice(
    0,
    OPTIONS_LIMIT,
  ) as FilterOption<string | number>[];
};

const Dropdown = <Row extends DatatableRow>({
  filter,
  selectedFilter,
  onFilterChange,
}: Props<Row>) => {
  const selectedValue = selectedFilter.values[0];
  const isGrouping = !!filter.options.slice(5).find(x => !!x.groupByValue);

  const handleOnChange = (
    filterId: string,
    value: FilterOption<string | number> | Array<FilterOption<string | number>> | null,
  ) => {
    if (!value) {
      return [];
    }

    const values = Array.isArray(value) ? value : [value];

    onFilterChange(
      filterId,
      'dropdown',
      values.map(x => x.value),
    );
  };

  return (
    <Stack>
      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        {filter.label}
      </Typography>

      {isGrouping ? (
        <Autocomplete
          options={filter.options}
          filterOptions={filterOptions}
          renderOption={(props, { value, label }) => (
            <li {...props} key={value}>
              {value === -1 ? (
                <span style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .5)' }}>(Empty)</span>
              ) : (
                <span>{label}</span>
              )}
            </li>
          )}
          groupBy={option => option.groupByValue || ''}
          onChange={(e, value) => handleOnChange(filter.id, value)}
          multiple={!!filter.multiple}
          value={
            filter.multiple
              ? filter.options.filter(x => selectedFilter.values.includes(x.value))
              : filter.options.find(x => x.value === selectedValue) || null
          }
          isOptionEqualToValue={(x, y) => x?.value === y?.value}
          autoHighlight
          renderInput={params => <TextField placeholder={`Select ${filter.label}`} {...params} />}
        />
      ) : (
        <Autocomplete
          options={filter.options}
          renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
          onChange={(e, value) => handleOnChange(filter.id, value)}
          multiple={!!filter.multiple}
          value={
            filter.multiple
              ? filter.options.filter(x => selectedFilter.values.includes(x.value))
              : filter.options.find(x => x.value === selectedValue) || null
          }
          isOptionEqualToValue={(x, y) => x?.value === y?.value}
          autoHighlight
          ListboxComponent={VirtualListComponent}
          renderInput={params => <TextField placeholder={`Select ${filter.label}`} {...params} />}
        />
      )}
    </Stack>
  );
};

export default Dropdown;
