/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Button from '../../components/ui/Button';
import { Client, FlaggedDoc, Pod } from '../../globalTypes/objects';
import { apiFetch } from '../../adalConfig';
import { convertToFileDownload } from '../../Utils';
import SetFlagDocument from './SetFlagDocument';
import Dropdown from '../../components/ui/Dropdown';
import FlaggedDocsList, { FlaggedDocumentWithDetail } from './FlaggedDocsList';
import { useClients } from '../../Hooks/useClients';
import { useToaster } from '../../Hooks/toasters';
import SearchAnyInput from '../../components/Utilities/SearchAnyInput';
import AlertDialogSlide from '../../components/Documents/DocumentDeleteAlert';
import { useGetData } from '../../Hooks';
import { User } from '../clientComponents/types';

export enum DocumentFlagStatus {
  Expected = 1,
  Received = 2,
  ReceivedSorted = 3,
}

export enum ShowUrgentOption {
  All = 1,
  UrgentOnly = 2,
  NonUrgentOnly = 3,
}

export enum ShowPendingLabel {
  All = 1,
  PendingLabel = 2,
  NotPendingLabel = 3,
}

export enum ShowPendingLocating {
  All = 1,
  PendingLocating = 2,
  NotPendingLocating = 3,
}

export default function FlagDocument({ defaultClientId }: { defaultClientId?: number }) {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modalPaper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 750,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      col1: {
        width: '25%',
        paddingTop: '10',
        paddingRight: '10',
      },
      col2: {
        width: '25%',
        paddingTop: '10',
        paddingRight: '10',
      },
      col3: {
        width: '25%',
      },
      col4: {
        paddingTop: '10',
        paddingRight: '10',
        width: '25%',
      },
      col5: {
        paddingTop: '10',
        width: '25%',
      },
    }),
  );
  const classes = useStyles();
  const [flaggedDocs, setFlaggedDocs] = useState<FlaggedDocumentWithDetail[]>([]);
  const [hasPartialData, setHasPartialData] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const { errorToaster } = useToaster();
  const [selectedClient, setSelectedClient] = useState<Client | null>({} as Client);
  const [selectedUser, setSelectedUser] = useState<User | null>({} as User);
  const [selectedPod, setSelectedPod] = useState<Pod | null>({} as Pod);
  const [searchText, setSearchText] = useState<string>('');
  const addFlag = async () => {
    try {
      setIsFlagDocumentOpen(true);
    } catch (error) {
      errorToaster(error.message);
    }
  };
  const flagStatuses = Object.entries(DocumentFlagStatus)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key.replace(/([a-z])([A-Z])/g, '$1 & $2'), value: val }));
  const showUrgentOptions = Object.entries(ShowUrgentOption)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key.replace(/([a-z])([A-Z])/g, '$1 $2'), value: val }));
  const showLabelOptions = Object.entries(ShowPendingLabel)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key.replace(/([a-z])([A-Z])/g, '$1 $2'), value: val }));
  const showLocatingOptions = Object.entries(ShowPendingLocating)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key.replace(/([a-z])([A-Z])/g, '$1 $2'), value: val }));

  const [selectedStatus, setSelectedStatus] = useState<DocumentFlagStatus | null>(null);
  const [selectedUrgentOption, setSelectedUrgentOption] = useState<ShowUrgentOption | null>(null);
  const [selectedLabelOption, setSelectedLabelOption] = useState<ShowPendingLabel | null>(null);
  const [selectedLocatingOption, setSelectedLocatingOption] = useState<ShowPendingLocating | null>(
    null,
  );
  const [isFlagDocumentOpen, setIsFlagDocumentOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmEsc, setConfirmEsc] = useState(false);

  const clients = useClients();
  const { data: users } = useGetData<User[]>('/api/documents/getflaggeddocsusers', []);
  const { data: pods } = useGetData<Pod[]>('/api/clients/pods', []);

  useEffect(() => {
    setLoading(true);
    apiFetch<FlaggedDoc[]>(`/api/Documents/flaggeddocs`, {
      params: {
        clientId: selectedClient?.id ?? 0,
        user: selectedUser?.username ?? '',
        searchText: searchText ?? '',
        status: selectedStatus,
        urgentStatus: selectedUrgentOption,
        pendingLabel: selectedLabelOption,
        pendingLocating: selectedLocatingOption,
        pod: selectedPod?.id ?? 0,
      },
    }).then(({ data }) => {
      setFlaggedDocs(bindFlaggedDocsToClients(data, clients));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedClient,
    selectedStatus,
    searchText,
    selectedUser,
    selectedUrgentOption,
    selectedPod,
    selectedLabelOption,
    selectedLocatingOption,
  ]);

  const bindFlaggedDocsToClients = (fds, clientList) =>
    fds.map(fd => ({ ...fd, client: clientList.find(c => fd.clientId === c.id) as Client }));
  const onSearchText = searchTxt => {
    setSearchText(searchTxt);
  };
  const downloadFlaggedDocs = async () => {
    try {
      setDownloading(true);
      const { data: spreadsheet } = await apiFetch(`/api/Documents/DownloadFlaggedDocs`, {
        params: {
          clientId: selectedClient?.id ?? 0,
          user: selectedUser?.username ?? '',
          searchText: searchText ?? '',
          status: selectedStatus,
          urgentStatus: selectedUrgentOption,
          pendingLabel: selectedLabelOption,
          pendingLocating: selectedLocatingOption,
          pod: selectedPod?.id ?? 0,
        },
      });
      convertToFileDownload(spreadsheet, 'Flagged Docs.xlsx', 'xlsx');
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div css={{ margin: 20, maxWidth: 1800 }}>
      <Modal
        onClose={() => {
          if (!hasPartialData) {
            setIsFlagDocumentOpen(false);
          } else {
            setConfirmEsc(true);
          }
        }}
        open={isFlagDocumentOpen}
      >
        <div className={classes.modalPaper}>
          <AlertDialogSlide
            isOpen={confirmEsc}
            handleClose={() => setConfirmEsc(false)}
            onConfirm={() => {
              setIsFlagDocumentOpen(false);
              setConfirmEsc(false);
            }}
            confirmationHeader="Cancel flagging"
            confirmationText="Are you sure you want to cancel this flag (it's not saved)?"
          />

          <SetFlagDocument
            setIsFlagDocumentOpen={setIsFlagDocumentOpen}
            flaggedDocs={flaggedDocs}
            setFlaggedDocs={setFlaggedDocs}
            setHasPartialData={setHasPartialData}
          />
        </div>
      </Modal>
      <div css={{ display: 'flex', marginBottom: 10 }}>
        <div>
          <Button onClick={downloadFlaggedDocs} disabled={downloading}>
            Download Flagged Documents
          </Button>
        </div>
        <div css={{ paddingLeft: 10 }}>
          <Button onClick={addFlag} disabled={downloading}>
            Add Flag
          </Button>
        </div>
      </div>
      <div css={{ alignItems: 'top', display: 'flex' }}>
        <div className={classes.col1}>
          <Dropdown
            css={{ zoom: 1.12 }}
            style={{ height: '70 !important' }}
            options={flagStatuses}
            placeholder="Flagged Status"
            onChange={({ value }: { value: DocumentFlagStatus }) => setSelectedStatus(value)}
            value={selectedStatus ? flagStatuses.find(({ value }) => value === selectedStatus) : ''}
          />
        </div>
        <div className={classes.col2}>
          <Autocomplete
            style={{ height: '40' }}
            isOptionEqualToValue={(option, value) => option === value}
            options={clients}
            getOptionLabel={(client: Client) => client.company ?? ''}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Select A Client" />
            )}
            value={selectedClient}
            onChange={(e, newValue) => setSelectedClient(newValue)}
          />
        </div>
        <div className={classes.col2}>
          <Autocomplete
            style={{ height: '40' }}
            isOptionEqualToValue={(option, value) => option === value}
            options={pods}
            getOptionLabel={(pod: Pod) => pod.name ?? ''}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Select A POD" />
            )}
            value={selectedPod}
            onChange={(e, newValue) => setSelectedPod(newValue)}
          />
        </div>
        <div className={classes.col3}>
          <SearchAnyInput css={{ height: '56' }} onChange={onSearchText} />
        </div>
      </div>
      <div css={{ paddingBottom: '10', alignItems: 'top', display: 'flex' }}>
        <div className={classes.col4}>
          <Dropdown
            css={{ zoom: 1.12 }}
            style={{ height: '70 !important' }}
            options={showUrgentOptions}
            placeholder="Show Urgent"
            onChange={({ value }: { value: ShowUrgentOption }) => setSelectedUrgentOption(value)}
            value={
              selectedUrgentOption
                ? showUrgentOptions.find(({ value }) => value === selectedUrgentOption)
                : ''
            }
          />
        </div>
        <div className={classes.col4}>
          <Dropdown
            css={{ zoom: 1.12 }}
            style={{ height: '70 !important' }}
            options={showLabelOptions}
            placeholder="Show Pending Label"
            onChange={({ value }: { value: ShowPendingLabel }) => setSelectedLabelOption(value)}
            value={
              selectedLabelOption
                ? showLabelOptions.find(({ value }) => value === selectedLabelOption)
                : ''
            }
          />
        </div>
        <div className={classes.col4}>
          <Dropdown
            css={{ zoom: 1.12 }}
            style={{ height: '70 !important' }}
            options={showLocatingOptions}
            placeholder="Show Pending Locating"
            onChange={({ value }: { value: ShowPendingLocating }) =>
              setSelectedLocatingOption(value)
            }
            value={
              selectedLocatingOption
                ? showLocatingOptions.find(({ value }) => value === selectedLocatingOption)
                : ''
            }
          />
        </div>
        <div className={classes.col5}>
          <Autocomplete
            style={{ height: '40' }}
            isOptionEqualToValue={(option, value) => option === value}
            options={users}
            getOptionLabel={(users: User) => users.username ?? ''}
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Select A User" />
            )}
            value={selectedUser}
            onChange={(e, newValue) => setSelectedUser(newValue)}
          />
        </div>
      </div>
      {loading && (
        <div css={{ position: 'fixed', top: '50%', left: '50%' }}>
          <CircularProgress size={50} />
        </div>
      )}
      {!loading && (
        <div css={{ marginTop: 16, minHeight: 800 }}>
          <FlaggedDocsList flaggedDocs={flaggedDocs} setFlaggedDocs={setFlaggedDocs} />
        </div>
      )}
    </div>
  );
}
