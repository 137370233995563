/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import Button from '../../components/ui/Button';
import AlertDialogSlide from '../../components/Documents/DocumentDeleteAlert';
import AddClosingPackage from './AddClosingPackage';
import { ClosingPackagesList, RowData } from './ClosingPackagesList';
import EditClosingPackage from './EditClosingPackage';
import { TableRef } from '../../components/ui/DataTableV2/Datatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    col1: {
      height: '100',
      width: '33%',
      paddingTop: '10',
      paddingRight: '10',
    },
    col2: {
      width: '33%',
      paddingTop: '10',
      paddingRight: '10',
    },
    col3: {
      width: '33%',
    },
  }),
);

export default function ClosingPackages() {
  const classes = useStyles();

  const [hasPartialData, setHasPartialData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editClosingPackage, setEditClosingPackage] = useState<RowData | null>(null);
  const [confirmEsc, setConfirmEsc] = useState(false);

  const tableRef = useRef({} as TableRef);

  return (
    <div css={{ margin: 20, maxWidth: 1800 }}>
      <Modal
        onClose={() => {
          if (!hasPartialData) {
            setIsModalOpen(false);
          } else {
            setConfirmEsc(true);
          }
        }}
        open={isModalOpen}
      >
        <div className={classes.modalPaper}>
          <AlertDialogSlide
            isOpen={confirmEsc}
            handleClose={() => setConfirmEsc(false)}
            onConfirm={() => {
              setIsModalOpen(false);
              setConfirmEsc(false);
            }}
            confirmationHeader="Cancel Closing Package"
            confirmationText="Are you sure you want to cancel this closing package (it's not saved)?"
          />
          <AddClosingPackage
            onClose={() => {
              setIsModalOpen(false);
              const { refreshTable } = tableRef.current;
              refreshTable && refreshTable();
            }}
            setHasPartialData={setHasPartialData}
          />
        </div>
      </Modal>

      {editClosingPackage && (
        <Modal
          onClose={() => {
            setEditClosingPackage(null);
          }}
          open={true}
        >
          <div className={classes.modalPaper}>
            <EditClosingPackage
              onClose={() => {
                setEditClosingPackage(null);
                const { refreshTable } = tableRef.current;
                refreshTable && refreshTable();
              }}
              closingPackage={editClosingPackage}
            />
          </div>
        </Modal>
      )}

      <div css={{ display: 'flex', marginBottom: 26, marginLeft: 32 }}>
        <Button onClick={() => setIsModalOpen(true)}>Add Closing Package</Button>
      </div>

      <ClosingPackagesList setClosingPackage={setEditClosingPackage} ref={tableRef} />
    </div>
  );
}
