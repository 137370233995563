/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import {
  AuditedAutomaticallyRowData as RowData,
  DocumentsAuditedAutomaticallyDrillDown,
} from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsAuditedAutomatically: RowData[];
};

const automatedAuditsColumns: Column<DocumentsAuditedAutomaticallyDrillDown>[] = [
  {
    id: 'id',
    label: 'Document Id',
    sortable: true,
  },
  {
    id: 'documentType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'createdAt',
    label: 'Date',
    sortable: true,
    render: ({ createdAt }) => createdAt && new Date(createdAt).toLocaleDateString(),
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'passed',
    label: 'Passed / Failed',
    sortable: true,
    render: ({ passed }) => (passed ? 'Passed' : 'Failed'),
  },
];

const AutomatedAudits = ({ documentsAuditedAutomatically }: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [dateDrillDownData, setDateDrillDownData] = useState<Date | null>(null);

  const classes = useStyles();

  const columns: Column<RowData>[] = [
    {
      id: 'count',
      label: 'Count',
      sortable: true,
      render: ({ count, date }) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() => setDateDrillDownData(new Date(date))}
        >
          {count}
        </Link>
      ),
    },
    {
      id: 'date',
      label: 'Date',
      sortable: true,
      render: ({ date }) => new Date(date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: '3px',
            right: '10px',
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          All Selected Dates
        </Button>
      </div>

      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title="Documents Audited Automatically"
          columns={columns}
          data={documentsAuditedAutomatically}
          exportFileName="Documents Audited Automatically.csv"
          dense
        />
      </div>

      <Modal open={!!dateDrillDownData} onClose={() => setDateDrillDownData(null)}>
        <div className={classes.paper}>
          <Datatable<DocumentsAuditedAutomaticallyDrillDown>
            title={`Documents Audited Automatically Report - ${dateDrillDownData?.toLocaleDateString()}`}
            columns={automatedAuditsColumns}
            url={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: dateDrillDownData?.toISOString().split('T')[0],
              andOnward: false,
            })}`}
            exportUrl={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: dateDrillDownData?.toISOString().split('T')[0],
              andOnward: false,
            })}`}
            exportFileName={`Documents Audited Automatically Report - ${dateDrillDownData?.toLocaleDateString()}.xlsx`}
          />
        </div>
      </Modal>

      <Modal open={isAllDrillDownModalOpen} onClose={() => setIsAllDrillDownModalOpen(false)}>
        <div className={classes.paper}>
          <Datatable<DocumentsAuditedAutomaticallyDrillDown>
            title={`Documents Audited Automatically Report - ${new Date(
              documentsAuditedAutomatically[0].date,
            ).toLocaleDateString()} and on`}
            columns={automatedAuditsColumns}
            url={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: documentsAuditedAutomatically[0].date,
              andOnward: true,
            })}`}
            exportUrl={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: documentsAuditedAutomatically[0].date,
              andOnward: true,
            })}`}
            exportFileName={`Documents Audited Automatically Report - ${new Date(
              documentsAuditedAutomatically[0].date,
            ).toLocaleDateString()} and on.xlsx`}
            sortConfig={{ field: 'createdAt', direction: 'desc' }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default AutomatedAudits;
