/** @jsx jsx */
import { jsx } from '@emotion/core';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    height: '140px',
    padding: '24px',
    fontFamily: 'Roboto, Helvetica, sansSerif',
    fontStyle: 'normal',
    lineHeight: 'normal',
    borderRadius: '0',
    borderRight: 'solid 1px #e6e4e8',
    backgroundColor: '#fffeff',
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#757575',
  },
  text: {
    marginTop: 8,
    fontSize: '22px',
    fontWeight: 'normal',
  },
});

type Props = {
  clientName: string;
  investorName: string;
};

const ClientInvestorData = ({ clientName, investorName }: Props) => {
  const classes = useStyles();

  return (
    <div className="df aic f-wrap">
      <div className={classes.container}>
        <div className={classes.label}>LENDER</div>
        <div className={classes.text}>{clientName}</div>
      </div>
      <div className={classes.container}>
        <div className={classes.label}>INVESTOR</div>
        <div className={classes.text}>{investorName || 'N/A'}</div>
      </div>
    </div>
  );
};

export default ClientInvestorData;
