import { ActiveFilters, PaginationData, Sort } from './types';
import { useEffect, useState } from 'react';

const useDetectParamChange = (
  activeFilters: ActiveFilters,
  sort: Sort,
  paginationData: PaginationData,
) => {
  const [localActiveFilters, setLocalActiveFilters] = useState(activeFilters);
  const [localSort, setLocalSort] = useState(sort);
  const [localPaginationData, setLocalPaginationData] = useState(paginationData);
  const [fieldsChanged, setFieldsChanged] = useState<string[]>([]);

  useEffect(() => {
    let changed = [] as string[];
    if (activeFilters !== localActiveFilters) {
      changed = Object.entries(activeFilters)
        .filter(([filterId, value]) => localActiveFilters[filterId] !== value)
        .map(([filterId, value]) => `${filterId}:${JSON.stringify(value)}`);
      setLocalActiveFilters(activeFilters);
    }

    if (sort !== localSort) {
      changed.push(`sort.field:${sort.field},sort.direction:${sort.direction}`);
      setLocalSort(sort);
    }

    if (paginationData !== localPaginationData) {
      changed.push(
        `paginationData.pageNumber:${paginationData.pageNumber},paginationData.rowsPerPage:${paginationData.rowsPerPage}`,
      );
      setLocalPaginationData(paginationData);
    }

    setFieldsChanged(changed);
  }, [activeFilters, sort, paginationData]);

  return fieldsChanged;
};

export default useDetectParamChange;
