/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import QueueMode from '../components/CallCenter/TitlePortalOutreach/QueueMode';
import OnDemandMode from '../components/CallCenter/TitlePortalOutreach/OnDemandMode';

export type Mode = 'queue' | 'on-demand';

const TitlePortalOutreach = () => {
  const { search } = useLocation();

  const mode = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return (searchParams.get('mode') || 'queue') as Mode;
  }, [search]);

  return mode === 'queue' ? <QueueMode /> : <OnDemandMode />;
};

export default TitlePortalOutreach;
