/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { CallCenterProgressByUser, CallCenterProgressHeader as Header } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  callCenterProgressHeader: Header;
};

const CallCenterProgressHeader = ({ callCenterProgressHeader }: Props) => {
  const columns: Column<CallCenterProgressByUser>[] = [
    {
      id: 'rep',
      label: 'User',
      sortable: true,
    },
    {
      id: 'totalType',
      label: 'Type',
      sortable: true,
    },
    {
      id: 'monthOne',
      label: `${callCenterProgressHeader.monthOne}`,
      sortable: true,
    },
    {
      id: 'monthTwo',
      label: `${callCenterProgressHeader.monthTwo}`,
      sortable: true,
    },
    {
      id: 'weekOne',
      label: `${callCenterProgressHeader.weekOne}`,
      sortable: true,
    },
    {
      id: 'weekTwo',
      label: `${callCenterProgressHeader.weekTwo}`,
      sortable: true,
    },
  ];

  return (
    <Fragment>
      {callCenterProgressHeader?.data && (
        <div style={{ maxHeight: '500px', overflow: 'auto' }}>
          <Datatable<CallCenterProgressByUser>
            title="Outreach Progress - By User"
            columns={columns}
            data={callCenterProgressHeader.data}
            exportFileName="Outreach Progress - By User.csv"
            dense
            rowStyle={row => ({
              backgroundColor: row.totalType === 'Loans Called' ? '#f7f7f7' : '#fff',
            })}
          />
        </div>
      )}
    </Fragment>
  );
};

export default CallCenterProgressHeader;
