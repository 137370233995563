/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { produce } from 'immer';
import { isEmpty } from 'lodash';
import { FailAfterShipIcon, ImportDocsIcon } from '../ui/icons';
import { apiFetch, apiPost } from '../../adalConfig';
import DocTypeSelect from '../Utilities/DocTypeSelect';
import { convertToBase64, isAccountRep, isAdmin, openOrDownloadFile } from '../../Utils';
import Button from '../ui/Button';
import ConditionalComponent from '../ConditionalComponent';
import {
  Failure,
  isFailure,
  isLoading,
  isSuccess,
  Loading,
  NotAsked,
  RemoteData,
  Success,
} from '../../globalTypes/RemoteData';
import { DocumentAudit, DocumentType, TitleCompany } from '../../globalTypes/objects';
import InvestorFail from './InvestorFail';
import { Event, EventDisplay, getEventNameMappings } from '../Documents/EventDisplay';
import { LoanInformation } from './types';
import { VerificationErrorCoversheetProps } from '../Documents/Imports/VerificationErrorCoversheet';
import { AuthContext } from '../AuthContext';
import { useClients } from '../../Hooks/useClients';
import { useToaster } from '../../Hooks/toasters';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import LoanDocumentChangeLogDialog from './LoanDocumentChangeLogDialog';
import Datatable, { TableRef } from '../ui/DataTableV2/Datatable';
import { Column } from '../ui/DataTableV2/types';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useQuery } from '@tanstack/react-query';
import Link from '@mui/material/Link';

const fasIcon = {
  '& svg': {
    opacity: 0.35,
  },
};

type RowData = {
  id: number;
  documentType: string;
  docTypeId: number;
  dateUploaded?: Date;
  hardCopy: boolean;
  isInvestorFailure: boolean;
  searcherCost: number;
  documentCreatedBy: string;
  events: Event[];
  audit: DocumentAudit;
  verificationFailures: string[];
  verificationNote: string;
  shippedDates: string[];
  trackingNumbers: string[];
};

type Props = {
  loan: LoanInformation;
  setCoversheetData: Dispatch<SetStateAction<VerificationErrorCoversheetProps>>;
  coversheetData: VerificationErrorCoversheetProps;
};

const LoanDocuments = ({ loan, setCoversheetData, coversheetData }: Props) => {
  const { id: loanId } = loan;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentRowData, setDocumentRowData] = useState({ documentId: 0, documentType: 0 });
  const [addingDocument, setAddingDocument] = useState(false);
  const [isChangeLogDialogOpen, setIsChangeLogDialogOpen] = useState(false);

  const { roles } = useContext(AuthContext);
  const { errorToaster } = useToaster();
  const clients = useClients();
  const tableRef = useRef({} as TableRef);

  const { data: eventDisplayNameMapping } = useQuery(
    ['event-name-mappings'],
    getEventNameMappings,
    {
      refetchOnWindowFocus: false,
    },
  );

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'ID',
      sortable: true,
      render: ({ id }: RowData) => (
        <Link href={`/documents/${id}`} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      id: 'documentType',
      label: 'Doc Type',
      sortable: true,
    },
    {
      id: 'dateUploaded',
      label: 'Barcode Generated',
      sortable: true,
      render: ({ dateUploaded }: RowData) =>
        dateUploaded ? new Date(dateUploaded).toLocaleDateString() : null,
    },
    {
      id: 'hardCopy',
      label: 'Hard Copy',
      sortable: true,
      render: ({ hardCopy }: RowData) =>
        hardCopy ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />,
    },
    {
      id: 'shipped',
      label: 'Shipped',
      render: ({ shippedDates }: RowData) =>
        shippedDates.length ? (
          <Fragment>
            {shippedDates.map((shippedDate, i) => (
              <p key={i}>{new Date(shippedDate).toLocaleDateString()}</p>
            ))}
          </Fragment>
        ) : (
          <Fragment />
        ),
    },
    {
      id: 'trackingNumber',
      label: 'Tracking #',
      render: ({ trackingNumbers }: RowData) =>
        trackingNumbers.length ? (
          <Fragment>
            {trackingNumbers.map((trackingNumber, i) => (
              <p key={i}>{trackingNumber}</p>
            ))}
          </Fragment>
        ) : (
          <Fragment />
        ),
    },
    {
      id: 'searcherCost',
      label: 'Search & Ret',
      sortable: true,
      render: ({ searcherCost }: RowData) =>
        `$${searcherCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
    },
    {
      id: 'documentCreatedBy',
      label: 'User',
      render: ({ documentCreatedBy }: RowData) =>
        documentCreatedBy?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ''),
    },
    {
      id: 'events',
      label: 'Events',
      render: ({ events }: RowData) => {
        if (!events?.length) {
          return <Fragment />;
        }

        const mostRecentDocEvent = events[events.length - 1];
        return (
          <p>
            <b>{eventDisplayNameMapping?.get(mostRecentDocEvent.event)}</b>:{' '}
            {new Date(mostRecentDocEvent.timestamp).toLocaleDateString()}
          </p>
        );
      },
    },
    {
      id: 'actions',
      label: 'Actions',
      render: (rowData: RowData) => {
        return (
          <div className="df gap-1">
            {rowData.shippedDates.length > 0 && isAccountRep(roles) && (
              <div>
                <Tooltip
                  title={
                    rowData.isInvestorFailure
                      ? 'Investor Failure Already Reported'
                      : 'Report Investor Failure'
                  }
                >
                  <span css={rowData.isInvestorFailure && fasIcon}>
                    <FailAfterShipIcon
                      style={{ color: rowData.isInvestorFailure ? '#EBEBE4' : 'inherit' }}
                      onClick={() => failDocument(rowData)}
                    />
                  </span>
                </Tooltip>
              </div>
            )}
            <div>
              <Tooltip title="Download Document">
                <div>
                  <ArrowDownwardIcon onClick={() => downloadDocument(rowData)} />
                </div>
              </Tooltip>
            </div>
            <div>
              <Tooltip title={isEmpty(coversheetData) ? 'Print Coversheet' : ''}>
                <div>
                  <ImportDocsIcon onClick={() => printCoversheet(rowData)} />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const failDocument = (rowData: RowData) => {
    setDocumentRowData({ documentId: rowData.id, documentType: rowData.docTypeId });
    setIsModalOpen(true);
  };

  const downloadDocument = async (rowData: RowData) => {
    try {
      const { data } = await apiFetch('/api/documents/getDocumentPdf', {
        responseType: 'blob',
        params: { documentId: rowData.id },
      });

      const url = window.URL.createObjectURL(data as Blob);
      openOrDownloadFile(url, `Document - ${rowData.id}.pdf`);
    } catch (e) {
      if (e.response) {
        let errorMessage = await new Response(e.response.data).text();
        errorMessage = errorMessage.split('\n')[0];

        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const printCoversheet = async (rowData: RowData) => {
    setCoversheetData({
      documentId: rowData.id,
      borrowerName: loan.borrower,
      clientName: loan.client,
      documentTypeDescription: rowData.documentType,
      dateDocumentDrawn: loan.dateFunded,
      investorName: loan.investor,
      notFound: false,
      audit: rowData.audit,
      accountRep: loan.clientAccountRep,
      verificationFailures: rowData.verificationFailures ?? [],
      note: rowData.verificationNote,
      titleCompany: { name: loan.titleCompany } as TitleCompany,
      loanNumber: loan.appNumber,
    });
    const docData = rowData;
    docData.documentType = rowData.documentType.split(' ').join('');
    apiPost(`/Api/Documents/LogReprintCoversheet/${docData.id}`, {});
  };

  return (
    <div>
      <div className="df jcsb aic">
        <div>
          {!addingDocument && <Button onClick={() => setAddingDocument(true)}>Add Document</Button>}
          {addingDocument && (
            <div css={{ width: '20rem' }}>
              <AddDocument
                loan={loan}
                onSuccess={() => {
                  setAddingDocument(false);
                  const { refreshTable } = tableRef.current;
                  refreshTable && refreshTable();
                }}
              />
            </div>
          )}
        </div>
        <div>
          {isAdmin(roles) && (
            <Tooltip title="View change log">
              <IconButton color="primary" onClick={() => setIsChangeLogDialogOpen(x => !x)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
          <span className="bold">Required Documents: </span>
          <span style={{ textTransform: 'capitalize' }}>{loan.requiredDocuments.join(', ')}</span>
        </div>
      </div>

      <div className="mt2">
        <Datatable<RowData>
          title="Loan documents"
          columns={columns}
          url={`/api/loans/${loanId}/documents`}
          exportUrl={`/api/loans/${loanId}/documents`}
          exportFileName={`Loan documents - ${loan.appNumber}.xlsx`}
          sortConfig={{ field: 'id', direction: 'asc' }}
          searchBarPlaceholder="Search by ID or Doc Type"
          detailsPanel={rowData => (
            <ConditionalComponent display={rowData.events?.length}>
              <div
                style={{
                  padding: '16px 60px',
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  gridRowGap: 16,
                }}
              >
                {rowData.events?.map(docEvent =>
                  EventDisplay({ docEvent, clients, eventDisplayNameMapping }),
                )}
              </div>
            </ConditionalComponent>
          )}
          ref={tableRef}
        />
      </div>

      {isModalOpen && (
        <InvestorFail
          documentId={documentRowData.documentId}
          documentType={documentRowData.documentType}
          refreshDatatable={tableRef.current.refreshTable || (() => {})}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <LoanDocumentChangeLogDialog
        loanId={loanId}
        open={isChangeLogDialogOpen}
        handleCloseDialog={() => setIsChangeLogDialogOpen(false)}
      />
    </div>
  );
};

interface DocumentForm {
  documentType: DocumentType | undefined;
  loanId: number;
  clientId: number;
  fileContents: string;
  filename: string | undefined;
  uploadedToLoan: boolean;
}

function documentFormIsValid(documentForm: DocumentForm): true | string {
  if (!documentForm.documentType || documentForm.documentType === DocumentType.Unknown) {
    return 'Please select a document type.';
  }
  if (!documentForm.fileContents) {
    return 'Please choose a document to upload.';
  }

  return true;
}

const initForm = loan => ({
  documentType: undefined,
  clientId: loan.clientId,
  fileContents: '',
  loanId: loan.id,
  uploadedToLoan: true,
  filename: undefined,
  file: undefined,
});

function AddDocument({ loan, onSuccess }) {
  const { id: loanId } = loan;
  const [documentForm, setDocumentForm] = useState<DocumentForm>(() => initForm(loan));

  const [submitDocumentReq, setSubmitDocumentReq] = useState<RemoteData<unknown, Error>>(NotAsked);
  const [formValidationError, setFormValidationError] = useState<string>();

  function submitDocument(form: DocumentForm) {
    setSubmitDocumentReq(NotAsked);
    setFormValidationError(undefined);
    const formValidation = documentFormIsValid(form);
    if (formValidation === true) {
      setSubmitDocumentReq(Loading);
      apiPost(`/Api/Documents/CreateSoftCopy`, documentForm)
        .then(res => {
          setSubmitDocumentReq(Success(res.data));
          setDocumentForm(initForm(loan));
        })
        .catch(e => {
          setSubmitDocumentReq(Failure(e));
          setDocumentForm(initForm(loan));
        });
    } else {
      setFormValidationError(formValidation);
    }
  }

  useEffect(() => {
    setFormValidationError(undefined);
  }, [documentForm]);

  return (
    <Fragment>
      <DocTypeSelect
        docType={documentForm.documentType}
        onSelect={docType =>
          setDocumentForm(
            produce(documentForm, formDraft => {
              formDraft.documentType = docType;
            }),
          )
        }
      />
      <input
        type="file"
        accept=".pdf"
        onInput={async e => {
          // @ts-ignore
          const file = e.target.files[0];
          const fileContents = await convertToBase64(file);
          if (!fileContents) return;
          setDocumentForm(
            produce(documentForm, documentFormDraft => {
              documentFormDraft.fileContents = fileContents;
              documentFormDraft.filename = file?.name;
            }),
          );
        }}
      />
      <Button
        type="submit"
        onClick={() => {
          submitDocument(documentForm);
        }}
      >
        Submit
      </Button>
      <div>
        <h3 css={{ color: 'red' }}>{formValidationError}</h3>
        {isLoading(submitDocumentReq) && <div>Uploading...</div>}
        {isFailure(submitDocumentReq) && <div>There was a problem uploading the document.</div>}
        {isSuccess(submitDocumentReq) && <div>Successfully uploaded!</div>}
      </div>
    </Fragment>
  );
}

export default LoanDocuments;
