/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { format } from 'date-fns';
import { MonthlyPayments as RowData } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column, RowDataForExport } from '../../components/ui/DataTableV2/types';

type Props = {
  monthlyPayments: RowData[];
  fromDate: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'company',
    label: 'Client',
    sortable: true,
  },
  {
    id: 'paymentAmt',
    label: 'Payment Amount',
    sortable: true,
    render: ({ paymentAmt }) =>
      paymentAmt.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  },
  {
    id: 'datePaid',
    label: `Date Paid`,
    sortable: true,
    render: ({ datePaid }) => new Date(datePaid).toLocaleDateString(),
  },
  {
    id: 'checkNo',
    label: `Check Number`,
    sortable: true,
  },
];

const customExport = (rows: RowData[]) =>
  rows.map(
    row =>
      ({
        Client: row.company,
        'Payment Amount': `${row.paymentAmt.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}`,
        'Date Paid': row.datePaid && new Date(row.datePaid).toLocaleDateString(),
        'Check Number': row.checkNo,
      } as RowDataForExport),
  );

const MonthlyPayments = ({ monthlyPayments, fromDate }: Props) => (
  <Fragment>
    {monthlyPayments && (
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<RowData>
          title={`Monthly Payments - For - ${format(new Date(fromDate), 'MMMM')}   ${format(
            new Date(fromDate),
            'yyyy',
          )}`}
          columns={columns}
          data={monthlyPayments}
          exportFileName={`Monthly Payments - For - ${format(
            new Date(fromDate),
            'MMMM',
          )}   ${format(new Date(fromDate), 'yyyy')}.csv`}
          customExport={customExport}
          dense
        />
      </div>
    )}
  </Fragment>
);

export default MonthlyPayments;
