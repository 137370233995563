import React, {
  createContext,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const { label, value } = dataSet[1];
  return (
    <Typography component="li" {...dataSet[0]} style={inlineStyle}>
      {value === -1 ? (
        <span style={{ fontStyle: 'italic', color: 'rgba(0, 0, 0, .5)' }}>(Empty)</span>
      ) : (
        <span>{label.length < 50 ? label : label.slice(0, 50) + '...'}</span>
      )}
    </Typography>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const VirtualListComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData = children as ReactElement[];

    const itemCount = itemData.length;
    const itemSize = 48;
    const gridRef = useResetCache(itemCount);

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }

      return itemCount * itemSize;
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={_ => itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export default VirtualListComponent;

const useResetCache = (data: number) => {
  const ref = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
};
