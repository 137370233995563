import React, { Fragment } from 'react';
import { MsalAuthenticationTemplate, useAccount } from '@azure/msal-react';
import beamerInit from './beamerInit';
import ErrorBoundary from './ErrorBoundary';
import Providers from './Providers';
import GlobalStyles from './components/GlobalStyles';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router-dom';
import Router from './Router';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './auth-config';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#0828CC',
        light: '#663CFF',
        dark: '#00009A',
      },
    },
    typography: {
      fontWeightMedium: 700,
      button: {
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: '1',
      },
    },
  }),
);

const App = () => {
  const account = useAccount();

  if (account) {
    const user = account.idTokenClaims;
    beamerInit(BEAMER_ID, {
      user_firstname: user?.given_name,
      user_lastname: user?.family_name,
      user_email: user?.email,
    });
  }

  return (
    <Fragment>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <ErrorBoundary>
          <Providers>
            <GlobalStyles />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Router />
                  </QueryParamProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </Providers>
        </ErrorBoundary>
      </MsalAuthenticationTemplate>
    </Fragment>
  );
};

export default App;

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}
