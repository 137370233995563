/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Client } from '../../globalTypes/objects';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  completedLoans: LoanInfo[];
  client: Client;
};

export type LoanInfo = {
  loanId: number;
  appNumber: string;
  borrower: string;
  dateFunded: Date;
  titleCompany: string;
  mortgageDate: Date;
  policyDate: Date;
};

const columns: Column<LoanInfo>[] = [
  {
    id: 'loanId',
    label: 'Loan Id',
    sortable: true,
  },
  {
    id: 'appNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'borrower',
    label: 'Borrower',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'titleCompany',
    label: 'Title Company',
    sortable: true,
  },
  {
    id: 'mortgageDate',
    label: 'Mortgage Date',
    sortable: true,
    render: ({ mortgageDate }) => new Date(mortgageDate).toLocaleDateString(),
  },
  {
    id: 'policyDate',
    label: 'Policy Date',
    sortable: true,
    render: ({ policyDate }) => new Date(policyDate).toLocaleDateString(),
  },
];

const CompletedLoans = ({ completedLoans, client }: Props) => (
  <Fragment>
    {completedLoans && (
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Datatable<LoanInfo>
          title={`Completed Loans For - ${client.company}`}
          columns={columns}
          data={completedLoans}
          exportFileName={`Completed Loans for -  ${client.company}.csv`}
          dense
        />
      </div>
    )}
  </Fragment>
);

export default CompletedLoans;
