import React, { Fragment } from 'react';
import { LegendItem } from './types';
import Box from '@mui/material/Box';

type Props = {
  legendItems?: LegendItem[];
};

const DataLegend = ({ legendItems }: Props) => {
  if (!legendItems) {
    return <></>;
  }

  return (
    <Box sx={{ px: 1, py: 0, display: 'flex', gap: 8 }}>
      {legendItems.map((legendItem, i) => (
        <Box sx={{ py: 1, display: 'flex', gap: 2, alignItems: 'center' }} key={i}>
          {legendItem.map(subItem => (
            <Fragment key={subItem.text}>
              <div>{subItem.symbol}</div>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  lineHeight: '18px',
                }}
              >
                {subItem.text}
              </div>
            </Fragment>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default DataLegend;
