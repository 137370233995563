/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import { FunctionComponent } from 'react';
import Button from '../Button';
import IconCircle from './IconCircle';
import colors from '../../../styles/colors';
import { IconType } from '../Icon';

export enum StatusTypes {
  initial = 'INITIAL',
  loading = 'LOADING',
  success = 'SUCCESS',
  error = 'ERROR',
  warning = 'WARNING',
}

export enum RadioSelections {
  POD = 'POD',
  Client = 'Client',
  ALL = 'ALL',
}

const circleStyles: CSSObject = {
  backgroundColor: colors.blue,
};
const getIcon = (status?: StatusTypes, initialIcon = IconType.RightArrow) => {
  switch (status) {
    case StatusTypes.initial:
      return <IconCircle icon={initialIcon} styleOverrides={circleStyles} />;
    case StatusTypes.loading:
      return <IconCircle icon={IconType.Processing} styleOverrides={circleStyles} />;
    case StatusTypes.success:
      return (
        <IconCircle
          icon={IconType.Check}
          styleOverrides={{
            ...circleStyles,
            backgroundColor: colors.blueLight,
          }}
        />
      );
    case StatusTypes.error:
      return (
        <IconCircle
          icon={IconType.X}
          styleOverrides={{ ...circleStyles, backgroundColor: colors.red }}
        />
      );
    default:
      return null;
  }
};

const iconStyles: CSSObject = {
  marginRight: '8px',
  width: '40px',
};

const textStyles: CSSObject = {
  textAlign: 'center',
  display: 'inline-block',
};

const secondaryStyles = {
  backgroundColor: colors.white,
  border: `1px solid ${colors.blue}`,
  color: colors.blackText,
  div: {
    color: colors.white,
  },
};

type StatusButtonProps = {
  status?: StatusTypes;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  text?: string;
  initialIcon?: IconType;
  width?: number;
  secondary?: boolean;
  styleOverrides?: any;
};

const StatusButton: FunctionComponent<StatusButtonProps> = ({
  status,
  disabled,
  onClick,
  text,
  initialIcon,
  width,
  secondary,
  styleOverrides,
  children,
  ...props
}) => {
  const buttonStyles = !disabled &&
    status && [
      {
        alignItems: 'center',
        backgroundColor: colors.blueStatus,
        display: 'inline-flex',
        justifyContent: 'space-between',
        padding: 0,
        paddingLeft: '20px',
        width,
        ':hover': {
          backgroundColor: colors.blueDark,
          color: colors.white,
          border: 'none',
          ' div': {
            backgroundColor: colors.white,
            borderRadius: '50%',
            color: colors.blue,
          },
        },
      },
      secondary && secondaryStyles,
      styleOverrides,
      status === StatusTypes.loading && { pointerEvents: 'none' },
    ];
  return (
    <Button
      large
      dark
      disabled={disabled}
      styleOverrides={buttonStyles}
      onClick={status !== StatusTypes.loading && status !== StatusTypes.success ? onClick : null}
      {...props}
    >
      <span
        css={[textStyles, !disabled && status && { width: 'calc(100% - 48px)', marginRight: 16 }]}
      >
        {text || children}
      </span>
      {!disabled && <div css={iconStyles}>{getIcon(status, initialIcon)}</div>}
    </Button>
  );
};

export default StatusButton;

export const getButtonStatus = (loading: Boolean, error: Boolean, success: Boolean) => {
  if (loading) return StatusTypes.loading;
  if (error) return StatusTypes.error;
  if (success) return StatusTypes.success;
  return StatusTypes.initial;
};
