/** @jsx jsx */
import { jsx } from '@emotion/core';
import { startCase } from 'lodash';
import { apiFetch } from '../../adalConfig';
import Link from '@mui/material/Link';

export type Event = {
  id: number;
  documentId: number;
  event: string;
  timestamp: string;
  username: string;
  logEvent: string;
  requestUrl: string;
};

export const getEventNameMappings = async () => {
  const { data } = await apiFetch<{ [key: string]: string }>('/api/documents/event-name-mappings');

  return Object.entries(data).reduce((acc, [key, value]) => acc.set(key, value), new Map());
};

export const EventDisplay = ({
  docEvent,
  clients,
  eventDisplayNameMapping,
}: {
  docEvent: Event;
  clients;
  eventDisplayNameMapping: Map<string, string> | undefined;
}) => {
  let auditPassed = false;
  const { timestamp, event, logEvent, id, username } = docEvent;
  if (event === 'AuditPerformed') {
    const auditEvent = JSON.parse(logEvent);
    auditPassed = auditEvent?.Properties?.Audit?.Passed ?? false;
  }

  let deletedReason = null;
  if (event === 'Deleted') {
    const deletedEvent = JSON.parse(logEvent);
    deletedReason = deletedEvent?.Properties?.DocumentDeletedReason ?? null;
  }

  let resolution = '';
  if (event === 'DocumentCured' || event === 'DocumentAutoCured') {
    const cureEvent = JSON.parse(logEvent);
    const cureInfo = cureEvent?.Properties?.DocumentCureInfo;
    const resolutionType = cureInfo?.ResolutionType ?? cureInfo?.CorrectionType ?? '';
    const resolvedBy =
      cureInfo?.ResolvedViaDocumentId ?? cureInfo?.CorrectDocumentId ?? 'New Document';

    if (resolutionType === 'CorrectionNotNeeded') {
      resolution = 'No Correction Needed';
    } else if (resolutionType === 'Replacement') {
      resolution = `Replaced with ${resolvedBy}`;
    } else if (resolutionType === 'Supportive') {
      resolution = `Supported by ${resolvedBy}`;
    }
  }

  let oldType = '';
  let newType = '';
  if (event === 'DocumentTypeUpdated') {
    const updateEvent = JSON.parse(logEvent);
    const docTypeUpdateInfo = updateEvent?.Properties?.DocumentTypeUpdate;
    oldType = docTypeUpdateInfo?.From ?? '';
    newType = docTypeUpdateInfo?.To ?? '';
  }

  let oldClient = '';
  if (event === 'ClientUpdated') {
    const updateEvent = JSON.parse(logEvent);
    const logEventInfo = updateEvent?.Properties;
    oldClient = clients.find(c => c.id === parseInt(logEventInfo?.FromId))?.company ?? '';
  }

  let failedDoc = '';
  if (event === 'CuringDocumentFound') {
    const possibleCureInfo = JSON.parse(logEvent)?.Properties;
    failedDoc = possibleCureInfo?.FailedDocument?.Id ?? possibleCureInfo?.FailedPolicy?.Id ?? '';
  }

  let destination = '';
  if (event === 'UploadedToExternalLocation') {
    const uploadInfo = JSON.parse(logEvent)?.Properties;
    destination = uploadInfo?.Destination ?? '';
  }

  let newBarcode = null;
  if (event === 'InternallyShipped') {
    newBarcode = JSON.parse(logEvent)?.Properties?.InternalShipNewDocumentId;
  }

  return (
    <div key={id}>
      <p className="bold fs16">{eventDisplayNameMapping?.get(event)}:</p>
      <span className="fs18">
        {username?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ' - ')}
      </span>
      <span className="fs18">{new Date(timestamp).toLocaleDateString()}</span>
      {event === 'Deleted' && deletedReason && <div>{startCase(deletedReason)}</div>}
      {event === 'ClientUpdated' && (
        <div className="fs18">
          <span className="fs18">{oldClient}</span>
        </div>
      )}
      {event === 'AuditPerformed' && (
        <div className="fs18">
          <span style={{ backgroundColor: auditPassed ? '#d4edda' : '#f8d7da' }}>
            {auditPassed ? 'Passed' : 'Failed'}
          </span>
        </div>
      )}
      {(event === 'DocumentCured' || event === 'DocumentAutoCured') && (
        <div className="fs16">{resolution}</div>
      )}
      {event === 'DocumentTypeUpdated' && oldType !== '' && newType !== '' && (
        <div className="fs16">
          {oldType} to {newType}
        </div>
      )}
      {event === 'CuringDocumentFound' && <div className="fs16">For {failedDoc}</div>}
      {event === 'UploadedToExternalLocation' && <div className="fs16">To {destination}</div>}
      {event === 'InternallyShipped' && (
        <div className="fs16">
          New barcode:{' '}
          <Link href={`/documents/${newBarcode}`} target="_blank" underline="hover">
            {newBarcode}
          </Link>
        </div>
      )}
    </div>
  );
};
