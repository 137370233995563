import React from 'react';
import {
  ActiveFilters,
  DatatableRow,
  DropdownFilter as Filter,
  FilterOption,
  FilterType,
} from './types';
import Dropdown from './Dropdown';

type Props<Row> = {
  filters: Filter<Row>[];
  selectedFilters: ActiveFilters;
  onFilterChange: (
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<string | number>['value']>,
  ) => void;
};

const DropdownFilters = <Row extends DatatableRow>({
  filters,
  selectedFilters,
  onFilterChange,
}: Props<Row>) => (
  <>
    {filters.map(filter => (
      <Dropdown
        key={filter.id}
        filter={filter}
        selectedFilter={selectedFilters[filter.id]}
        onFilterChange={onFilterChange}
      />
    ))}
  </>
);

export default DropdownFilters;
