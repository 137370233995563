/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { AutoDeletedDocs as RowData } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  autoDeletedDocs: RowData[];
  fromDate: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'barcodeId',
    label: 'Barcode Id',
    sortable: true,
  },
  {
    id: 'docType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'deletedDate',
    label: `Deleted Date`,
    sortable: true,
    render: ({ deletedDate }) => new Date(deletedDate).toLocaleDateString(),
  },
  {
    id: 'client',
    label: `Client`,
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: `Loan Number`,
    sortable: true,
  },
  {
    id: 'reason',
    label: `Deleted Reason`,
    sortable: true,
  },
];

const AutoDeletedDocuments = ({ autoDeletedDocs, fromDate }: Props) => (
  <Fragment>
    {autoDeletedDocs && (
      <Datatable<RowData>
        title={`Deleted Docs From  ${fromDate.toLocaleDateString()}`}
        columns={columns}
        data={autoDeletedDocs}
        exportFileName={`Deleted Docs From  ${fromDate.toLocaleDateString()}.csv`}
        dense
      />
    )}
  </Fragment>
);

export default AutoDeletedDocuments;
