/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Column } from 'material-table';
import DataTable from '../../components/ui/DataTable/DataTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useToaster } from '../../Hooks/toasters';
import { apiPost } from '../../adalConfig';
import { parseRequestError } from '../../Utils';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type RowData = {
  id: number;
  client: string;
  email: string;
  permission: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: string;
  isRegistered: boolean;
};

const columns: Column<object>[] = [
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'permission',
    title: 'Permission',
  },
  {
    field: 'firstName',
    title: 'First Name',
  },
  {
    field: 'lastName',
    title: 'Last Name',
  },
  {
    field: 'role',
    title: 'Role',
  },
  {
    field: 'createdAt',
    title: 'Date Created',
    render: ({ createdAt }: RowData) => new Date(createdAt).toLocaleDateString(),
  },
];

function ViewUsers() {
  const [confirmDeleteRow, setConfirmDeleteRow] = useState<RowData | null>(null);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});

  const { successToaster, errorToaster } = useToaster();

  const deleteUser = async () => {
    try {
      await apiPost('/api/client-portal-user-management/delete-user', { id: confirmDeleteRow?.id });
      successToaster('Successfully deleted user');
      refreshDatatable();
      setConfirmDeleteRow(null);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    }
  };

  return (
    <div style={{ marginTop: 16, marginLeft: '-12px' }}>
      <Dialog open={confirmDeleteRow !== null} onClose={() => setConfirmDeleteRow(null)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to delete user {confirmDeleteRow?.email}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteRow(null)} autoFocus>
            Cancel
          </Button>
          <Button onClick={deleteUser}>Delete</Button>
        </DialogActions>
      </Dialog>
      <DataTable<RowData>
        title="Users"
        columns={columns}
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        actions={() => [
          (rowData: RowData) => ({
            icon: () => <DeleteIcon style={{ color: 'red' }} />,
            tooltip: 'Delete user',
            onClick: (event, rowData) => setConfirmDeleteRow(rowData),
            hidden: rowData.isRegistered,
          }),
        ]}
        url="/api/client-portal-user-management/get-users"
        orderBy={{ field: 'createdAt' }}
        orderDirection="desc"
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </div>
  );
}

export default ViewUsers;
