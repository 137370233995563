/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import {
  CallCenterDocumentsFromCalls,
  CallCenterDocumentsFromCallsHeader as Header,
} from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  callCenterDocumentsFromCallsHeader: Header;
};

const CallCenterDocumentsFromCallsHeader = ({ callCenterDocumentsFromCallsHeader }: Props) => {
  const columns: Column<CallCenterDocumentsFromCalls>[] = [
    {
      id: 'rep',
      label: 'User',
      sortable: true,
    },
    {
      id: 'date1',
      label: `${callCenterDocumentsFromCallsHeader?.dateOneName}`,
      sortable: true,
    },
    {
      id: 'date2',
      label: `${callCenterDocumentsFromCallsHeader.dateTwoName}`,
      sortable: true,
    },
    {
      id: 'date3',
      label: `${callCenterDocumentsFromCallsHeader.dateThreeName}`,
      sortable: true,
    },
    {
      id: 'date4',
      label: `${callCenterDocumentsFromCallsHeader.dateFourName}`,
      sortable: true,
    },
    {
      id: 'date5',
      label: `${callCenterDocumentsFromCallsHeader.dateFiveName}`,
      sortable: true,
    },
    {
      id: 'date6',
      label: `${callCenterDocumentsFromCallsHeader.dateSixName}`,
      sortable: true,
    },
  ];

  return (
    <Fragment>
      {callCenterDocumentsFromCallsHeader?.data && (
        <Datatable<CallCenterDocumentsFromCalls>
          title={`Outreach Documents Received - ${callCenterDocumentsFromCallsHeader.reportType}`}
          columns={columns}
          data={callCenterDocumentsFromCallsHeader.data}
          exportFileName={`Outreach Documents Received - ${callCenterDocumentsFromCallsHeader.reportType}.csv`}
          dense
        />
      )}
    </Fragment>
  );
};

export default CallCenterDocumentsFromCallsHeader;
