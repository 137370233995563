import { useState } from 'react';
import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import { parse } from 'papaparse';
import useFilters, { activeValues } from './useFilters';
import {
  ActiveFilters,
  ActiveFilterValue,
  Column,
  DatatableResponse,
  DatatableRow,
  DateRangeValue,
  Filter,
  FilterGroups,
  PaginationData,
  RowDataForExport,
  RowsPerPage,
  SearchFilter,
  Sort,
} from './types';
import { DataHookReturn } from './useData';
import { apiFetch } from '../../../adalConfig';
import { downloadCsv, downloadFileFromBlob } from '../../../Utils';
import useDetectParamChange from './useDetectParamChange';
import { isNullOrUndefined } from './utils';

type OrderBy = {
  column: string;
  directionAscending: boolean;
};

type PartialRequestParams = {
  orderBy: OrderBy;
  fetch: number;
  offset: number;
};

type RequestParams = PartialRequestParams & {
  [key: string]: ActiveFilterValue | ActiveFilterValue[];
};

const filtersForRequest = <Row>(filterGroups: FilterGroups<Row>) => {
  const radioFilters = filterGroups.radio.reduce((final, filter) => {
    final[filter.id] = activeValues(filter.options)[0];
    return final;
  }, {} as { [key: string]: ActiveFilterValue | ActiveFilterValue[] });

  const checkboxFilters = filterGroups.checkbox.reduce((final, filter) => {
    final[filter.id] = activeValues(filter.options);
    return final;
  }, {} as { [key: string]: ActiveFilterValue | ActiveFilterValue[] });

  const dateRangeFilters = filterGroups.dateRange.reduce((final, filter) => {
    const activeValue = activeValues(filter.options)[0] as DateRangeValue;
    if (!activeValue) {
      return final;
    }

    final[`${filter.id}Start`] = activeValue.start!.toDateString();
    final[`${filter.id}End`] = activeValue.end!.toDateString();
    return final;
  }, {} as { [key: string]: ActiveFilterValue | ActiveFilterValue[] });

  const dropdownFilters = filterGroups.dropdown.reduce((final, filter) => {
    if (filter.multiple) {
      final[filter.id] = activeValues(filter.options);
      return final;
    }

    const activeValue = activeValues(filter.options)[0];
    if (!activeValue) {
      return final;
    }

    final[filter.id] = activeValue;
    return final;
  }, {} as { [key: string]: ActiveFilterValue | ActiveFilterValue[] });

  const searchFilters = filterGroups.search.reduce((final, filter) => {
    final[filter.id] = activeValues(filter.options)[0];
    return final;
  }, {} as { [key: string]: ActiveFilterValue | ActiveFilterValue[] });

  return {
    ...radioFilters,
    ...checkboxFilters,
    ...dateRangeFilters,
    ...dropdownFilters,
    ...searchFilters,
  };
};

const requestParams = <Row>(
  filterGroups: FilterGroups<Row>,
  paginationData: PaginationData,
  sort: Sort,
): RequestParams => {
  return {
    ...filtersForRequest(filterGroups),
    orderBy: { column: sort.field, directionAscending: sort.direction === 'asc' },
    fetch: paginationData.rowsPerPage,
    offset: paginationData.pageNumber * paginationData.rowsPerPage,
  } as RequestParams;
};

/**
 * Returns whether the ajax request params are populated correctly.
 *
 * If the URL query string contain preset filters, we don't want to send the request until we pulled those filters out of the URL query string
 *
 * This method compares the filters within the query string against the filters in the request. If the request doesn't have filters but the URL query string does, this method returns false.
 */
const isMissingFilters = (filters: RequestParams) => {
  const fieldsToIgnore = ['fetch', 'offset', 'orderBy'];
  const hasFilters = Object.entries(filters)
    .filter(([key]) => !fieldsToIgnore.includes(key))
    .some(filter => {
      const value = filter[1] as string | number | null;
      return !isNullOrUndefined(value) && (typeof value === 'number' || value.length);
    });

  if (hasFilters) {
    return false;
  }

  // pattern to extract the values for the URL query string (from '=' until '&' (if '&' exists))
  const regExp = /[A-Za-z0-9?]+=([^&]*)&?/g;
  return location.search.replace(regExp, '$1').length > 0;
};

const getDataFromUrl = async <Row>(
  url: string,
  filterGroups: FilterGroups<Row>,
  paginationData: PaginationData,
  sort: Sort,
  signal: AbortSignal | undefined,
  fieldsChanged: string[],
  allowMultiSearch: boolean | undefined = false,
) => {
  const filters = requestParams(filterGroups, paginationData, sort);

  if (isMissingFilters(filters)) {
    return { dataTableRows: [], count: 0 } as DatatableResponse<Row>;
  }

  const getRowCount = fieldsChanged.some(
    field => !field.startsWith('sort') && !field.startsWith('paginationData'),
  );

  const { data } = await apiFetch<DatatableResponse<Row>>(url, {
    params: { filters: { ...filters, isExporting: false, getRowCount, allowMultiSearch } },
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    signal,
  });

  data.dataTableRows = data.dataTableRows.map((row, i) => ({ ...row, rowId: i }));

  return data;
};

const dataForExport = async <Row>(
  url: string,
  filterGroups: FilterGroups<Row>,
  paginationData: PaginationData,
  sort: Sort,
  allowMultiSearch: boolean,
) => {
  const { headers, data } = await apiFetch(url, {
    params: {
      filters: {
        ...requestParams(filterGroups, paginationData, sort),
        getRowCount: false,
        isExporting: true,
        allowMultiSearch,
      },
    },
    paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    responseType: 'blob',
  });

  return {
    data,
    headers,
  } as { data: Blob; headers: typeof headers };
};

async function exportDatatable<Row>(
  exportUrl: string | undefined,
  exportFileName: string | undefined,
  filterGroups: FilterGroups<Row>,
  paginationData: PaginationData,
  sort: Sort,
  customExport: ((rows: Row[]) => RowDataForExport[]) | undefined,
  allowMultiSearch: boolean | undefined = false,
) {
  if (!exportUrl) {
    return 1;
  }

  const { data, headers } = await dataForExport<Row>(
    exportUrl,
    filterGroups,
    paginationData,
    sort,
    allowMultiSearch,
  );

  const filename = exportFileName || 'export.csv';

  if (!customExport) {
    downloadFileFromBlob(data, filename, headers['content-disposition']);
    return 1;
  }

  const blobText = await data.text();
  const rows = parse(blobText, { header: true, dynamicTyping: true }).data as Row[];
  const customData = customExport(rows);
  downloadCsv(customData, filename, 'text/csv');
  return 1;
}

const useServerData = <Row extends DatatableRow>(
  columns: Column<Row>[],
  url: string,
  filters: Filter<Row>[],
  rowsPerPage: RowsPerPage,
  sortConfig?: Sort,
  exportUrl?: string,
  exportFileName?: string,
  customExport?: (rows: Row[]) => RowDataForExport[],
  allowMultiSearch?: boolean,
) => {
  const searchFilter: SearchFilter<Row> = {
    filterType: 'search',
    id: 'searchText',
    label: 'Search',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  };

  const [paginationData, setPaginationData] = useState<PaginationData>({
    pageNumber: 0,
    rowsPerPage,
  });
  const [sort, setSort] = useState<Sort>(sortConfig || { field: columns[0].id, direction: 'desc' });

  const {
    filterGroups,
    activeFilters,
    updateFilters,
    filterChips,
    clearFilter,
    clearFilters,
    initialized,
  } = useFilters([...filters, searchFilter]);

  const fieldsChanged = useDetectParamChange(activeFilters, sort, paginationData);

  const {
    data,
    isFetching,
    error: dataError,
    refetch: refresh,
  } = useQuery({
    queryKey: [url, ...fieldsChanged],
    queryFn: ({ signal }) =>
      getDataFromUrl<Row>(
        url!,
        filterGroups,
        paginationData,
        sort,
        signal,
        fieldsChanged,
        allowMultiSearch,
      ),
    enabled: !!url,
    refetchOnWindowFocus: false,
    retry: (failureCount, error) => failureCount < 3,
  });

  const {
    refetch: exportData,
    isFetching: isExporting,
    error: exportError,
  } = useQuery({
    queryKey: ['datatable-export', exportUrl, ...fieldsChanged],
    queryFn: async () =>
      await exportDatatable(
        exportUrl,
        exportFileName,
        filterGroups,
        paginationData,
        sort,
        customExport,
        allowMultiSearch,
      ),
    enabled: false,
  });

  const onSortChange = (field: string) => {
    setSort(prev => {
      if (prev.field === field) {
        return { field, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
      }

      return { ...prev, field };
    });
  };

  const resetPagination = () => {
    setPaginationData(x => ({ ...x, pageNumber: 0 }));
  };

  const onPaginationChange = (field: keyof PaginationData, value: number) => {
    setPaginationData(x => ({ ...x, [field]: value }));
  };

  const onFiltersSubmit = (submittedFilters: ActiveFilters) => {
    updateFilters(submittedFilters);
    resetPagination();
  };

  return {
    data,
    isLoading: isFetching,
    error: dataError || exportError,
    filterGroups,
    activeFilters,
    filterChips,
    clearFilter,
    clearFilters,
    sort,
    onSortChange,
    paginationData,
    onPaginationChange,
    onFiltersSubmit,
    refresh,
    exportData,
    isExporting,
    filtersInitialized: initialized,
  } as DataHookReturn<Row>;
};

export default useServerData;
