import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { apiFetch } from '../../../adalConfig';
import { RowData } from '../types';

const getTitleCompanyLoansByTitleId = async ({ queryKey }: QueryFunctionContext<number[]>) => {
  const { data } = await apiFetch<RowData[]>(
    `/api/call-tasks/get-title-portal-outreach-loans/${queryKey[1]}`,
  );
  return data;
};

const useOnDemandOutstandingLoans = (titleCompanyId?: number) => {
  const [localTime, setLocalTime] = useState(new Date().toLocaleDateString());
  const [timeInterval, setTimeInterval] = useState<NodeJS.Timeout>();
  const [datatableData, setDatatableData] = useState([] as RowData[]);

  const { data, isFetching } = useQuery(
    ['title-portal-outreach-by-title-id', titleCompanyId],
    getTitleCompanyLoansByTitleId,
    {
      enabled: !!titleCompanyId,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setDatatableData(data);

    if (!data.length) {
      return;
    }

    if (timeInterval) {
      clearInterval(timeInterval);
    }

    const interval = setInterval(
      () => setLocalTime(new Date().toLocaleTimeString('en-US', { timeZone: data[0].timeZone })),
      1000,
    );

    setTimeInterval(interval);
  }, [data]);

  const loansRemaining = useMemo(() => {
    if (!datatableData.length) {
      return 0;
    }

    return datatableData.filter(row => {
      if (row.isResolved || row.isDeferred) {
        return false;
      }

      return (row.calculatedOutreachStage || 1) <= 2;
    }).length;
  }, [datatableData]);

  return {
    datatableData,
    setDatatableData,
    isLoading: isFetching,
    loansRemaining,
    localTime,
  };
};

export default useOnDemandOutstandingLoans;
