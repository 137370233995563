export type DocumentIntake = {
  totalCoverSheetsCreatedToday: number;
  percentOfNotFoundDocuments: number;
  percentOfTotalWeeklyDocumentCreationGoal: number;
  totalDocumentsCreatedMonthToDate: number;
  totalDocumentsCreatedYearToDate: number;
  inQueueToBeScanned: number;
  inQueueToBeScannedAsOfToday: number;
  totalDocumentsStapledToday: number;
  allDocumentsInQueueToBeStapled: number;
  documentsInQueueToBeStapledAsOfToday: number;
  percentOfGoalStapled: number;
  totalForMonthStapled: number;
  totalForYearStapled: number;
  totalDocumentsScannedToday: number;
  percentOfGoalScanned: number;
  totalForMonthScanned: number;
  totalForYearScanned: number;
};

export enum ReportType {
  Client,
  Investor,
}

export type ClientAndInvestorData = {
  id: number;
  documentCountReadyToBeShipped: number;
  digitalsNotBeingShipped: number;
};

export type ClientData = ClientAndInvestorData & {
  kind: 'client';
  client: string;
};

export type InvestorData = ClientAndInvestorData & {
  kind: 'investor';
  investor: string;
};

export type RowData = ClientData | InvestorData;

export type Shipping = {
  totalDocsShippedToday: number;
  percentThatAreHardCopies: number;
  readyToBeShipped: number;
  digitalsNotBeingShipped: number;
  readyToBeShippedAsOfToday: number;
  digitalExclusionsAsOfToday: number;
  readyForShippingGroupedByClients?: ClientData[];
  readyForShippingGroupedByInvestors?: InvestorData[];
  topFiveInvestors: InvestorData[];
  totalDocsShippedThisMonth: number;
  percentOfMonthlyGoal: number;
  totalYearlyToDate: number;
};

export type ClientSortData = {
  Client: string;
  ReadyToBeSorted: number;
};

export type Sorting = {
  sortedToday: number;
  readyToBeSorted: number;
  readyToBeSortedAsOfToday: number;
  topFiveClients: ClientSortData[];
  totalDocsSortedThisMonth: number;
  percentOfMonthlyGoal: number;
  totalYearly: number;
};
