/** @jsx jsx */
import { jsx } from '@emotion/core';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column, RowDataForExport } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
};

export type RowData = {
  clientId: number;
  company: string;
  integrated: boolean;
  missingTitleCompany: number;
  agedMissingTitleCompany: number;
  agedImportMissingTitleCompany: number;
  missingInvestor: number;
  agedMissingInvestor: number;
  incompleteInvestor: number;
  agedIncompleteInvestor: number;
  pod: string;
};

const columns: Column<RowData>[] = [
  {
    id: 'company',
    label: 'Client',
    sortable: true,
  },
  {
    id: 'pod',
    label: 'Pod',
    sortable: true,
  },
  {
    id: 'integrated',
    label: 'Integrated',
    sortable: true,
    render: (rowData: RowData) => (rowData.integrated ? 'Yes' : 'No'),
  },
  {
    id: 'missingInvestor',
    label: 'Missing Investor',
    sortable: true,
  },
  {
    id: 'agedMissingInvestor',
    label: 'Aged Missing Investor',
    sortable: true,
  },
  {
    id: 'incompleteInvestor',
    label: 'Incomplete Investor',
    sortable: true,
  },
  {
    id: 'agedIncompleteInvestor',
    label: 'Aged Incomplete Investor',
    sortable: true,
  },
  {
    id: 'missingTitleCompany',
    label: 'Missing Title Company',
    sortable: true,
  },
  {
    id: 'agedMissingTitleCompany',
    label: 'Aged Missing Title Company',
    sortable: true,
  },
  {
    id: 'agedImportMissingTitleCompany',
    label: 'Imported Over 30 Days Missing Title Company',
    sortable: true,
  },
];

const customExport = (rows: RowData[]) =>
  rows.map(
    row =>
      ({
        ClientId: row.clientId,
        Pod: row.pod,
        Company: row.company,
        Integrated: row.integrated,
        MissingInvestor: row.missingInvestor,
        AgedMissingInvestor: row.agedMissingInvestor,
        IncompleteInvestor: row.incompleteInvestor,
        AgedIncompleteInvestor: row.agedIncompleteInvestor,
        MissingTitleCompany: row.missingTitleCompany,
        AgedMissingTitleCompany: row.agedMissingTitleCompany,
        AgedImportMissingTitleCompany: row.agedImportMissingTitleCompany,
      } as RowDataForExport),
  );

const MissingData = ({ reportData }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Missing Data Stats"
      columns={columns}
      data={reportData}
      exportFileName="Missing data stats.csv"
      customExport={customExport}
      dense
    />
  </div>
);

export default MissingData;
