/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ClientInvestors as RowData } from './types';
import { Column } from '../../components/ui/DataTableV2/types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Fragment } from 'react';

type Props = {
  investorsPerClient: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'client',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'investors',
    label: 'Investors',
    sortable: true,
    render: ({ investors }) => (
      <Fragment>
        {investors.map(investor => (
          <div>{investor}</div>
        ))}
      </Fragment>
    ),
  },
];

const ClientInvestors = ({ investorsPerClient }: Props) => {
  return (
    <div style={{ maxHeight: '500px', overflow: 'auto' }}>
      <Datatable<RowData>
        title="Active Investors Per Client"
        columns={columns}
        data={investorsPerClient}
        exportFileName="Active Investors Per Client.csv"
        dense
      />
    </div>
  );
};

export default ClientInvestors;
