/** @jsx jsx */
import { jsx } from '@emotion/core';
import { GuildRetainedDocuments as RowData } from './types';
import Link from '@mui/material/Link';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column, RowDataForExport } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
  fromDate: Date;
};

const columns: Column<RowData>[] = [
  {
    id: 'documentId',
    label: 'Barcode',
    sortable: true,
    render: ({ documentId }) => (
      <Link target="_blank" href={`/documents/${documentId}`}>
        {documentId}
      </Link>
    ),
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
    render: ({ loanId, loanNumber }) => (
      <Link target="_blank" href={`/loans/${loanId}`}>
        {loanNumber}
      </Link>
    ),
  },
  {
    id: 'region',
    label: 'Region',
    sortable: true,
  },
  {
    id: 'borrower',
    label: 'Borrower',
    sortable: true,
  },
  {
    id: 'propertyAddress',
    label: 'Property Address',
    sortable: true,
  },
  {
    id: 'state',
    label: 'State',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => dateFunded && new Date(dateFunded).toLocaleDateString(),
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'dateCreated',
    label: 'Date Created',
    sortable: true,
    render: ({ dateCreated, dateFunded }) =>
      dateFunded && new Date(dateCreated).toLocaleDateString(),
  },
];

const customExport = (rows: RowData[]) =>
  rows.map(
    row =>
      ({
        Barcode: row.documentId,
        Region: row.region,
        LoanNumber: row.loanNumber,
        Borrower: row.borrower,
        DateFunded: new Date(row.dateFunded).toLocaleDateString(),
        DateCreated: new Date(row.dateCreated).toLocaleDateString(),
        PropertyAddress: row.propertyAddress,
        State: row.state,
        Investor: row.investor,
        RequiredEndorsements: row.requiredEndorsements,
        Hyperlink: `=HYPERLINK(\"https://internal.docprobe.net/documents/${row.documentId}\")`,
      } as RowDataForExport),
  );

const GuildRetainedDocuments = ({ reportData, fromDate }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Guild Retained Documents"
      columns={columns}
      data={reportData}
      exportFileName="Guild Retained Documents.csv"
      customExport={customExport}
      dense
    />
  </div>
);

export default GuildRetainedDocuments;
