/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useRef, useState } from 'react';
import { LinearProgress, Tooltip } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import GetAppIcon from '@mui/icons-material/GetApp';
import PauseIcon from '@mui/icons-material/Pause';
import CachedIcon from '@mui/icons-material/Cached';
import format from 'date-fns/format';
import { deepOrange, green, grey, red } from '@mui/material/colors';
import Link from '@mui/material/Link';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import Modal from '@mui/material/Modal';
import ImportedLoansModal from './ImportedLoansModal';
import { Column, Filter } from '../ui/DataTableV2/types';
import Datatable, { TableRef } from '../ui/DataTableV2/Datatable';
import RedoIcon from '@mui/icons-material/Redo';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { parseRequestError } from '../../Utils';

enum LoanImportBatchStatus {
  Failed,
  Success,
  Processing,
  Preprocessing,
  NothingToProcess,
}

type RowData = {
  id: number;
  client: string;
  importedErrorsCount: number;
  rejectedCount: number;
  importStatus: LoanImportBatchStatus;
  filePath: string;
  note: string;
  loansInBatch: number;
  importedCount: number;
  createdAt: Date;
  importedBy: string;
  isUpdatingExistingLoans: boolean;
  hasRawDataPath: boolean;
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'dateRange',
    id: 'importDate',
    label: 'Import date',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'importType',
    label: 'Import type',
    options: [
      {
        label: 'New loans',
        value: '0',
        active: false,
      },
      {
        label: 'Updated loans',
        value: '1',
        active: false,
      },
    ],
  },
];

const displayStatusIcon = (importStatus: LoanImportBatchStatus) => {
  if (importStatus === LoanImportBatchStatus.Success) {
    return (
      <Tooltip title="Import Processed Successfully">
        <DoneIcon style={{ color: green[500] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Failed) {
    return (
      <Tooltip title="Failed While Processing">
        <CloseIcon style={{ color: red[800] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Processing) {
    return (
      <Tooltip title="Processing">
        <LinearProgress />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Preprocessing) {
    return (
      <Tooltip title="Waiting To Be Processed">
        <PauseIcon style={{ color: grey[500] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.NothingToProcess) {
    return (
      <Tooltip title="Failed Before Processing">
        <BlockIcon style={{ color: deepOrange[500] }} />
      </Tooltip>
    );
  }

  return <Fragment />;
};

const retryLoanImport = async (batchId: number) =>
  await apiPost('/api/loans/retry-loan-import', { id: batchId });

const ImportInformation = () => {
  const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>(undefined);
  const [viewOnlyImportedLoans, setViewOnlyImportedLoans] = useState<boolean | undefined>();

  const tableRef = useRef({} as TableRef);
  const { successToaster, errorToaster } = useToaster();

  const { mutate: reImport } = useMutation({
    mutationKey: ['retry-loan-import'],
    mutationFn: retryLoanImport,
    onSuccess: () => {
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      successToaster('Importing...');
    },
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  const exportSpreadsheet = async (apiUrl: string, fileName: string) => {
    try {
      const { headers, data } = await apiFetch(apiUrl, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(data as Blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      let fileNameFromServer;
      const fileParts = headers['content-disposition'].match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (fileParts.length >= 2) {
        fileNameFromServer = fileParts[1].replace(/"/g, '');
      }

      a.download = fileNameFromServer || fileName;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Batch id',
      sortable: true,
      render: ({ id, isUpdatingExistingLoans }: RowData) => (
        <div style={{ display: 'flex', gap: '.4rem', alignItems: 'center' }}>
          <span>{id}</span>
          {isUpdatingExistingLoans && (
            <Tooltip title="Updated Loans">
              <CachedIcon color="action" />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      id: 'client',
      label: 'Client',
      sortable: true,
    },
    {
      id: 'importedErrorsCount',
      label: 'Loans imported with errors',
      sortable: true,
    },
    {
      id: 'loansInBatch',
      label: 'Loans in batch',
      sortable: true,
      render: ({ id, hasRawDataPath, loansInBatch }: RowData) => (
        <Fragment>
          {hasRawDataPath ? (
            <Link
              underline="hover"
              onClick={async e => {
                e.preventDefault();
                setSelectedBatchId(id);
                setViewOnlyImportedLoans(false);
              }}
            >
              {loansInBatch}
            </Link>
          ) : (
            loansInBatch
          )}
        </Fragment>
      ),
    },
    {
      id: 'importedCount',
      label: 'Total imported',
      sortable: true,
      render: ({ id, hasRawDataPath, importedCount }: RowData) => (
        <Fragment>
          {hasRawDataPath && importedCount > 0 ? (
            <Link
              underline="hover"
              onClick={async e => {
                e.preventDefault();
                setSelectedBatchId(id);
                setViewOnlyImportedLoans(true);
              }}
            >
              {importedCount}
            </Link>
          ) : (
            importedCount
          )}
        </Fragment>
      ),
    },
    {
      id: 'rejectedCount',
      label: 'Total excluded from import',
      sortable: true,
      render: ({ id, rejectedCount }: RowData) => (
        <Fragment>
          {rejectedCount ? (
            <Link
              underline="hover"
              onClick={async e => {
                e.preventDefault();
                await exportSpreadsheet(
                  `/api/loans/download-rejected-imports?batchId=${id}`,
                  `Rejected Loans From Batch Id ${id}`,
                );
              }}
            >
              {rejectedCount}
            </Link>
          ) : (
            0
          )}
        </Fragment>
      ),
    },
    {
      id: 'importStatus',
      label: 'Import status',
      sortable: true,
      render: ({ importStatus }: RowData) => displayStatusIcon(importStatus),
    },
    {
      id: 'filePath',
      label: 'Original spreadsheet',
      sortable: true,
      render: ({ id, filePath, importedBy }: RowData) =>
        filePath ? (
          <GetAppIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              exportSpreadsheet(
                `/api/loans/download-import-spreadsheet?batchId=${id}&isEncompass=${!importedBy}`,
                filePath,
              )
            }
          />
        ) : (
          <Fragment />
        ),
    },
    {
      id: 'note',
      label: 'Note',
      sortable: true,
      render: ({ note }: RowData) => (
        <Fragment>
          {note?.split('\n').map((notePart, i) => (
            <div key={i}>{notePart}</div>
          ))}
        </Fragment>
      ),
    },
    {
      id: 'createdAt',
      label: 'Import date (EST)',
      sortable: true,
      render: ({ createdAt }: RowData) =>
        createdAt && format(new Date(createdAt), 'MM/dd/yyyy h:mm:ss aaa'),
    },
    {
      id: 'importedBy',
      label: 'Imported by',
      sortable: true,
      render: ({ importedBy }: RowData) => importedBy ?? 'Encompass',
    },
    {
      id: 'actions',
      label: 'Actions',
      render: ({ id, importStatus }: RowData) =>
        importStatus !== LoanImportBatchStatus.Success ? (
          <div className="center-in-parent" style={{ cursor: 'pointer' }}>
            <Tooltip title="Re-import">
              <RedoIcon onClick={() => reImport(id)} />
            </Tooltip>
          </div>
        ) : (
          <Fragment />
        ),
    },
  ];

  return (
    <Fragment>
      <div className="m4">
        <Datatable<RowData>
          title="Loan imports"
          columns={columns}
          filters={filters}
          url="/api/loans/import-information"
          exportUrl="/api/loans/import-information"
          exportFileName="Loan imports.xlsx"
          searchBarPlaceholder="Search by batch id or client name"
          sortConfig={{ field: 'id', direction: 'desc' }}
          ref={tableRef}
        />
      </div>

      <Modal
        open={selectedBatchId !== undefined && viewOnlyImportedLoans !== undefined}
        onClose={() => {
          setSelectedBatchId(undefined);
          setViewOnlyImportedLoans(undefined);
        }}
      >
        <Fragment>
          <ImportedLoansModal
            batchId={selectedBatchId!}
            viewOnlyImportedLoans={viewOnlyImportedLoans!}
          />
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default ImportInformation;
