/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import TitleCompanyInfo from './TitleCompanyInfo';
import LoansList from './LoansList';
import { parseRequestError } from '../../../Utils';
import { useToaster } from '../../../Hooks/toasters';
import useQueueOutstandingLoans from './useQueueOutstandingLoans';
import { apiPost } from '../../../adalConfig';
import Button from '@mui/material/Button';
import { useEffect, useRef } from 'react';
import { TableRef } from '../../ui/DataTableV2/Datatable';

const resolveLoans = async (loanIds: number[]): Promise<void> => {
  await apiPost('/api/call-tasks/resolve-title-portal-outreach-loans', loanIds);
};

const QueueMode = () => {
  const {
    datatableData,
    setDatatableData,
    fetchNextSetOfLoans,
    isLoading,
    loansRemaining,
    localTime,
  } = useQueueOutstandingLoans();
  const { successToaster, errorToaster } = useToaster();
  const tableRef = useRef({} as TableRef);

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationKey: ['title-portal-outreach-resolve', datatableData[0]?.titleCompanyId],
    mutationFn: resolveLoans,
    onSuccess: async () => {
      await fetchNextSetOfLoans();
      successToaster('Successfully updated investor on loans');
    },
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  useEffect(() => {
    if (!tableRef.current?.filtersInitialized) {
      return;
    }

    const { clearFilters } = tableRef.current;
    clearFilters && clearFilters();
  }, [datatableData.map(x => x.id).join(','), tableRef.current?.filtersInitialized]);

  if (!datatableData) {
    return (
      <div className="center-in-parent">
        <CircularProgress disableShrink size={35} />
      </div>
    );
  }

  if (!isLoading && !isUpdating && datatableData.length === 0) {
    return <div className="center-in-parent">No outstanding loans</div>;
  }

  return (
    <div className="m2 df col" style={{ gap: '16px', height: '100%' }}>
      {datatableData.length > 0 && (
        <TitleCompanyInfo
          firstRow={datatableData[0]}
          loansRemaining={loansRemaining}
          localTime={localTime}
          mode="queue"
        >
          <div>
            <Button
              color="primary"
              disabled={loansRemaining > 0}
              onClick={() => mutate(datatableData.filter(r => r.isResolved).map(r => r.id))}
            >
              Resolve
            </Button>
          </div>
        </TitleCompanyInfo>
      )}

      {isLoading || isUpdating ? (
        <div className="center-in-parent">
          <CircularProgress size="35" disableShrink />
        </div>
      ) : (
        <LoansList rows={datatableData} setDatatableData={setDatatableData} ref={tableRef} />
      )}
    </div>
  );
};

export default QueueMode;
