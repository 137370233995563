import React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import { Column, DatatableRow, Sort } from './types';
import { grey } from '@mui/material/colors';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props<Row extends DatatableRow> = {
  column: Column<Row>;
  sort?: Sort;
  onSortChange?: (column: string) => void;
};

const ColumnHeader = <Row extends DatatableRow>({ column, sort, onSortChange }: Props<Row>) => {
  if (!column.sortable || !sort || !onSortChange) {
    return <TableCell sx={{ bgcolor: grey[200], color: '#004455' }}>{column.label}</TableCell>;
  }

  return (
    <TableCell sx={{ bgcolor: grey[200], color: '#004455' }}>
      <TableSortLabel
        hideSortIcon
        active={sort.field === column.id}
        direction={sort.direction}
        onClick={() => onSortChange(column.id)}
      >
        {column.label}
        {sort.field === column.id ? (
          <Box sx={{ ...visuallyHidden }}>
            {sort.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );
};

export default ColumnHeader;
