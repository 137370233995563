/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DigitalDeliveryLog as RowData } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  reportData: RowData[];
};

const columns: Column<RowData>[] = [
  {
    id: 'method',
    label: 'Shipment Method',
    sortable: true,
  },
  {
    id: 'investor',
    label: 'Investor',
    sortable: true,
  },
  {
    id: 'lender',
    label: 'Lender',
    sortable: true,
  },
  {
    id: 'loanNumber',
    label: 'Loan Number',
    sortable: true,
  },
  {
    id: 'investorsLoanNumber',
    label: 'Investor Loan Number',
    sortable: true,
  },
  {
    id: 'borrower',
    label: 'Borrower',
    sortable: true,
  },
  {
    id: 'documentType',
    label: 'Document Type',
    sortable: true,
  },
  {
    id: 'dateFunded',
    label: 'Date Funded',
    sortable: true,
    render: ({ dateFunded }) => (dateFunded ? new Date(dateFunded).toLocaleDateString() : ''),
  },
  {
    id: 'dateProcessed',
    label: 'Date Processed',
    sortable: true,
    render: ({ dateProcessed }) =>
      dateProcessed ? new Date(dateProcessed).toLocaleDateString() : '',
  },
  {
    id: 'dateUploaded',
    label: 'Date Uploaded',
    sortable: true,
    render: ({ dateUploaded }) => (dateUploaded ? new Date(dateUploaded).toLocaleDateString() : ''),
  },
];

const DigitalDeliveryLog = ({ reportData }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title="Digital Delivery Log"
      columns={columns}
      data={reportData}
      exportFileName="Digital Delivery Log.csv"
      dense
    />
  </div>
);

export default DigitalDeliveryLog;
