/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import LoanInformation from '../LoanInformation';
import Panel, { PanelHeader } from '../../../ui/Panel';
import Circle from '../../../ui/Circle';
import { AssociationError, errorType } from '../ImportErrorsUtils';
import UnidentifiedTile from './UnidentifiedTile';
import ActionsBar, { Action } from './ActionsBar';
import { usePostData, usePrevious } from '../../../../Hooks';
import MessageTab from '../../../ui/MessageTab';
import { IconType } from '../../../ui/Icon';

const getCircle = error => (
  <Circle
    styleoverrides={{ border: 'none' }}
    icon={error.type === errorType.title ? IconType.TitleCompany : IconType.Investor}
  />
);

const getError = type => {
  return type.match(/[^A-Z]*/);
};

type ErrorPanelProps = {
  associationError: AssociationError;
  refresh: () => any;
  disablePaginator: (action: boolean) => any;
};
export default function ErrorPanel({
  associationError,
  refresh,
  disablePaginator,
}: ErrorPanelProps) {
  const { doPost: deleteAssociation, isSuccess: deleteSuccess, error: deleteError } = usePostData();
  const prevDeleteSuccess = usePrevious(deleteSuccess);
  const [deletedId, setDeletedId] = useState<number>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { doPost: undoDelete, error: undoError, isSuccess: undoSuccess } = usePostData();
  const { doPost: emailAssociation, error: emailError, isSuccess: emailSuccess } = usePostData();
  const prevEmailSuccess = usePrevious(emailSuccess);
  const [emailedId, setEmailedId] = useState<number>();
  const {
    doPost: incompleteAssociation,
    error: incompleteError,
    isSuccess: incompleteSuccess,
  } = usePostData();
  const prevIncompleteSuccess = usePrevious(incompleteSuccess);
  const [incompleteId, setIncompleteId] = useState<number>();

  useEffect(() => {
    if (deleteSuccess && deleteSuccess !== prevDeleteSuccess) {
      setDeletedId(associationError.id);
      refresh();
      setTimeout(() => {
        setDeletedId(undefined);
      }, 5000);
    }
  }, [deleteSuccess, prevDeleteSuccess, associationError.id, refresh]);

  useEffect(() => {
    if (emailSuccess && emailSuccess !== prevEmailSuccess) {
      setEmailedId(associationError.id);
      refresh();
      setTimeout(() => {
        setEmailedId(undefined);
      }, 5000);
    }
  }, [emailSuccess, prevEmailSuccess, associationError.id, refresh]);

  useEffect(() => {
    if (incompleteSuccess && incompleteSuccess !== prevIncompleteSuccess) {
      setIncompleteId(associationError.id);
      refresh();
      setTimeout(() => {
        setIncompleteId(undefined);
      }, 5000);
    }
  }, [incompleteSuccess, prevIncompleteSuccess, associationError.id, refresh]);

  useEffect(() => {
    if (undoSuccess) {
      refresh();
      setSuccessMessageText('Deleted association error was undone');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoSuccess]);

  useEffect(() => {
    deleteError && setErrorMessageText('There was an error deleting the association error');
  }, [deleteError]);

  useEffect(() => {
    emailError &&
      setErrorMessageText('There was an error adding this association error to the email queue');
  }, [emailError]);

  useEffect(() => {
    undoError && setErrorMessageText('There was an error undoing the deleted association error');
  }, [undoError]);

  useEffect(() => {
    incompleteError &&
      setErrorMessageText('There was an error marking the association error incomplete');
  }, [incompleteError]);

  const setErrorMessageText = text => {
    setErrorMessage(text);
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 5000);
  };

  const setSuccessMessageText = text => {
    setSuccessMessage(text);
    setTimeout(() => {
      setSuccessMessage(undefined);
    }, 5000);
  };

  const onDeleteClick = async () => {
    disablePaginator(true);
    await deleteAssociation(
      `/api/associations/delete${getError(associationError.type)}Association`,
      {
        int: associationError.id,
      },
    );
  };

  const onFlagClick = async () => {
    disablePaginator(true);
    await incompleteAssociation(
      `/api/associations/incomplete${getError(associationError.type)}Association`,
      {
        int: associationError.id,
      },
    );
  };

  const onEmailClick = async () => {
    disablePaginator(true);
    await emailAssociation(`/api/associations/InsertAssociationMissingInfo`, {
      int: associationError.id,
    });
  };

  const onUndoClick = async () => {
    disablePaginator(true);
    setDeletedId(undefined);
    await undoDelete(`/api/associations/undoDeleted${getError(associationError.type)}Association`, {
      int: deletedId,
    });
  };

  return (
    <Fragment>
      <Panel>
        <PanelHeader
          data-test="unidentified header"
          largeText
          iconComponent={getCircle(associationError)}
          text={`Unidentified ${
            associationError.type === errorType.title ? 'Title Company' : 'Investor'
          }`}
        />
        <UnidentifiedTile contactInformation={associationError} isUnidentified />
        <div css={{ display: 'flex', marginTop: 40 }}>
          <LoanInformation
            loanInformation={associationError.associationInformation}
            styles={{ marginTop: 0 }}
            associationId={associationError.id}
            type={associationError.type}
          />
          <ActionsBar
            errorMessage={errorMessage}
            successMessage={successMessage}
            styles={{ display: 'flex' }}
          >
            {associationError.type === errorType.investor && (
              <Action
                disabled={associationError.incomplete}
                icon={IconType.Pencil}
                text="Mark as Incomplete Investor"
                onClick={onFlagClick}
                styles={{
                  backgroundColor: '#f7f7f9',
                  marginRight: '8px',
                  svg: { color: '#0828cc' },
                  '&:hover': { backgroundColor: '#e5e5ea' },
                }}
              />
            )}

            <Action
              icon={IconType.EmailIcon}
              text="Request more Title Company info"
              onClick={onEmailClick}
              styles={{
                backgroundColor: '#f7f7f9',
                marginRight: '8px',
                svg: { color: '#0828cc' },
                '&:hover': { backgroundColor: '#e5e5ea' },
              }}
            />
            <Action
              icon={IconType.TrashCan}
              text="Delete association error"
              onClick={onDeleteClick}
              styles={{
                backgroundColor: '#f7f7f9',
                svg: { color: '#0828cc' },
                '&:hover': { backgroundColor: '#e5e5ea' },
              }}
            />
          </ActionsBar>
        </div>
      </Panel>
      {incompleteId && (
        <Alert
          css={{ left: 400, position: 'fixed', bottom: 32 }}
          severity="success"
          onClose={() => setIncompleteId(undefined)}
        >
          Association successfully marked incomplete.
        </Alert>
      )}
      {deletedId && (
        <MessageTab
          messageText="Association error deleted"
          handleClose={() => setDeletedId(undefined)}
          handleUndo={onUndoClick}
        />
      )}
      {emailedId && (
        <Alert
          css={{ left: 400, position: 'fixed', bottom: 32 }}
          severity="success"
          onClose={() => setEmailedId(undefined)}
        >
          Association successfully added to email queue.
        </Alert>
      )}
    </Fragment>
  );
}
