import React from 'react';
import Collapse from '@mui/material/Collapse';
import { DatatableRow } from './types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';

type Props<Row> = {
  rowData: Row;
  detailsPanel: (rowData: Row) => JSX.Element;
  isRowExpanded: boolean;
  numberOfColumns: number;
};

const ExpandedListItem = <Row extends DatatableRow>({
  rowData,
  detailsPanel,
  isRowExpanded,
  numberOfColumns,
}: Props<Row>) => {
  return (
    <TableRow>
      <TableCell sx={{ p: 0, border: 'none' }} colSpan={numberOfColumns + 1}>
        <Collapse in={isRowExpanded} timeout="auto" unmountOnExit sx={{ bgcolor: grey[200] }}>
          {detailsPanel(rowData)}
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default ExpandedListItem;
