/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useRef, useState } from 'react';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { apiPost } from '../adalConfig';
import { useToaster } from '../Hooks/toasters';
import Link from '@mui/material/Link';
import { DocumentNote, NotesDisplay } from '../components/AccountRepsPage';
import { Column, Filter } from '../components/ui/DataTableV2/types';
import Datatable, { SelectionAction, TableRef } from '../components/ui/DataTableV2/Datatable';
import { parseRequestError } from '../Utils';

type RowData = {
  documentId: number;
  resolutionType: string;
  correctedAt: string;
  correctedBy: string;
  replacementDocumentId?: number;
  replacementDocumentIsHardCopy?: boolean;
  resolutionNote?: string;
  fulfilledBy?: string;
  fulfilledAt?: string;
  shippedAt?: string;
  client: string;
  failedDocumentFormat: string;
  investorName: string;
  dateFunded?: string;
  lastScanForSortDate?: string;
  scannedForSortBy?: string;
  documentNotes: DocumentNote[];
};

const filters: Filter<RowData>[] = [
  {
    filterType: 'radio',
    id: 'pods',
    label: 'Pods',
    options: [
      {
        label: 'All clients',
        value: 0,
        active: true,
      },
      {
        label: 'My team',
        value: 1,
        active: false,
      },
      {
        label: 'Our team',
        value: 2,
        active: false,
      },
      {
        label: 'Hello team',
        value: 3,
        active: false,
      },
      {
        label: 'Your team',
        value: 4,
        active: false,
      },
    ],
  },
  {
    filterType: 'radio',
    id: 'fulfilled',
    label: 'Fulfilled',
    options: [
      {
        label: 'Exclude fulfilled',
        value: 0,
        active: true,
      },
      {
        label: 'Include fulfilled',
        value: 1,
        active: false,
      },
    ],
  },
  {
    filterType: 'checkbox',
    id: 'resolutionType',
    label: 'Resolution type',
    options: [
      {
        label: 'Correction not needed',
        value: 'correctionNotNeeded',
        active: false,
      },
      {
        label: 'Supportive',
        value: 'supportive',
        active: false,
      },
      {
        label: 'Replacement',
        value: 'replacement',
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'clients',
    label: 'Clients',
    multiple: true,
    optionsUrl: '/api/clients/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'investors',
    label: 'Investor',
    multiple: true,
    optionsUrl: '/api/investors/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
];

const CorrectionFulfillment = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);

  const { successToaster, errorToaster } = useToaster();
  const tableRef = useRef({} as TableRef);

  const columns: Column<RowData>[] = [
    {
      id: 'documentId',
      label: 'Document id',
      sortable: true,
      render: ({ documentId }) => (
        <Link target="_blank" href={`documents/${documentId}`}>
          {documentId}
        </Link>
      ),
    },
    {
      id: 'investorName',
      label: 'Investor',
      sortable: true,
    },
    {
      id: 'resolutionType',
      label: 'Resolution type',
      sortable: true,
    },
    {
      id: 'correctedAt',
      label: 'Corrected at',
      sortable: true,
      render: ({ correctedAt }) => correctedAt && new Date(correctedAt).toLocaleDateString(),
    },
    {
      id: 'correctedBy',
      label: 'Corrected by',
      sortable: false,
    },
    {
      id: 'replacementDocumentId',
      label: 'Replacement document id',
      sortable: true,
      render: ({ replacementDocumentId }) => (
        <Link target="_blank" href={`documents/${replacementDocumentId}`}>
          {replacementDocumentId}
        </Link>
      ),
    },
    {
      id: 'replacementDocumentIsHardCopy',
      label: 'Replacement document format',
      sortable: true,
      render: ({ replacementDocumentIsHardCopy }) =>
        replacementDocumentIsHardCopy !== null ? (
          <Fragment>{replacementDocumentIsHardCopy ? 'Physical' : 'Digital'}</Fragment>
        ) : (
          <Fragment />
        ),
    },
    {
      id: 'fulfilledAt',
      label: 'Fulfilled at',
      sortable: true,
      render: ({ fulfilledAt }) => (fulfilledAt ? new Date(fulfilledAt).toLocaleDateString() : ''),
    },
    {
      id: 'shippedAt',
      label: 'Shipped at',
      sortable: false,
      render: ({ shippedAt }) => (shippedAt ? new Date(shippedAt).toLocaleDateString() : ''),
    },
    {
      id: 'lastScanForSortDate',
      label: 'Last scanned for sort',
      sortable: true,
      render: ({ lastScanForSortDate }) =>
        lastScanForSortDate ? new Date(lastScanForSortDate).toLocaleDateString() : '',
    },
    {
      id: 'scannedForSortBy',
      label: 'Scanned by',
      sortable: true,
    },
  ];

  const fulfillDocument = async () => {
    try {
      await apiPost(
        '/api/accountReps/fulfill',
        selectedRows.map(x => x.documentId),
      );

      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      successToaster(`Successfully marked the documents as fulfilled`);
      setIsDialogOpen(false);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(`Failed to fulfill. ${firstError}`);
    }
  };

  const actions = [
    {
      key: 'fulfill',
      tooltip: 'Mark as fulfilled',
      icon: () => <FactCheckIcon style={{ color: 'rgb(76, 175, 80)' }} />,
      onClick: () => setIsDialogOpen(x => !x),
    } as SelectionAction,
  ];

  return (
    <Fragment>
      <div className="m4">
        <Datatable<RowData>
          title="Correction Fulfillment Documents"
          columns={columns}
          filters={filters}
          url="/api/accountReps/correction-fulfillment-documents"
          exportUrl="/api/accountReps/correction-fulfillment-documents"
          exportFileName="Correction fulfillment documents.xlsx"
          searchBarPlaceholder="Search by barcode, loan id, or loan #"
          sortConfig={{ field: 'correctedAt', direction: 'asc' }}
          detailsPanel={DetailPanel}
          dense
          allowMultiSearch
          rowSelectionActions={actions}
          onSelectionChange={rows => setSelectedRows(rows)}
          ref={tableRef}
        />
      </div>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle style={{ cursor: 'move' }}>Fulfill Correction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you want to mark these as fulfilled.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="inherit">
            Cancel
          </Button>
          <Button onClick={fulfillDocument} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CorrectionFulfillment;

const DetailPanel = (rowData: RowData) => (
  <div style={{ margin: '16px 48px' }}>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 2fr',
        gap: '8px',
        marginBottom: '8px',
      }}
    >
      <div className="df col" style={{ gap: '16px' }}>
        <div>
          <b>Client: </b>
          {rowData.client}
        </div>
        <div>
          <b>Date Funded: </b>
          {rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString()}
        </div>
        <div>
          <b>Failed Document Format: </b>
          {rowData.failedDocumentFormat}
        </div>
      </div>
      <div className="df col" style={{ gap: '16px' }}>
        <div>
          <b>Fulfilled By: </b>
          {rowData.fulfilledBy}
        </div>
        <div>
          <b>Resolution Note: </b>
          {rowData.resolutionNote}
        </div>
      </div>
      <div>
        {rowData.documentNotes && (
          <div>
            <b>NOTES:</b>
            <NotesDisplay notes={rowData.documentNotes} />
          </div>
        )}
      </div>
    </div>
  </div>
);
