/* eslint-disable no-nested-ternary,no-console */
/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  AlertTitle,
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import queryString from 'query-string';
import EditIcon from '@mui/icons-material/Edit';
import RotateRightTwoToneIcon from '@mui/icons-material/RotateRightTwoTone';
import Autocomplete from '@mui/material/Autocomplete';
import DoneIcon from '@mui/icons-material/Done';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import LoopIcon from '@mui/icons-material/Loop';
import ButtonGroup from '@mui/material/ButtonGroup';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { QueryObserverSuccessResult, useQuery } from '@tanstack/react-query';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isEmpty } from 'lodash';
import { useImmer } from 'use-immer';
import { Event, EventDisplay, getEventNameMappings } from './Documents/EventDisplay';
import {
  AuditState,
  Client,
  CustomAuditRules,
  Document,
  DocumentAudit,
  DocumentPageDto,
  DocumentType,
  isFailedAudit,
  TitleCompany,
} from '../globalTypes/objects';
import SpinChildren from './ui/SpinChildren';
import { apiFetch, apiPost, fetchWithAuth } from '../adalConfig';
import {
  Failure,
  isLoading,
  isSuccess,
  Loading,
  NotAsked,
  RemoteData,
  Success,
} from '../globalTypes/RemoteData';
import { useClient, useClients } from '../Hooks/useClients';
import { getDocTypeName, Nullable } from '../Utils';
import { DocumentTypeProperties, DocumentTypesContext } from '../DocumentTypesContext';
import CreateMatch from './Documents/CreateMatch';
import DocumentPdfDisplay from './Utilities/DocumentPdfDisplay';
import colors from '../styles/colors';
import ConditionalComponent from './ConditionalComponent';
import useSuggestedDocType from '../Hooks/useSuggestedDocType';
import ManualDataExtractionSection from './ManualDataExtractionSection';
import CustomAuditPanel from './Documents/Audit/CustomAuditPanel';
import ReturnToInbox from './Documents/ReturnToInbox';
import AlertDialogSlide from './Documents/DocumentDeleteAlert';
import VerificationErrorCoversheet, {
  VerificationErrorCoversheetProps,
} from './Documents/Imports/VerificationErrorCoversheet';
import CoversheetPrint from './Documents/Imports/CoversheetPrint';
import icons from './ui/DataTable/Icons';
import { ImportDocsIcon } from './ui/icons';
import NativePdfRenderer from './NativePdfRenderer';
import { useToaster } from '../Hooks/toasters';
import {
  AuditSectionWrap,
  DocAndSidebarWrap,
  EventLogWrap,
  NewmanWrap,
  SidebarWrap,
  TopActionBarWrap,
} from './Documents/documentsViewerStyles';
import StitchedPolicyDisclaimer from './Documents/StitchedPolicyDisclaimer';
import AuditFail, { AuditFailVersion } from './Documents/Audit';
import FailedAuditDisplay from './Documents/Audit/FailedAuditDisplay';
import { getToken } from '../auth-config';
import Alert from '@mui/material/Alert';
import FlagDocumentModal from '../pages/FlagDocuments/FlagDocumentModal';

const useStyles = makeStyles(_theme => ({
  buttonWrapper: {
    marginTop: '2rem',
  },
  closeIcon: {
    position: 'absolute',
    color: '#bcbcbc',
    right: 16,
    top: 16,
    zIndex: 5,
    cursor: 'pointer',
    '& svg': {
      fontSize: 30,
    },
  },
  modalPaper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    backgroundColor: _theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: _theme.shadows[5],
    padding: _theme.spacing(2, 4, 3),
  },
}));

const numberBadge = {
  display: 'inline-flex',
  height: 32,
  alignItems: 'center',
  padding: '0 8px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: 5,
  marginRight: 6,
  color: '#fff',
  fontWeight: 600,
};

const auditSchema = yup.object().shape({
  passed: yup
    .boolean()
    .when(
      ['$documentType', '$newDocumentType', '$clientId', '$newClientId'],
      ([documentType, newDocumentType, clientId, newClientId], schema) => {
        return documentType === newDocumentType && clientId === newClientId
          ? schema.required()
          : schema.notRequired();
      },
    ),
  failureReasons: yup.mixed().when('kind', {
    is: 'FailedAudit',
    then: _ => yup.array().min(1).required(),
    otherwise: _ => yup.mixed().notRequired(),
  }),
});

const documentSchema = yup.object().shape({
  documentId: yup.number().required(),
  clientId: yup.number().required(),
  documentType: yup.number().moreThan(0).required(),
  audit: auditSchema.default(undefined).nullable(),
});

const determinationSchema = documentSchema.concat(
  yup
    .object()
    .shape({
      audit: auditSchema.required(),
    })
    .required(),
);

type DeterminationProps = {
  onNext: () => void;
  doingSkipped: boolean;
  doingPhysicals: boolean;
  doingDigitals: boolean;
  clientId?: number;
  isTitleCompanyUploads?: boolean;
};

type Props = {
  pageData: DocumentPageDto;
  determinationProps?: DeterminationProps;
  pageDataQuery?: QueryObserverSuccessResult<DocumentPageDto | null, Error>;
};

function authHeader(token: string) {
  return { Authorization: `Bearer ${token}` };
}

async function getCustomAuditRules(
  clientId: number,
  docType: DocumentType,
  loanId: number,
): Promise<CustomAuditRules[]> {
  const authToken = await getToken();
  const res = await fetch(
    `/api/CustomAudit/CustomAuditRequirements/${clientId}/${docType}/${loanId}`,
    {
      headers: authHeader(authToken),
    },
  );
  return res.json();
}

async function doSkipDetermination(documentId: number) {
  const url = queryString.stringifyUrl({
    url: '/Api/Documents/Determination/Skip',
    query: { documentId },
  });

  const authToken = await getToken();
  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'PUT',
  });
  return res.status;
}

async function sendReturnToInboxRequest(document: Document) {
  const authToken = await getToken();
  return fetch(`/Api/DocumentEmail/ReturnToInbox`, {
    method: 'POST',
    body: JSON.stringify({ int: document.id }),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

async function sendIrrelevantDocRequest(document: Document, irrelevantType: string) {
  const authToken = await getToken();
  return fetch(`/Api/DocumentEmail/AddIrrelevantDoc`, {
    method: 'POST',
    body: JSON.stringify({ documentId: document.id, irrelevantType }),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

async function sendDeleteDocumentRequest(document: Document) {
  const authToken = await getToken();
  return fetch(`/Api/Documents/DeleteDoc`, {
    method: 'POST',
    body: JSON.stringify({ int: document.id }),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}

async function sendRotatePdfRequest(document: Document, pages?: Set<number>) {
  const authToken = await getToken();
  return fetch(`/Api/Documents/RotateDocumentPdf/${document.id}`, {
    method: 'PUT',
    body: pages ? JSON.stringify(Array.from(pages)) : null,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
}

async function sendSplitPdfRequest(document: Document, pages: Set<number>) {
  const authToken = await getToken();
  return fetch(`/Api/Documents/SplitPdf/${document.id}`, {
    method: 'PUT',
    body: JSON.stringify(Array.from(pages)),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
}

async function sendRemovePagesRequest(document: Document, pages: Set<number>) {
  const authToken = await getToken();
  return fetch(`/Api/Documents/RemovePages/${document.id}`, {
    method: 'PUT',
    body: JSON.stringify(Array.from(pages)),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
  });
}

type Form = {
  documentId: number;
  clientId: number;
  documentType: DocumentType;
  audit: AuditState;
};

function sendFormRequest(form: Form, authToken: string) {
  type FormWithNullableAudit = Nullable<Form, 'audit'>;
  const body: FormWithNullableAudit = {
    ...form,
    audit: form.audit.kind === 'NotAudited' ? null : form.audit,
  };
  console.log({ form });
  return fetch(`/Api/Documents/Determination/Save`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
}

const isDetermination = (
  determinationProps?: DeterminationProps,
): determinationProps is DeterminationProps => !!determinationProps;

type GenericRequest = RemoteData<any, Error>;
type PdfViewRender = 'NATIVE' | 'PDF_JS';

export default function DocumentsPage({ pageData, determinationProps, pageDataQuery }: Props) {
  const {
    document,
    link,
    audit,
    loanInformation,
    verificationFailures,
    verificationNote,
    passedStandardAutomatedAudit,
    internalShipOriginalDocument,
  } = pageData;
  const clients = useClients();
  const documentClient = useClient(document.clientId ?? 0);
  const { docTypes: allDocTypes } = useContext(DocumentTypesContext);
  const [skipReq, setSkipReq] = useState<GenericRequest>(NotAsked);
  const [_deleteDocReq, setDeleteDocReq] = useState<GenericRequest>(NotAsked);
  const [submitFormReq, setSubmitFormReq] = useState<GenericRequest>(NotAsked);
  const [rotatePdfReq, setRotatePdfReq] = useState<GenericRequest>(NotAsked);
  const [splitPdfReq, setSplitPdfReq] = useState<GenericRequest>(NotAsked);
  const [removePagesReq, setRemovePagesReq] = useState<GenericRequest>(NotAsked);
  const [isEventsOpen, setIsEventsOpen] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [editingClient, setEditingClient] = useState<boolean>(false);
  const [editingDocType, setEditingDocType] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [showManualMatchPanel, setShowManualMatchPanel] = useState<boolean>(false);
  const [pdfRenderer, setPdfRenderer] = useState<PdfViewRender>('NATIVE');
  const [pageCount, setPageCount] = useState(0);
  const [pagesSelected, setPagesSelected] = useState<Set<number>>(new Set());
  const docTypePrediction = useSuggestedDocType(pageData?.document?.id);
  const [pdfRenders, setPdfRenders] = useState(0);
  const classes = useStyles();
  const [form, setForm] = useImmer<Form>({
    documentId: document.id,
    documentType: document.documentType ?? DocumentType.Unknown,
    clientId: document.clientId,
    audit: { kind: 'NotAudited' },
  });
  const [showAuditPointsModal, setShowAuditPointsModal] = useState(false);

  const [determinationCount, setDeterminationCount] = useState(0);

  const [inManualExtractionMode] = useQueryParam('performManualExtraction', BooleanParam);

  const { successToaster, errorToaster } = useToaster();

  const [coversheetData, setCoversheetData] = useState<VerificationErrorCoversheetProps>(
    {} as VerificationErrorCoversheetProps,
  );

  const [showFlagModal, setShowFlagModal] = useState(false);

  const { audit: formAudit } = form;
  const printCoversheet = async () => {
    setCoversheetData({
      documentId: document.id,
      borrowerName: loanInformation?.borrower ?? '',
      clientName: loanInformation?.client ?? documentClient?.company ?? '',
      documentTypeDescription: getDocTypeName(document?.documentType ?? 0),
      dateDocumentDrawn:
        loanInformation?.dateFunded ?? document.dateDocumentsDrawn?.toString() ?? '',
      investorName: loanInformation?.investor ?? '',
      notFound: document.loanId === null,
      audit: audit ?? ({} as DocumentAudit),
      accountRep: loanInformation?.clientAccountRep ?? '',
      verificationFailures: verificationFailures ?? [],
      note: verificationNote ?? '',
      titleCompany: { name: loanInformation?.titleCompany } as TitleCompany,
      loanNumber: loanInformation?.appNumber ?? '',
    });
  };

  // Reset audit state when document type is changed
  useEffect(() => {
    setForm(draft => {
      draft.audit = { kind: 'NotAudited' };
    });
  }, [form.documentType, setForm]);

  useEffect(() => {
    if (isEmpty(coversheetData)) return;
    console.log({ coversheetData });
    apiPost(`/Api/Documents/LogReprintCoversheet/${document.id}`, {});
  }, [coversheetData, document, setCoversheetData]);

  const [customAuditRules, setAuditRules] = useState<CustomAuditRules[]>([]);
  const [_isLoadingAuditRules, setIsLoadingAuditRules] = useState(false);

  const { data: eventDisplayNameMapping } = useQuery(
    ['event-name-mappings'],
    getEventNameMappings,
    {
      refetchOnWindowFocus: false,
    },
  );

  const isReadyForAudit = useCallback(
    (doc: Document, theForm: Form): doc is Document & { loanId: number; active: true } => {
      return (
        doc.loanId !== null &&
        doc.active &&
        (theForm.documentType !== DocumentType.Unknown || doc.documentType !== DocumentType.Unknown)
      );
    },
    [],
  );

  useEffect(() => {
    if (!isReadyForAudit(document, form)) return;
    setIsLoadingAuditRules(true);

    getCustomAuditRules(document.clientId, document.documentType, document.loanId).then(
      existingRules => {
        setAuditRules(existingRules);
        setIsLoadingAuditRules(false);
      },
    );
  }, [isReadyForAudit, document, form]);

  // Probably should lift this state out of this component
  // so we don't have to reset everything on a new document.
  useEffect(() => {
    const auditState: AuditState = !pageData.audit
      ? { kind: 'NotAudited' }
      : getAuditState(pageData.audit);

    function getAuditState(documentAudit: DocumentAudit): AuditState {
      if (documentAudit.passed) {
        return {
          kind: 'PassedAudit',
          passed: true,
          documentId: documentAudit.documentId,
        };
      }
      return {
        kind: 'FailedAudit',
        failureReasons: documentAudit.failureReasons,
        notes: documentAudit.notes,
        passed: false,
        failureSensitivity: documentAudit.sensitiveTier,
        documentId: documentAudit.documentId,
      };
    }

    setForm({
      documentId: pageData.document.id,
      clientId: pageData.document.clientId,
      documentType: pageData.document.documentType,
      audit: auditState,
    });

    setSubmitFormReq(NotAsked);
    setDeleteDocReq(NotAsked);
    setSkipReq(NotAsked);
    setRotatePdfReq(NotAsked);
    setSplitPdfReq(NotAsked);
    setRemovePagesReq(NotAsked);
    setEditingClient(false);
    setEditingDocType(false);
    setConfirmDelete(false);
    setShowManualMatchPanel(false);
    setPdfRenderer('NATIVE');
    setPageCount(0);
    setPagesSelected(new Set());
  }, [
    pageData.document.id,
    pageData.document.clientId,
    pageData.document.loanId,
    pageData.document.documentType,
    pageData.audit,
    setForm,
  ]);

  useEffect(() => {
    async function getDeterminationCount() {
      const query: {
        clientId?: string;
        skipped?: string;
        physicals?: string;
        digitals?: string;
        titleCompanyUploads?: string;
      } = {};
      if (determinationProps?.clientId) {
        query.clientId = determinationProps.clientId.toString();
      }
      if (determinationProps?.doingSkipped) {
        query.skipped = '1';
      }
      if (determinationProps?.doingPhysicals) {
        query.physicals = '1';
      }
      if (determinationProps?.doingDigitals) {
        query.digitals = '1';
      }
      if (determinationProps?.isTitleCompanyUploads) {
        query.titleCompanyUploads = 'true';
      }

      const url = queryString.stringifyUrl({
        url: `/api/documents/determination/get-determination-count`,
        query,
      });
      const res = await fetchWithAuth(url, { method: 'GET' });
      try {
        return (await res.json()) as number;
      } catch (e) {
        return 0;
      }
    }

    if (determinationProps) {
      getDeterminationCount()
        .then(setDeterminationCount)
        .catch(e => {
          if (e.response) {
            const errorMessage = e.response.data.split('\n')[0];
            errorToaster(errorMessage || e.message);
          } else {
            errorToaster(e.message);
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [determinationProps]);

  function skipDetermination() {
    setSkipReq(Loading);
    doSkipDetermination(document.id)
      .then(res => setSkipReq(Success(res)))
      .then(() => (isDetermination(determinationProps) ? determinationProps.onNext() : null))
      .catch(e => setSkipReq(Failure(e)));
  }

  async function getEvents() {
    const { data } = await apiFetch<Event[]>(`/api/documents/events-for-document/${document.id}`);
    setEvents(data);
  }

  function returnToInbox() {
    sendReturnToInboxRequest(document)
      .then(async res => {
        if (!res.ok) {
          throw await res.text();
        }
        successToaster('Successfully sent document to client inbox');
        isDetermination(determinationProps) ? determinationProps.onNext() : null;
      })
      .catch(e => {
        errorToaster(
          <div>
            <p>Failed to return to inbox</p>
            <p>{e?.split('\n')[0]}</p>
          </div>,
        );
      });
  }

  function irrelevantDoc(irrelevantType: string) {
    sendIrrelevantDocRequest(document, irrelevantType)
      .then(async res => {
        if (!res.ok) {
          throw await res.text();
        }
        successToaster('Successfully marked document as irrelevant');
        isDetermination(determinationProps) ? determinationProps.onNext() : null;
      })
      .catch(_e => {
        errorToaster('Failed to mark as irrelevant');
      });
  }

  function deleteDocument() {
    setDeleteDocReq(Loading);
    sendDeleteDocumentRequest(document)
      .then(async res => {
        if (!res.ok) {
          throw await res.text();
        }

        return setDeleteDocReq(Success(res));
      })
      .then(() => {
        setConfirmDelete(false);
        successToaster('Successfully deleted the document');
        isDetermination(determinationProps) ? determinationProps.onNext() : null;
      })
      .catch(e => {
        errorToaster(
          <div>
            <p>Failed to delete the document</p>
            <p>{e?.split('\n')[0]}</p>
          </div>,
        );
        setDeleteDocReq(Failure(e));
      });
  }

  async function submitForm() {
    setSubmitFormReq(Loading);
    const authToken = await getToken();
    const response = await sendFormRequest(form, authToken);
    if (!response.ok) {
      const text = await response.text();
      errorToaster(`Failed to save. ${text.split('\n')[0]}`);
      return;
    }

    setSubmitFormReq(Success(response));
    isDetermination(determinationProps) ? determinationProps.onNext() : pageDataQuery?.refetch();
  }

  async function rotateDocumentPdf() {
    setRotatePdfReq(Loading);
    sendRotatePdfRequest(document)
      .then(res => {
        setRotatePdfReq(Success(res));
        setPdfRenders(pdfRenders + 1);
      })
      .catch(e => setRotatePdfReq(Failure(e)));
  }

  function rotateDocumentPdfPages() {
    setRotatePdfReq(Loading);
    sendRotatePdfRequest(document, pagesSelected)
      .then(res => {
        setRotatePdfReq(Success(res));
        setPdfRenders(pdfRenders + 1);
      })
      .catch(e => setRotatePdfReq(Failure(e)));
  }

  async function splitPdf() {
    setSplitPdfReq(Loading);
    try {
      const res = await sendSplitPdfRequest(document, pagesSelected);
      const blob = await res.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const a = window.document.createElement('a');
      a.href = downloadUrl;
      a.download = `from_${document.id}.pdf`;
      window.document.body.appendChild(a);
      a.click();
      setSplitPdfReq(Success(res.status));
    } catch (e) {
      setSplitPdfReq(Failure(e));
    }
  }

  async function removePages() {
    setRemovePagesReq(Loading);
    try {
      const res = await sendRemovePagesRequest(document, pagesSelected);
      setRemovePagesReq(Success(res));
      setPdfRenders(pdfRenders + 1);
    } catch (e) {
      setRemovePagesReq(Failure(e));
    }
  }

  function togglePagesSelected(pageNum: number) {
    setPagesSelected(ps => {
      const pagesSelectedCopy = new Set(ps);
      if (!pagesSelectedCopy.has(pageNum)) {
        pagesSelectedCopy.add(pageNum);
      } else {
        pagesSelectedCopy.delete(pageNum);
      }
      return pagesSelectedCopy;
    });
  }

  const chipTextDisplay = (): string => {
    if (!determinationProps) {
      return 'All Remaining Docs';
    }
    if (determinationProps.clientId) {
      if (determinationProps.doingPhysicals) {
        return `Remaining Physicals - ${documentClient?.company}`;
      }
      if (determinationProps.doingDigitals) {
        return `Remaining Digitals - ${documentClient?.company}`;
      }
      return `All Remaining Docs - ${documentClient?.company}`;
    }
    if (determinationProps.doingSkipped) {
      return 'Skipped - Remaining Docs';
    }
    if (determinationProps.doingPhysicals) {
      return 'Physicals - Remaining Docs';
    }
    if (determinationProps.doingDigitals) {
      return 'Digitals - Remaining Docs';
    }
    if (determinationProps.isTitleCompanyUploads) {
      return 'Title Company Uploads - Remaining Docs';
    }

    return 'All Remaining Docs';
  };

  // don't include the "Unknown" doc type
  const docTypes = allDocTypes.filter(dt => dt.value > 0);

  const missingDocType = !document.documentType || document.documentType < 0;
  const docNeedsInfo = missingDocType || !document.clientId || !audit;
  const schema = isDetermination(determinationProps) ? determinationSchema : documentSchema;
  const allPagesSelected = pagesSelected.size > 0 && pagesSelected.size === pageCount;
  const editPdfLoading =
    isLoading(splitPdfReq) || isLoading(rotatePdfReq) || isLoading(removePagesReq);

  // noinspection XmlDeprecatedElement
  return (
    <div>
      {!isEmpty(coversheetData) && (
        <Fragment>
          {!coversheetData.verificationFailures.length ? (
            <CoversheetPrint
              {...coversheetData}
              afterPrintCallback={() => setCoversheetData({} as VerificationErrorCoversheetProps)}
            />
          ) : (
            <div css={{ display: 'none', '@media print': { display: 'block' } }}>
              <VerificationErrorCoversheet {...coversheetData} />
            </div>
          )}
        </Fragment>
      )}

      {!document.active && (
        <Card
          css={{
            display: 'inline-block',
            marginLeft: 24,
            marginTop: 24,
            fontSize: 18,
          }}
        >
          <CardContent css={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon className="mr1" />
            This document is inactive and can only be viewed.
          </CardContent>
        </Card>
      )}

      <TopActionBarWrap>
        {document.active && (
          <Button
            onClick={() => setPdfRenderer(r => (r === 'NATIVE' ? 'PDF_JS' : 'NATIVE'))}
            variant="text"
            color="primary"
          >
            Change PDF Display
          </Button>
        )}
        {document.active && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => rotateDocumentPdf()}
            disabled={isLoading(rotatePdfReq)}
            style={{ marginLeft: 16 }}
          >
            {!isLoading(rotatePdfReq) && <RotateRightTwoToneIcon />}
            {isLoading(rotatePdfReq) && (
              <span>
                <CircularProgress size={24} />
              </span>
            )}
          </Button>
        )}
        <Button
          onClick={async () => {
            events.length === 0 && (await getEvents());
            setIsEventsOpen(!isEventsOpen);
          }}
          variant="outlined"
          color="primary"
          style={{ marginLeft: 16 }}
        >
          View Document Events
        </Button>
        {document.active && (
          <Button
            variant="outlined"
            color="primary"
            disabled={!document.hardCopy}
            style={{ marginLeft: 16, height: 33 }}
            onClick={printCoversheet}
          >
            <span>
              <ImportDocsIcon size={24} />
            </span>
          </Button>
        )}
        {document.active && (
          <div
            css={{
              width: 475,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginLeft: 25,
            }}
          >
            <div>
              Document: <Chip label={document.id} />
            </div>
            <ConditionalComponent display={isDetermination(determinationProps)}>
              <div css={{ marginLeft: 15 }}>
                {chipTextDisplay()}: <Chip label={determinationCount} />
              </div>
            </ConditionalComponent>
          </div>
        )}
      </TopActionBarWrap>
      {isEventsOpen && (
        <EventLogWrap>
          <div className="inner_wrap">
            <div className="gridder">
              <span className={classes.closeIcon} onClick={() => setIsEventsOpen(false)}>
                <icons.Close />
              </span>
              {events.map(docEvent => EventDisplay({ docEvent, clients, eventDisplayNameMapping }))}
            </div>
          </div>
        </EventLogWrap>
      )}
      {document && (
        <DocAndSidebarWrap makeBig={showAuditPointsModal}>
          {!link && (
            <div style={{ width: 1000, display: 'flex', justifyContent: 'center' }}>
              <h2>This document has not been scanned yet.</h2>
            </div>
          )}
          <Global
            styles={{
              '.main_layout_wrap_overridde': {
                zIndex: showAuditPointsModal ? 550 : undefined,
              },
            }}
          />
          <NewmanWrap
            className={showAuditPointsModal && isFailedAudit(formAudit) ? 'newman-is-the-bomb' : ''}
          >
            {link && pdfRenderer === 'NATIVE' && (
              <NativePdfRenderer link={link} rerender={pdfRenders} />
            )}
            {document.fileSubpath && pdfRenderer === 'PDF_JS' && (
              <DocumentPdfDisplay
                refreshPdf={pdfRenders}
                pageCount={pageCount}
                setPageCount={setPageCount}
                docReference={document.id}
                showPageNumbers
                pagesSelected={pagesSelected}
                onPageSelectChange={pageNum => togglePagesSelected(pageNum)}
              />
            )}
            {showAuditPointsModal && isFailedAudit(formAudit) && (
              <AuditFail
                closeModal={() => setShowAuditPointsModal(false)}
                saveFailedAuditData={(failedReasons, sensitiveTier) => {
                  setForm(formDraft => {
                    if (!isFailedAudit(formDraft.audit)) return;
                    formDraft.audit.failureReasons = failedReasons;
                    formDraft.audit.failureSensitivity = sensitiveTier;
                  });
                  setShowAuditPointsModal(false);
                }}
                docType={form.documentType}
                version={AuditFailVersion.panel}
                alreadySelectedFailures={formAudit.failureReasons}
              />
            )}
          </NewmanWrap>
          {/* SIDEBAR */}
          <SidebarWrap>
            <div className="tile_wrap">
              <div className="inner-wrap_jhk">
                <div>
                  <h3>Format: {document.hardCopy ? 'Physical' : 'Digital'}</h3>
                </div>

                {/* SKIP DETERMINATION SECTION */}
                {isDetermination(determinationProps) &&
                  !determinationProps.doingSkipped &&
                  !determinationProps.isTitleCompanyUploads && (
                    <div css={{ position: 'relative' }}>
                      <div css={{ display: 'flex', position: 'absolute', top: -35, right: -10 }}>
                        <Button
                          onClick={() => skipDetermination()}
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled={isLoading(skipReq)}
                        >
                          Skip
                        </Button>
                      </div>
                    </div>
                  )}

                {/* CLIENT SECTION */}
                <div css={{ marginTop: '16px' }}>
                  {internalShipOriginalDocument !== null && (
                    <div style={{ width: '320px', marginBottom: '8px' }}>
                      <Alert severity="warning">
                        <AlertTitle>Client Change Alert</AlertTitle>
                        This document replaces barcode{' '}
                        <Link target="_blank" href={`/documents/${internalShipOriginalDocument}`}>
                          {internalShipOriginalDocument}
                        </Link>
                        , which has been marked as internally shipped.
                      </Alert>
                    </div>
                  )}
                  {document.clientId && !editingClient ? (
                    <div css={{ display: 'flex' }}>
                      <h3>Client: {clients.find(c => c.id === document.clientId)?.company}</h3>
                      {document.active && !document.loanId && (
                        <span
                          css={{ cursor: 'pointer', marginLeft: '20px' }}
                          onClick={() => setEditingClient(true)}
                        >
                          <EditIcon />
                        </span>
                      )}
                    </div>
                  ) : (
                    <FormControl disabled={!!document?.clientId} css={{ width: '100%' }}>
                      <Autocomplete<Client>
                        options={clients}
                        renderOption={(props: React.HTMLAttributes<HTMLElement>, client) => (
                          <div key={client.id} {...props}>
                            {client?.company ?? ''}
                          </div>
                        )}
                        getOptionLabel={client => client?.company ?? ''}
                        onChange={(_event, val) => {
                          if (val !== null) {
                            setForm(formDraft => {
                              formDraft.clientId = val.id;
                            });
                          }
                        }}
                        renderInput={params => (
                          <TextField {...params} variant="outlined" label="Search For Client" />
                        )}
                      />
                    </FormControl>
                  )}
                </div>

                {/* Document Type Section */}
                <div css={{ marginTop: '16px' }}>
                  {!missingDocType && !editingDocType && (
                    <div css={{ display: 'flex' }}>
                      <h3>
                        Document Type: {getDocTypeName(document.documentType as DocumentType)}
                      </h3>
                      {document.active && (
                        <span
                          css={{ cursor: 'pointer', marginLeft: '20px' }}
                          onClick={() => setEditingDocType(true)}
                        >
                          <EditIcon htmlColor={document.loanId ? 'red' : ''} />
                        </span>
                      )}
                    </div>
                  )}
                  {(missingDocType || editingDocType) && document.active && (
                    <Fragment>
                      <FormControl disabled={!missingDocType} css={{ width: '100%' }}>
                        <Autocomplete<DocumentTypeProperties>
                          isOptionEqualToValue={(option, value) => {
                            return option.value === value.value;
                          }}
                          options={docTypes}
                          renderOption={(props: React.HTMLAttributes<HTMLElement>, docType) => (
                            <div key={docType.value} {...props}>
                              {docType.label}
                            </div>
                          )}
                          getOptionLabel={docType => docType.label}
                          onChange={(_event, docType) => {
                            setForm(formDraft => {
                              if (docType) formDraft.documentType = docType.value;
                            });
                          }}
                          renderInput={params => (
                            <TextField {...params} variant="outlined" label="Search For DocType" />
                          )}
                        />
                      </FormControl>
                      {isSuccess(docTypePrediction) &&
                        (docTypePrediction.data.prediction === DocumentType.Unknown ? (
                          <div>Document type suggestion unavailable.</div>
                        ) : (
                          <div
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                              setForm({
                                ...form,
                                documentType: docTypePrediction.data.prediction as DocumentType,
                              });
                            }}
                          >
                            Suggested DocType: {getDocTypeName(docTypePrediction.data.prediction)}
                          </div>
                        ))}
                    </Fragment>
                  )}
                  {document.documentType === DocumentType.Policy && (
                    <StitchedPolicyDisclaimer docId={document.id} />
                  )}
                </div>

                {loanInformation?.appNumber && (
                  <div css={{ marginTop: '16px', display: 'flex', alignItems: 'center' }}>
                    <h3 css={{ marginRight: 4 }}>Loan Number: </h3>
                    <Link target="_blank" href={`/loans/${document.loanId}`}>
                      {loanInformation?.appNumber}
                    </Link>
                  </div>
                )}

                {loanInformation?.investor && (
                  <div css={{ marginBottom: '2rem', display: 'flex', alignItems: 'center' }}>
                    <h3 css={{ marginRight: 4 }}>Investor: </h3>
                    <Link target="_blank" href={`/investors/${loanInformation.investorId}`}>
                      {loanInformation?.investor}
                    </Link>
                  </div>
                )}

                {/* AUDIT SECTION */}

                {customAuditRules.length !== 0 && (
                  <CustomAuditPanel
                    rules={customAuditRules}
                    passedStandardAudit={passedStandardAutomatedAudit}
                  />
                )}
                {audit && audit.passed && (
                  <div
                    css={{
                      alignItems: 'center',
                      color: 'green',
                      display: 'flex',
                      fontSize: 18,
                      justifyContent: 'center',
                      marginTop: 16,
                      overflow: 'hidden',
                      position: 'relative',
                      textTransform: 'uppercase',
                      '&:before': {
                        content: '""',
                        borderTop: '2px solid green',
                        height: 1,
                        left: '-20px',
                        position: 'absolute',
                        width: 500,
                        zIndex: 2,
                      },
                    }}
                  >
                    <span
                      css={{
                        backgroundColor: '#fff',
                        fontWeight: 500,
                        padding: '0 10px',
                        position: 'relative',
                        zIndex: 6,
                      }}
                    >
                      Passed Audit
                    </span>
                  </div>
                )}
                {audit && audit.failureReasons.length > 0 && (
                  <FailedAuditDisplay failedReasons={audit.failureReasons} />
                )}

                <div>
                  {!audit && isReadyForAudit(document, form) && (
                    <AuditSectionWrap>
                      <FormControl>
                        <FormLabel>Audit</FormLabel>
                        <div css={{ padding: '8px 16px' }}>
                          <div className="df aic jcsb">
                            <RadioGroup
                              row
                              value={
                                form.audit?.kind === 'NotAudited'
                                  ? ''
                                  : form.audit?.passed
                                  ? 'pass'
                                  : 'fail'
                              }
                              onChange={(_e, val) =>
                                setForm(formDraft => {
                                  if (val === 'pass') {
                                    formDraft.audit = {
                                      kind: 'PassedAudit',
                                      documentId: document.id,
                                      passed: true,
                                    };
                                  } else {
                                    formDraft.audit = {
                                      kind: 'FailedAudit',
                                      passed: false,
                                      failureReasons: [],
                                      failureSensitivity: null,
                                      notes: '',
                                      documentId: document.id,
                                    };
                                  }
                                })
                              }
                            >
                              <FormControlLabel
                                value="pass"
                                control={<Radio color="primary" />}
                                label="Pass"
                              />
                              <FormControlLabel
                                value="fail"
                                control={<Radio color="primary" />}
                                label="Fail"
                              />
                            </RadioGroup>
                            {isFailedAudit(form.audit) && (
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => setShowAuditPointsModal(true)}
                              >
                                Choose Reasons
                              </Button>
                            )}
                          </div>
                        </div>
                        {isFailedAudit(formAudit) && formAudit.failureReasons.length > 0 && (
                          <FailedAuditDisplay failedReasons={formAudit.failureReasons} />
                        )}
                      </FormControl>
                    </AuditSectionWrap>
                  )}
                </div>

                {/* SAVE BUTTON SECTION  */}
                {document.active && (
                  <div>
                    <div
                      css={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {(docNeedsInfo || editingClient || editingDocType) && (
                        <div css={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
                          <div css={{ display: 'flex' }}>
                            <Button
                              onClick={() => submitForm()}
                              variant="contained"
                              color="primary"
                              size="large"
                              disabled={
                                isLoading(submitFormReq) ||
                                !schema.isValidSync(form, {
                                  context: {
                                    documentType: document.documentType,
                                    newDocumentType: form.documentType,
                                    clientId: document.clientId,
                                    newClientId: form.clientId,
                                  },
                                })
                              }
                            >
                              {isDetermination(determinationProps) ? `Save And Load Next` : 'Save'}
                            </Button>

                            {isSuccess(submitFormReq) && (
                              <div css={{ alignSelf: 'center', marginLeft: '.5rem' }}>
                                <DoneIcon color="primary" fontSize="large" />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      css={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {(docNeedsInfo || editingClient || editingDocType) &&
                        isDetermination(determinationProps) && (
                          <div
                            css={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}
                          >
                            <div css={{ display: 'flex' }}>
                              <Button
                                onClick={() => setShowFlagModal(true)}
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                  form.audit.kind !== 'PassedAudit' ||
                                  isLoading(submitFormReq) ||
                                  !schema.isValidSync(form, {
                                    context: {
                                      documentType: document.documentType,
                                      newDocumentType: form.documentType,
                                      clientId: document.clientId,
                                      newClientId: form.clientId,
                                    },
                                  })
                                }
                              >
                                Flag
                              </Button>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                )}

                {!isDetermination(determinationProps) &&
                  !document.loanId &&
                  !missingDocType &&
                  document.active && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setShowManualMatchPanel(true)}
                      className={classes.buttonWrapper}
                    >
                      Manually Match
                    </Button>
                  )}

                {/* DELETE DOCUMENT SECTION */}
                {!document.loanId && document.active && (
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      marginTop: 16,
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="large"
                      color="error"
                      onClick={() => setConfirmDelete(true)}
                    >
                      Delete Document
                    </Button>
                    <AlertDialogSlide
                      isOpen={confirmDelete}
                      handleClose={() => setConfirmDelete(false)}
                      disableConfirm={false}
                      onConfirm={() => deleteDocument()}
                      confirmationHeader="Delete Document"
                      confirmationText="Are you sure you want to delete this document? Have you looked at the whole thing?"
                    />
                  </div>
                )}
                {document.active && (
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <ReturnToInbox
                      docId={document.id}
                      disabled={false}
                      color="error"
                      onConfirm={() => returnToInbox()}
                      onIrrelevantDocConfirm={irrelevantType => irrelevantDoc(irrelevantType)}
                      showDelete={false}
                    />
                  </div>
                )}
                {/* DOWNLOAD OR REMOVE PAGES */}
                {pagesSelected.size > 0 && (
                  <div
                    css={[
                      editPdfLoading && { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                      {
                        border: `1px solid ${colors.grayLight}`,
                        margin: '24px 0 16px',
                        padding: 16,
                        borderRadius: 5,
                        position: 'relative',
                      },
                    ]}
                  >
                    {editPdfLoading && (
                      <SpinChildren displayLocalOverlay>
                        <LoopIcon color="primary" fontSize="large" />
                      </SpinChildren>
                    )}

                    <div className="mb2 bold">Edit Selected Pages:</div>
                    <div>
                      {Array.from(pagesSelected)
                        .sort()
                        .map(page => (
                          <span key={page} css={numberBadge}>
                            {page}
                          </span>
                        ))}
                    </div>

                    <div
                      className="mt3"
                      css={{
                        textAlign: 'center',
                        button: { whiteSpace: 'nowrap', padding: '8px 12px' },
                      }}
                    >
                      <ButtonGroup orientation="vertical" color="primary" style={{ width: '80%' }}>
                        <Button onClick={() => splitPdf()}>
                          {`Download Page${pagesSelected.size > 1 ? 's' : ''}`}
                        </Button>
                        <Button onClick={() => rotateDocumentPdfPages()}>
                          {`Rotate Page${pagesSelected.size > 1 ? 's' : ''}`}
                        </Button>
                        <Button
                          disabled={allPagesSelected}
                          color="error"
                          onClick={() => removePages()}
                        >
                          {`Remove Page${pagesSelected.size > 1 ? 's' : ''}`}
                        </Button>
                      </ButtonGroup>
                      {allPagesSelected && (
                        <div css={{ color: 'red' }}>
                          All pages cannot be removed from a document
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {inManualExtractionMode && <ManualDataExtractionSection documentId={document.id} />}
          </SidebarWrap>
        </DocAndSidebarWrap>
      )}

      {showManualMatchPanel && documentClient && (
        <div css={{ paddingLeft: 32, paddingBottom: 32 }}>
          <CreateMatch
            docId={document.id}
            client={documentClient}
            handleClose={() => {
              setShowManualMatchPanel(false);
              if (pageDataQuery) pageDataQuery.refetch();
            }}
          />
        </div>
      )}

      {showFlagModal && (
        <FlagDocumentModal
          setModalOpen={setShowFlagModal}
          flagInformation={{
            clientId: document?.clientId,
            documentType: document?.documentType,
            loanNumber: loanInformation?.appNumber,
            address: loanInformation?.propertyAddress,
            borrower: loanInformation?.borrower,
          }}
        />
      )}
    </div>
  );
}
