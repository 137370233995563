/** @jsx jsx */
import { jsx } from '@emotion/core';
import { AggregateProcessingStatsRowData as RowData } from './types';
import Datatable from '../../components/ui/DataTableV2/Datatable';
import { Column } from '../../components/ui/DataTableV2/types';

type Props = {
  aggregateProcessingStats: RowData[];
  client: string;
};

const columns: Column<RowData>[] = [
  {
    id: 'event',
    label: 'Event',
    sortable: true,
  },
  {
    id: 'daily',
    label: 'Daily',
    sortable: true,
    render: ({ daily }) => daily.toLocaleString(),
  },
  {
    id: 'weekToDate',
    label: 'Week To Date',
    sortable: true,
    render: ({ weekToDate }) => weekToDate.toLocaleString(),
  },
  {
    id: 'monthToDate',
    label: 'Month To Date',
    sortable: true,
    render: ({ monthToDate }) => monthToDate.toLocaleString(),
  },
  {
    id: 'yearToDate',
    label: 'Year To Date',
    sortable: true,
    render: ({ yearToDate }) => yearToDate.toLocaleString(),
  },
];

const AggregateProcessingStats = ({ aggregateProcessingStats, client }: Props) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    <Datatable<RowData>
      title={`Aggregate Processing Stats - ${client ?? 'All Clients'}`}
      columns={columns}
      data={aggregateProcessingStats}
      exportFileName={`Aggregate Processing Stats - ${client ?? 'All Clients'}.csv`}
      dense
    />
  </div>
);

export default AggregateProcessingStats;
