/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { Client, Loan } from '../../globalTypes/objects';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import LoanDropdown from '../../components/Utilities/Dropdowns/LoanDropdown';
import { parseRequestError } from '../../Utils';

type NewClosingPackage = {
  clientId: number;
  loanNumber: string;
  propertyAddress: string;
  borrowerName: string;
  titleCompanyName: string;
  inboundTrackingNumber: string;
  outboundTrackingNumber: string;
  shippingCost: string;
  note: string;
};

type Props = {
  onClose: () => void;
  setHasPartialData: Dispatch<SetStateAction<boolean>>;
};

export default function AddClosingPackage({ onClose, setHasPartialData }: Props) {
  const [loan, setLoan] = useState<Loan | null>(null);
  const [address, setAddress] = useState<string>('');
  const [borrower, setBorrower] = useState<string>('');
  const [titleCompanyName, setTitleCompanyName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [loanNumber, setLoanNumber] = useState<string>('');
  const [inboundTracking, setInboundTracking] = useState<string>('');
  const [outboundTracking, setOutboundTracking] = useState<string>('');
  const [shippingCost, setShippingCost] = useState<string>('');
  const [client, setClient] = useState<Client | null>(null);
  const [newClosingPackage, setNewClosingPackage] = useState<NewClosingPackage | null>(null);
  const [isValidForSubmit, setIsValidForSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (
      client?.id ||
      loanNumber ||
      inboundTracking ||
      outboundTracking ||
      shippingCost ||
      titleCompanyName ||
      address ||
      borrower
    ) {
      setHasPartialData(true);
    } else {
      setHasPartialData(false);
    }

    if (
      client?.id &&
      loanNumber &&
      inboundTracking &&
      outboundTracking &&
      shippingCost &&
      titleCompanyName &&
      address &&
      borrower
    ) {
      setIsValidForSubmit(true);
    }

    const newClosingPackage: NewClosingPackage = {
      clientId: client?.id ?? 0,
      loanNumber,
      inboundTrackingNumber: inboundTracking,
      outboundTrackingNumber: outboundTracking,
      shippingCost,
      titleCompanyName,
      propertyAddress: address,
      borrowerName: borrower,
      note,
    };

    setNewClosingPackage(newClosingPackage);
  }, [
    setHasPartialData,
    client,
    loanNumber,
    address,
    borrower,
    titleCompanyName,
    inboundTracking,
    outboundTracking,
    shippingCost,
    note,
  ]);

  const [status, setStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { errorToaster, successToaster } = useToaster();

  const add = async () => {
    if (newClosingPackage) {
      setStatus(StatusTypes.loading);
      await apiPost(`/api/closingPackages/addClosingPackage`, newClosingPackage);
    }
  };

  const addClosingPackage = () => {
    add()
      .then(() => {
        setStatus(StatusTypes.success);
        successToaster('Closing package added');
      })
      .catch(e => {
        const firstError = parseRequestError(e)[0];
        errorToaster(firstError);

        setStatus(StatusTypes.error);
      })
      .finally(() => onClose());
  };

  return (
    <Fragment>
      <div css={{ margin: 40, maxWidth: 1300 }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Panel>
            <PanelHeader text="Add Closing Package" />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div css={{ marginTop: 8, marginBottom: 8 }}>
                <ClientSearch onChange={c => setClient(c)} selectedClient={0} />
              </div>

              <div className="df jcsb aic full-width">
                <div>
                  <LoanDropdown
                    loan={loan ?? ({} as Loan)}
                    client={client}
                    onChange={async selection => {
                      setLoan(selection.value || {});
                      if (selection.value) {
                        setLoanNumber(selection.value?.loanNumber);
                        setAddress(selection.value?.propertyAddress);
                        setBorrower(selection.value?.borrower);
                        const { data: titleCo } = await apiFetch(
                          `/api/titleCompanies/get?id=${selection.value?.titleCompanyID}`,
                        );
                        setTitleCompanyName(titleCo.name);
                      }
                    }}
                    includeBorrower
                    includeDate
                    includeLoanNumber
                    includeAddress
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Inbound Tracking #"
                    name="inboundTackingNumber"
                    value={inboundTracking}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setInboundTracking(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Loan Number"
                    name="loanNumber"
                    value={loanNumber}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setLoanNumber(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setAddress(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Borrower"
                    name="borrower"
                    value={borrower}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setBorrower(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: '100%' }}>
                  <TextInput
                    placeholder="Title Company Name"
                    name="titleCompanyName"
                    value={titleCompanyName}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setTitleCompanyName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Outbound Tracking Number"
                    name="outboundTracking"
                    value={outboundTracking}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setOutboundTracking(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Shipping Cost"
                    name="shippingCost"
                    value={shippingCost}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      const { value } = e.target;

                      // Regular expression to match a valid decimal number
                      const decimalPattern = /^\d*\.?\d{0,2}$/;

                      if (decimalPattern.test(value)) {
                        setShippingCost(value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="df jcsb aife mb1">
                <div css={{ width: '100%' }}>
                  <TextInput
                    placeholder="Note"
                    name="note"
                    value={note}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => setNote(e.target.value)}
                  />
                </div>
              </div>
              <div css={{ display: 'flex' }}>
                <div css={{ paddingRight: 10 }}>
                  <StatusButton onClick={onClose} text="Cancel" />
                </div>
                <StatusButton
                  status={status}
                  onClick={addClosingPackage}
                  text="Add"
                  disabled={!isValidForSubmit}
                />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </Fragment>
  );
}
