/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DropdownListItem, RowData, SidebarOptions } from '../types';
import { actions, customExport, formatNotes, sortDataByPhoneAndClient } from '../Datatable';
import { roleTypes } from '../../../constants';
import { Dispatch, Fragment, SetStateAction, useContext, useMemo } from 'react';
import { poBoxDisplay } from '../../../pages/CallCenter';
import colors from '../../../styles/colors';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { AuthContext } from '../../AuthContext';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import differenceInDays from 'date-fns/differenceInDays';
import MaterialTable from '../../ui/DataTableV2/Datatable';
import { Column, Filter } from '../../ui/DataTableV2/types';
import DispositionsContext from '../DispositionsContext';
import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../../../adalConfig';
import { Pod } from '../../../globalTypes/objects';
import { useClients } from '../../../Hooks/useClients';

const useStyles = makeStyles(() => ({
  linkStyle: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  dataTableData: RowData[];
  selectedRows: RowData[];
  setSelectedRows: Dispatch<SetStateAction<RowData[]>>;
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
  setRowOfSelectedNote: Dispatch<SetStateAction<RowData | null>>;
};

const getPods = async () => {
  const { data } = await apiFetch<Pod[]>('/api/clients/pods');
  return data;
};

const getTitleCompanies = async () => {
  const { data } = await apiFetch<DropdownListItem[]>(
    '/api/titleCompanies/title-company-dropdown-data',
  );
  return data;
};

const DataTable = ({
  dataTableData,
  selectedRows,
  setSelectedRows,
  setSidebarOption,
  setRowOfSelectedNote,
}: Props) => {
  const { roles } = useContext(AuthContext);
  const classes = useStyles();
  const dispositions = useContext(DispositionsContext);
  const clients = useClients();

  const { data: pods } = useQuery(['pods'], getPods, {
    refetchOnWindowFocus: false,
  });

  const { data: titleCompanies } = useQuery(['title-companies-dropdown'], getTitleCompanies, {
    refetchOnWindowFocus: false,
  });

  const filters = useMemo(
    () =>
      [
        {
          filterType: 'dropdown',
          id: 'dispositions',
          label: 'Dispositions',
          multiple: true,
          filterData: (rows, filterValue) => {
            if (!filterValue) {
              return rows;
            }

            if (!Array.isArray(filterValue)) {
              return rows;
            }

            return rows.filter(
              ({ latestDisposition }) =>
                filterValue.findIndex(x => x === latestDisposition?.id) !== -1,
            );
          },
          options: dispositions
            .filter(x => x.stage === 3)
            .map(disposition => ({
              label: disposition.reason,
              value: disposition.id,
              active: false,
            })),
        },
        {
          filterType: 'dropdown',
          id: 'pods',
          label: 'Pods',
          multiple: true,
          filterData: (rows, filterValue) => {
            if (!filterValue) {
              return rows;
            }

            if (!Array.isArray(filterValue)) {
              return rows;
            }

            return rows.filter(
              ({ podNumber }) => filterValue.findIndex(x => x === podNumber) !== -1,
            );
          },
          options: (pods || []).map(pod => ({
            label: pod.name,
            value: pod.id,
            active: false,
          })),
        },
        {
          filterType: 'dropdown',
          id: 'clients',
          label: 'Clients',
          multiple: true,
          filterData: (rows, filterValue) => {
            if (!filterValue) {
              return rows;
            }

            if (!Array.isArray(filterValue)) {
              return rows;
            }

            return rows.filter(
              ({ clientInfo }) => filterValue.findIndex(x => x === clientInfo.id) !== -1,
            );
          },
          options: clients.map(client => ({
            label: client.company,
            value: client.id,
            active: false,
          })),
        },
        {
          filterType: 'dropdown',
          id: 'titleCompanies',
          label: 'Title Companies',
          multiple: true,
          filterData: (rows, filterValue) => {
            if (!filterValue) {
              return rows;
            }

            if (!Array.isArray(filterValue)) {
              return rows;
            }

            return rows.filter(
              ({ titleCompanyId }) => filterValue.findIndex(x => x === titleCompanyId) !== -1,
            );
          },
          options: (titleCompanies || []).map(titleCompany => ({
            label: titleCompany.label,
            value: titleCompany.id,
            active: false,
          })),
        },
      ] as Filter<RowData>[],
    [dispositions.length, pods?.length, clients.length, titleCompanies?.length],
  );

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Id',
      sortable: true,
      searchable: true,
      hidden: !roles.includes(roleTypes.Dev),
      render: ({ id }: RowData) => (
        <Link href={`/loans/${id}`} target="_blank">
          {id}
        </Link>
      ),
    },
    {
      id: 'calculatedOutreachStage',
      label: 'Stage',
    },
    {
      id: 'appNumber',
      label: 'Loan/Title number',
      searchable: true,
      render: ({ id, appNumber, titleNumber }: RowData) => (
        <Fragment>
          <div>
            <Link href={`/loans/${id}`} target="_blank">
              {appNumber}
            </Link>
          </div>
          <div style={{ color: 'grey' }}>{titleNumber}</div>
        </Fragment>
      ),
    },
    {
      id: 'titleCompany',
      label: 'Title company',
      render: ({ titleCompany, titleCompanyEmailGlobal, titleCompanyPhoneGlobal }: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{titleCompany}</div>
          <div style={{ color: 'grey' }}>
            <div>
              <a
                className={classes.linkStyle}
                href={`tel:${titleCompanyPhoneGlobal?.replace(/\D/g, '')}`}
              >
                {titleCompanyPhoneGlobal}
              </a>
            </div>
          </div>
          <span css={{ color: 'blue' }}>{titleCompanyEmailGlobal}</span>
        </div>
      ),
    },
    {
      id: 'clientInformation',
      label: 'Client',
      render: ({ clientInfo }: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{clientInfo.client}</div>
          <span style={{ color: 'blue' }}>{clientInfo.clientInbox}</span>
          <div style={{ color: 'grey' }}>
            <div>{poBoxDisplay(clientInfo)}</div>
          </div>
        </div>
      ),
    },
    {
      id: 'propertyInfo',
      label: 'Property info',
      render: ({ propertyInfo: { address, borrower, city, county, state, zip } }: RowData) => (
        <Fragment>
          <div>
            <b>{borrower}</b>
          </div>
          <div>{address}</div>
          <div>
            <span>{city}</span>&nbsp;
            <span>{state}</span>&nbsp;
            <span>{zip}</span>&nbsp;
          </div>
          <div>{county}</div>
        </Fragment>
      ),
    },
    {
      id: 'dateFunded',
      label: 'Date funded',
      sortable: true,
      render: ({ dateFunded }: RowData) => dateFunded && new Date(dateFunded).toLocaleDateString(),
    },
    {
      id: 'daysAged',
      label: 'Days aged',
      sortable: true,
      render: ({ dateFunded, daysAgedDeadline }: RowData) => {
        if (!dateFunded) {
          return null;
        }

        const daysAged = differenceInDays(new Date(), new Date(dateFunded));
        return `${daysAged}/${daysAgedDeadline}`;
      },
    },
    {
      id: 'missingDocuments',
      label: 'Missing documents',
      render: ({ missingDocuments, requiresOriginalDoc }: RowData) => (
        <Fragment>
          {missingDocuments.split(',').map((doc, i, arr) => (
            <Fragment key={doc}>
              <span
                style={{
                  color: requiresOriginalDoc && doc.trim() !== 'Policy' ? 'red' : 'inherit',
                }}
              >
                {doc}
              </span>
              {i !== arr.length - 1 && ', '}
            </Fragment>
          ))}
        </Fragment>
      ),
    },
    {
      id: 'loanLevelContactInformation',
      label: 'Loan contacts',
      sortable: true,
      customSort: sortDataByPhoneAndClient,
      render: ({
        loanLevelContactInformation: { email, phone },
        titlePortalContactEmails,
      }: RowData) => (
        <div css={{ maxHeight: 128, overflow: 'auto' }}>
          {(phone || email) && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark }}>Loan Level:</div>
              <div>
                <a className={classes.linkStyle} href={`tel:${phone?.replace(/\D/g, '')}`}>
                  {phone}
                </a>
              </div>
              <div>{email}</div>
            </Fragment>
          )}
          {titlePortalContactEmails.length > 0 && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark, paddingTop: 8 }}>
                Title Portal Contacts:
              </div>
              {titlePortalContactEmails.map(e => (
                <div key={e}>{e}</div>
              ))}
            </Fragment>
          )}
        </div>
      ),
    },
    {
      id: 'pastNotes',
      label: 'Past notes',
      render: (rowData: RowData) =>
        rowData.pastNotes.length ? (
          <div className="df">
            <div
              style={{ width: '310px', height: '66px', overflowY: 'auto', whiteSpace: 'normal' }}
            >
              {formatNotes(rowData.pastNotes)}
            </div>
            <div>
              <OpenWithIcon
                style={{ fontSize: '10px' }}
                onClick={() => setRowOfSelectedNote(rowData)}
              />
            </div>
          </div>
        ) : null,
    },
  ];

  return (
    <MaterialTable
      title="Escalated outstanding loans"
      columns={columns}
      filters={filters}
      data={dataTableData}
      exportFileName="Escalated outstanding loans.csv"
      customExport={rows => customExport(rows, false)}
      searchBarPlaceholder="Search by loan id or loan #"
      dense
      sortConfig={{ field: 'loanLevelContactInformation', direction: 'asc' }}
      rowStyle={({ isResolved }) => ({
        backgroundColor: isResolved ? 'rgb(237, 247, 237)' : '#FFF',
      })}
      rowSelectionActions={actions(
        selectedRows.every(r => r.isResolved),
        selectedRows.some(r => r.isResolved),
        setSidebarOption,
      )}
      onSelectionChange={rows => {
        setSelectedRows(rows);
        setSidebarOption(undefined);
      }}
    />
  );
};

export default DataTable;
