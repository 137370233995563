/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { match as routeMatch, RouteComponentProps } from 'react-router';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import { apiPost } from '../adalConfig';
import { roleTypes } from '../constants';
import { AuthContext } from '../components/AuthContext';
import { useToaster } from '../Hooks/toasters';
import { useClient } from '../Hooks/useClients';
import RequiredDocumentSettings, { Setting } from '../components/Loans/RequiredDocumentSettings';
import Modal from '@mui/material/Modal';
import Datatable, { SelectionAction, TableRef } from '../components/ui/DataTableV2/Datatable';
import { Column, Filter, LegendItem } from '../components/ui/DataTableV2/types';
import Link from '@mui/material/Link';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useInvestors } from '../Hooks/useInvestors';
import { Investor } from '../globalTypes/objects';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { isExecutiveManagement, parseRequestError } from '../Utils';
import { AxiosError } from 'axios';
import Icon from '@mdi/react';
import { mdiOfficeBuildingCog, mdiOfficeBuildingRemove } from '@mdi/js';

export enum LoanDocumentRequiredAndReceivedStatus {
  NotRequiredAndNotReceived = 0,
  RequiredButNotReceived = 1,
  RequiredAndReceived = 2,
  NotRequiredAndReceived = 3,
}

export type RequiredDocument = {
  documentType: string;
  status: LoanDocumentRequiredAndReceivedStatus;
};

type RowData = {
  id: number;
  appNumber: string;
  dateFunded: string;
  dateUploaded: string;
  borrower: string;
  investor: string;
  requiredDocuments: RequiredDocument[];
};

type PostData = {
  investorId: number | undefined;
  loanIds: number[];
};

interface Props extends RouteComponentProps<any> {
  match: routeMatch<{ clientId: string }>;
  history: any;
}

const filters: Filter<RowData>[] = [
  {
    filterType: 'radio',
    id: 'outstandingStatus',
    label: 'Outstanding status',
    options: [
      {
        label: 'All',
        value: 0,
        active: true,
      },
      {
        label: 'Outstanding only',
        value: 1,
        active: false,
      },
    ],
  },
  {
    filterType: 'dropdown',
    id: 'investors',
    label: 'Investor',
    multiple: true,
    optionsUrl: '/api/investors/filter-dropdown',
    options: [
      {
        label: '',
        value: null,
        active: false,
      },
    ],
  },
];

export const iconRender = (value: LoanDocumentRequiredAndReceivedStatus) => {
  switch (value) {
    case LoanDocumentRequiredAndReceivedStatus.RequiredButNotReceived:
      return <RadioButtonUncheckedIcon color="primary" />;
    case LoanDocumentRequiredAndReceivedStatus.RequiredAndReceived:
      return <CheckCircleOutlineIcon color="primary" />;
    case LoanDocumentRequiredAndReceivedStatus.NotRequiredAndReceived:
      return <CheckCircleOutlineIcon color="action" />;
    default:
      return null;
  }
};

const legendItem: LegendItem = [
  {
    text: 'Required and received',
    symbol: <CheckCircleOutlineIcon color="primary" />,
  },
  {
    text: 'Required and not received',
    symbol: <RadioButtonUncheckedIcon color="primary" />,
  },
  {
    text: 'Not required but received',
    symbol: <CheckCircleOutlineIcon color="action" />,
  },
];

const investorOptionLabel = (props: object, { id, name }: Investor) => (
  <div {...props} key={id}>
    <div>{name}</div>
  </div>
);

const updateInvestor = async (postData: PostData) => {
  if (postData.investorId === undefined) {
    return;
  }

  await apiPost('/api/loans/update-investor-on-loans', postData);
};

const removeInvestor = async (loanIds: number[]) => {
  if (!loanIds.length) {
    return;
  }

  await apiPost('/api/loans/remove-investor-on-loans', loanIds);
};

const ClientLoans = ({ match, history }: Props) => {
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [isRequireDocModalOpen, setIsRequireDocModalOpen] = useState(false);
  const [isInvestorUpdateDialogOpen, setIsInvestorUpdateDialogOpen] = useState(false);
  const [isRemoveInvestorDialogOpen, setIsRemoveInvestorDialogOpen] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState<Investor | null>(null);
  const [isSavingRequiredStatus, setIsSavingRequiredStatus] = useState(false);

  const { roles, user } = useContext(AuthContext);
  const client = useClient(parseInt(match.params.clientId));
  const investors = useInvestors();
  const { successToaster, errorToaster } = useToaster();
  const tableRef = useRef({} as TableRef);

  const canBulkUpdateInvestor = useMemo(
    () => isExecutiveManagement(roles) || user?.userPrincipalName === 'aoneill@docprobe.net',
    [roles, user],
  );

  const { mutate: updateInvestorMutation, isLoading: isUpdatingInvestor } = useMutation({
    mutationKey: ['update-investor-on-loans', selectedInvestor?.id, selectedRows],
    mutationFn: updateInvestor,
    onSuccess: () => {
      closeInvestorUpdateDialog();
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      successToaster('Successfully updated investor on loans');
    },
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  const { mutate: removeInvestorMutation, isLoading: isRemovingLoading } = useMutation({
    mutationKey: ['remove-investor-on-loans', selectedRows],
    mutationFn: removeInvestor,
    onSuccess: () => {
      closeRemoveInvestorDialog();
      const { refreshTable } = tableRef.current;
      refreshTable && refreshTable();
      successToaster('Successfully removed investor on loans');
    },
    onError: (e: AxiosError) => {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    },
  });

  const columns: Column<RowData>[] = [
    {
      id: 'id',
      label: 'Id',
      sortable: true,
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      id: 'appNumber',
      label: 'App Number',
      sortable: true,
      render: ({ id, appNumber }) => (
        <Tooltip title={`${client?.company} loans`}>
          <Link
            noWrap
            color="inherit"
            underline="always"
            onClick={() => history.push(`/loans/${id}`)}
            sx={{ cursor: 'pointer' }}
          >
            {appNumber}
          </Link>
        </Tooltip>
      ),
    },
    {
      id: 'dateFunded',
      label: 'Date Funded',
      sortable: true,
      render: (rowData: RowData) => rowData && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      id: 'dateUploaded',
      label: 'Date Uploaded',
      sortable: true,
      render: (rowData: RowData) => rowData && new Date(rowData.dateUploaded).toLocaleDateString(),
    },
    {
      id: 'borrower',
      label: 'Borrower',
      sortable: true,
    },
    {
      id: 'investor',
      label: 'Investor',
    },
    {
      id: 'mortgage',
      label: 'Mortgage',
      render: ({ requiredDocuments }: RowData) => {
        const mortgage = requiredDocuments.find(
          requiredDocument => requiredDocument.documentType === 'Mortgage',
        );

        if (!mortgage) {
          return null;
        }

        return (
          <div className="df jcc aic">
            <div>{iconRender(mortgage.status)}</div>
          </div>
        );
      },
    },
    {
      id: 'policy',
      label: 'Policy',
      render: ({ requiredDocuments }: RowData) => {
        const policy = requiredDocuments.find(
          requiredDocument => requiredDocument.documentType === 'Policy',
        );

        if (!policy) {
          return null;
        }

        return (
          <div className="df jcc aic">
            <div>{iconRender(policy.status)}</div>
          </div>
        );
      },
    },
    {
      id: 'other',
      label: 'Other',
      render: ({ requiredDocuments }: RowData) => {
        const otherDocumentTypes = requiredDocuments
          .filter(({ documentType }) => documentType !== 'Mortgage' && documentType !== 'Policy')
          .filter(
            ({ status }) =>
              status !== LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
          );

        return (
          <Fragment>
            {otherDocumentTypes.map(({ documentType, status }) => (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }} key={documentType}>
                <div css={{ fontWeight: 'bold', padding: 4 }}>{documentType}</div>
                <div>{iconRender(status)}</div>
              </div>
            ))}
          </Fragment>
        );
      },
    },
  ];

  const saveDocumentStatusUpdate = async (settings: Setting[], noteText: string) => {
    setIsSavingRequiredStatus(true);

    try {
      const { data: response } = await apiPost('/api/loans/update-required-status', {
        loanIds: selectedRows.map(row => row.id),
        settings: settings.map(setting => ({
          documentType: setting.documentType!.value,
          isRequired: setting.isRequired,
        })),
        noteText,
      });

      if (response === 1) {
        const { refreshTable } = tableRef.current;
        refreshTable && refreshTable();
        successToaster('Successfully updated the document status');
      } else {
        throw new Error('Failed to update the document status');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsSavingRequiredStatus(false);
      setIsRequireDocModalOpen(false);
    }
  };

  const closeInvestorUpdateDialog = () => {
    setIsInvestorUpdateDialogOpen(false);
    setSelectedInvestor(null);
  };

  const closeRemoveInvestorDialog = () => {
    setIsRemoveInvestorDialogOpen(false);
  };

  const actions = () => {
    const result: SelectionAction[] = [
      {
        key: 'require-doc',
        tooltip: 'Toggle if a document is required',
        icon: () => <PostAddIcon />,
        onClick: () => setIsRequireDocModalOpen(x => !x),
      },
    ];

    if (canBulkUpdateInvestor) {
      result.push(
        {
          key: 'update-investor',
          tooltip: 'Update investor on loans',
          icon: () => <Icon path={mdiOfficeBuildingCog} size={1} />,
          onClick: () => setIsInvestorUpdateDialogOpen(x => !x),
        },
        {
          key: 'remove-investor',
          tooltip: 'Remove investor from loans',
          icon: () => <Icon path={mdiOfficeBuildingRemove} size={1} />,
          onClick: () => setIsRemoveInvestorDialogOpen(x => !x),
        },
      );
    }

    return result;
  };

  return (
    <Fragment>
      <div className="m4">
        <Datatable<RowData>
          title={
            <span>
              Clients
              <span css={{ verticalAlign: 'middle' }}>
                <KeyboardArrowRightIcon color="action" />
              </span>
              {client?.company}
            </span>
          }
          columns={columns}
          filters={filters}
          url={`/api/loans/${match.params.clientId}`}
          exportUrl={`/api/loans/loans-datatable-export/${match.params.clientId}`}
          exportFileName={`${client?.company} loans.xlsx`}
          searchBarPlaceholder="Search by app #, borrower, property info, or investor number"
          sortConfig={{ field: 'dateFunded', direction: 'desc' }}
          legendItems={[legendItem]}
          allowMultiSearch
          rowSelectionActions={actions()}
          onSelectionChange={rows => setSelectedRows(rows)}
          ref={tableRef}
        />
      </div>

      <Modal open={isRequireDocModalOpen} onClose={() => setIsRequireDocModalOpen(false)}>
        <Fragment>
          <RequiredDocumentSettings
            setIsModalOpen={setIsRequireDocModalOpen}
            onSubmit={saveDocumentStatusUpdate}
            isSaving={isSavingRequiredStatus}
          />
        </Fragment>
      </Modal>

      <Dialog open={isInvestorUpdateDialogOpen} onClose={closeInvestorUpdateDialog}>
        <DialogTitle>Update investor on loans</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select an investor to update on the selected loans
          </DialogContentText>
          <Box sx={{ my: 1 }}>
            <Autocomplete
              autoHighlight
              options={investors.filter(x => x.name !== '' && x.name !== null)}
              getOptionLabel={option => option.name}
              renderOption={investorOptionLabel}
              renderInput={params => (
                <TextField {...params} label="Select Investor" variant="outlined" />
              )}
              onChange={(_, value) => setSelectedInvestor(value)}
              value={investors.find(x => x.id === selectedInvestor?.id) || null}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ mr: 1, mb: 1, display: 'flex', gap: '8px' }}>
            <Button onClick={closeInvestorUpdateDialog} variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              loading={isUpdatingInvestor}
              onClick={() =>
                updateInvestorMutation({
                  investorId: selectedInvestor?.id,
                  loanIds: selectedRows.map(row => row.id),
                })
              }
              variant="contained"
              disabled={!selectedInvestor}
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog open={isRemoveInvestorDialogOpen} onClose={closeRemoveInvestorDialog}>
        <DialogTitle>Remove investor on loans</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to remove the investor from the selected loans
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{ mr: 1, mb: 1, display: 'flex', gap: '8px' }}>
            <Button onClick={closeRemoveInvestorDialog} variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              loading={isRemovingLoading}
              onClick={() => removeInvestorMutation(selectedRows.map(row => row.id))}
              variant="contained"
            >
              Confirm
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ClientLoans;
