/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

import { useContext } from 'react';
import Panel, { PanelHeader } from '../components/ui/Panel';
import Circle from '../components/ui/Circle';
import colors from '../styles/colors';
import { IconType } from '../components/ui/Icon';
import { AuthContext } from '../components/AuthContext';
import { hasAccessToRoute } from '../Utils';

const linksWrap = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '8px 32px 32px 32px',
};

const linkWrap = {
  alignItems: 'center',
  backgroundColor: colors.blue,
  borderRadius: 60 / 2,
  color: colors.white,
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  height: 60,
  letterSpacing: 1,
  marginTop: 24,
  padding: '0 6px',
  position: 'relative',
  textTransform: 'uppercase',
  transition: 'background-color .2s',
  width: '48%',
  '&:hover': { backgroundColor: colors.blueDark },
};

export default () => {
  const { roles } = useContext(AuthContext);

  return (
    <Panel styles={{ margin: 40, padding: 0 }}>
      <PanelHeader
        text="Administrator"
        headerStyles={{ borderRadius: '4px 4px 0 0' }}
        iconComponent={<Circle icon={IconType.Settings} />}
      />
      <div css={linksWrap}>
        {adminPages
          .filter(page => hasAccessToRoute(roles, page.link))
          .map(i => (
            <LinkButton key={i.title} info={i} />
          ))}
      </div>
    </Panel>
  );
};

type AdminItem = {
  link: string;
  icon: IconType;
  title: string;
  largeIcon: boolean;
};

function LinkButton({ info }: { info: AdminItem }) {
  const { link, icon, title, largeIcon } = info;
  return (
    <Link
      to={link}
      css={[linkWrap, largeIcon && { svg: { transform: 'scale(1.2)', marginRight: 1 } }]}
    >
      <Circle small icon={icon || 'CursorIcon'} />
      <span css={{ marginLeft: 12 }}>{title}</span>
    </Link>
  );
}
const adminPages: AdminItem[] = [
  {
    link: '/admin/titlecompanies',
    icon: IconType.TitleCompany,
    title: 'Title Companies',
    largeIcon: false,
  },
  {
    link: '/admin/investors',
    icon: IconType.Investor,
    title: 'Investors',
    largeIcon: false,
  },
  {
    link: '/admin/clientContacts',
    icon: IconType.Client,
    title: 'Client Contacts',
    largeIcon: false,
  },
  // {
  //   link: '/admin/deleteLoan',
  //   icon: IconType.TrashCan,
  //   title: 'Delete Loan',
  //   largeIcon: true,
  // },
  {
    link: '/admin/deleteAssociation',
    icon: IconType.TrashCan,
    title: 'Delete Association',
    largeIcon: true,
  },
  {
    link: '/admin/undoAssociation',
    icon: IconType.Unassociated,
    title: 'Undo Association',
    largeIcon: true,
  },
  {
    link: '/admin/deleteDocument',
    icon: IconType.TrashCan,
    title: 'Delete Document',
    largeIcon: true,
  },
  {
    link: '/admin/creatematches',
    icon: IconType.Unassociated,
    title: 'Create Doc Matches',
    largeIcon: true,
  },
  // {
  //   link: '/admin/client-followups',
  //   icon: IconType.EmailIcon,
  //   title: 'Send Client Followups',
  //   largeIcon: false,
  // },
  {
    link: '/admin/bulk-loan-note',
    icon: IconType.Note,
    title: 'Bulk Loans Notes',
    largeIcon: false,
  },
  {
    link: '/admin/bulk-ship-documents',
    icon: IconType.Docs,
    title: 'Bulk Ship Documents',
    largeIcon: false,
  },
  // {
  //   link: '/searcher-retrieval',
  //   icon: IconType.Loans,
  //   title: 'Searcher Retrieval',
  //   largeIcon: true,
  // },
  {
    link: '/admin/digital-delivery-settings',
    icon: IconType.DigitalDelivery,
    title: 'Digital Delivery Settings',
    largeIcon: false,
  },
  {
    link: '/admin/revenue-breakdown-report',
    icon: IconType.Billing,
    title: 'Revenue Breakdown Report',
    largeIcon: false,
  },
  {
    link: '/admin/payments',
    icon: IconType.Payment,
    title: 'Payments',
    largeIcon: false,
  },
  {
    link: '/admin/lender-aliases',
    icon: IconType.Client,
    title: 'Lender Aliases',
    largeIcon: false,
  },
  {
    link: '/admin/client-portal-user-management',
    icon: IconType.Account,
    title: 'Client Portal User Management',
    largeIcon: false,
  },
  {
    link: '/admin/import-document-notes',
    icon: IconType.Import,
    title: 'Upload Document Notes',
    largeIcon: false,
  },
  {
    link: '/admin/new-document-type',
    icon: IconType.Plus,
    title: 'New document type',
    largeIcon: false,
  },
];
