import React from 'react';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import { ActiveFilters, DatatableRow, FilterOption, FilterType, RadioFilter } from './types';

type Props<Row> = {
  filters: RadioFilter<Row>[];
  selectedFilters: ActiveFilters;
  onFilterChange: (
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<string | number>['value']>,
  ) => void;
};

const RadioButtonFilters = <Row extends DatatableRow>({
  filters,
  selectedFilters,
  onFilterChange,
}: Props<Row>) => (
  <>
    {filters.map(filter => {
      const selectedOption = selectedFilters[filter.id].values[0];

      return (
        <Stack key={filter.id}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            {filter.label}
          </Typography>
          {filter.options.map(option => (
            <FormControlLabel
              key={option.label}
              control={
                <Radio
                  checked={option.value === selectedOption}
                  onClick={() => onFilterChange(filter.id, 'radio', [option.value])}
                />
              }
              label={option.label}
              sx={{ textTransform: 'capitalize' }}
            />
          ))}
        </Stack>
      );
    })}
  </>
);

export default RadioButtonFilters;
