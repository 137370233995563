import React from 'react';
import {
  ActiveFilters,
  DatatableRow,
  DateRangeFilter,
  DateRangeValue,
  FilterOption,
  FilterType,
} from './types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

type Props<Row> = {
  filters: DateRangeFilter<Row>[];
  selectedFilters: ActiveFilters;
  onFilterChange: (
    filterId: string,
    filterType: FilterType,
    values: Array<FilterOption<DateRangeValue>['value']>,
  ) => void;
};

const dateError = (start: Date | null, end: Date | null) => Boolean(start && end && end < start);

const DateRangeFilters = <Row extends DatatableRow>({
  filters,
  selectedFilters,
  onFilterChange,
}: Props<Row>) => (
  <>
    {filters.map(filter => {
      const datePickerValue = selectedFilters[filter.id].values[0] as DateRangeValue;

      return (
        <Stack key={filter.id}>
          <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
            {filter.label}
          </Typography>
          <Stack spacing={2.5}>
            <DatePicker
              label="Start date"
              value={datePickerValue?.start || null}
              onChange={value =>
                onFilterChange(filter.id, 'dateRange', [
                  {
                    start: value,
                    end: datePickerValue?.end,
                  } as DateRangeValue,
                ])
              }
              renderInput={params => <TextField {...params} />}
            />

            <DatePicker
              label="End date"
              value={datePickerValue?.end || null}
              onChange={value =>
                onFilterChange(filter.id, 'dateRange', [
                  {
                    start: datePickerValue?.start,
                    end: value,
                  } as DateRangeValue,
                ])
              }
              renderInput={params => <TextField {...params} />}
              /* only in new version
              slotProps={{
                textField: {
                  error: dateError(datePickerValue?.start, datePickerValue?.end),
                  helperText:
                    dateError(datePickerValue?.start, datePickerValue?.end) &&
                    'End date must be later than start date',
                },
              }}*/
            />
          </Stack>
        </Stack>
      );
    })}
  </>
);

export default DateRangeFilters;
